import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Loggedin } from './authfilter/platformCentralAuth';
import { PlatformCentralAuth } from './authfilter/platformCentralAuth';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {NavbarModule} from './navbar/navbar.module';
import {FooterModule} from './footer/footer.module';
import { LoginComponent } from './login/login.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import { MatCardModule} from '@angular/material/card';
import { HomeComponent } from './home/home.component';
import { TestimonialComponent } from './testimonial/testimonial.component';
import { ModulesCompComponent } from './modules-comp/modules-comp.component';
import {MatTabsModule} from '@angular/material/tabs';
import { DashboardComponent } from './dashboard/dashboard.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { ChartsModule } from 'ng2-charts';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
// import { ReportComponent } from './report/report.component';
import { Dashboard2Component } from './dashboard2/dashboard2.component';
import {HttpClientModule} from '@angular/common/http';
import { ExamModule} from './exam/exam.module';
import { ResultsComponent } from './results/results.component';
import { ChartscompModule } from './chartscomp/chartscomp.module';
import { MatDialogModule } from '@angular/material/dialog';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { SocialLoginComponent } from './social-login/social-login.component';
import { CommonComponentComponent } from './common-component/common-component.component';
import { LoaderWithProgressComponent } from './common-component/common-component.component';
import { PaymentComponent } from './payment/payment.component';
import { SendMessageComponent, CommentsVideo } from './home/home.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { AboutComponent } from './about/about.component';
import { ConfirmSubmitExamComponent } from './exam/exam.component';
import { CommentOnQuestionComponent } from './exam/exam.component';
import { SelectExamModeComponent } from './modules-comp/modules-comp.component';
import {GetMobileNumberComponent } from './social-login/social-login.component';
import { ExcelonOnNewsComponent } from './excelon-on-news/excelon-on-news.component';
import { CardComponent } from './card/card.component';
import {SafeHtmlPipe} from './results/results.component';
import { safeVideoLink } from './card/card.component';
import { PaymentPromptComponent} from './payment/payment.component';
import {GetMobileNumberComponentModule } from './modules-comp/modules-comp.component';
import { MentorComponent } from './mentor/mentor.component';
import { TelegramPageComponent } from './telegram-page/telegram-page.component';
import { MainsComponent } from './mains/mains.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        HomeComponent,
        TestimonialComponent,
        ModulesCompComponent,
        DashboardComponent,
        // ReportComponent,
        Dashboard2Component,
        ResultsComponent,
        SocialLoginComponent,
        CommonComponentComponent,
        LoaderWithProgressComponent,
        PaymentComponent,
        SendMessageComponent,
        CommentsVideo,
        TermsAndConditionsComponent,
        AboutComponent,
        ConfirmSubmitExamComponent,
        CommentOnQuestionComponent,
        SelectExamModeComponent,
        GetMobileNumberComponent,
        PaymentPromptComponent,
        SafeHtmlPipe,
        ExcelonOnNewsComponent,
        CardComponent,
        safeVideoLink,
        GetMobileNumberComponentModule,
        MentorComponent,
        TelegramPageComponent,
        MainsComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgbModule,
        NavbarModule,
        FooterModule,
        FormsModule,
        BrowserAnimationsModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatInputModule,
        MatIconModule,
        MatTabsModule,
        MatCardModule,
        MatRadioModule,
        MatButtonToggleModule,
        ChartsModule,
        ExamModule,
        HttpClientModule,
        ChartscompModule,
        MatDialogModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        PdfViewerModule
    ],
    providers: [HttpClientModule, Loggedin, PlatformCentralAuth],
    bootstrap: [AppComponent]
})
export class AppModule { }

// platformBrowserDynamic().bootstrapModule(AppModule);
