<div class="row" style="background: #f2f2f2;margin: 0px !important;border-radius: 3px;">
    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-center">
        <div style="display: flex;align-items: center;">
            <div>
<!--                <img src="assets/img/button_loading.gif" height="50"/>-->
              <mat-spinner style="margin: 3rem;"></mat-spinner>
            </div><br>
            <div *ngIf="data && data.message" class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-center">
                <div class="progress-message">{{data.message}}</div>
                <!--                <div [ngStyle]="{'left': progress + '%'}" class="progress-message">{{data.message}}</div>-->
<!--                <mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>-->
            </div>
        </div>
    </div>
</div>
