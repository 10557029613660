<app-navbar></app-navbar>

<div style="margin-top: 2rem;background: #f2f2f2;" class="container-fluid">
  <div class="row justify-content-center">
    <div style="padding: 1rem; padding-top: 2.2rem;" class="col-md-11 col-lg-11 col-sm-11 col-xs-11">
      <h1>Terms, Conditions & Privacy</h1>
    </div>
  </div>
</div>
<div style="padding: 1rem;" class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-md-11 col-lg-11 col-sm-11 col-xs-11">
      <p>The domain name and Website are owned, and operated by Linx Works private Limited.</p>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-11 col-lg-11 col-sm-11 col-xs-11">
      <h1 style="font-weight: bolder;">Unlearn Platform and User accounts</h1>
    </div>
  </div>
  <div class="row justify-content-center">
    <div style="margin-left: 0.3rem;" class="col-md-11 col-lg-11 col-sm-11 col-xs-11">
      <ol>
        <li>
          <p>These Terms and Conditions apply to all Users of the Unlearn Platform. The Unlearn Platform includes all aspects of the Website which includes but is not limited to products, software and service offered via the Unlearn Platform.</p>
        </li>
        <li>
          <p>Unlearn Platform is an online platform that supports and facilitates the online test series for UPSC and other competitive exams.</p>
        </li>
        <li>
          <p>Subject to these Terms and Conditions, Privacy Policy and all other other rules and policies made available or published elsewhere, Unlearn hereby grants the you a non-exclusive, non-transferable, non-sublicensable, limited license to use the Unlearn Platform in accordance with these Terms and Conditions.</p>
        </li>
        <li>
          <p>In order to access some of the features of the Unlearn Platform, you may have to create your account with Unlearn. You agree and confirm that you will never use another User’s account nor provide access to your account to any third-party. When creating your account, you confirm that the information so provided is accurate and complete. Further, you agree that you are solely responsible for the activities that occur on your account, and you shall keep your account password secure and not share the same with anyone. You must notify Unlearn immediately of any breach of security or unauthorized use of your account. At no point in time will Unlearn be liable for any losses caused by any unauthorized use of your account, you shall solely be liable for the losses caused to Unlearn or others due to such unauthorized use, if any.</p>
        </li>
      </ol>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-11 col-lg-11 col-sm-11 col-xs-11">
      <h1 style="font-weight: bolder;">Access, Permissions and Restrictions</h1>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-11 col-lg-11 col-sm-11 col-xs-11">
      <p>Unlearn hereby grants you permission to access and use the Unlearn Platform as set forth in these Terms and Conditions, provided that:</p>
    </div>
  </div>
  <div class="row justify-content-center">
    <div style="margin-left: 0.3rem;" class="col-md-11 col-lg-11 col-sm-11 col-xs-11">
      <ol>
        <li>
          <p>You agree not to distribute in any medium any part of the Unlearn Platform or the content without Unlearn’s prior written authorization.</p>
        </li>
        <li>
          <p>You agree not to alter or modify any part of the Unlearn Platform.</p>
        </li>
        <li>
          <p>You agree not to access content of any other User through any technology or means.</p>
        </li>
        <li>
          <p>You agree not to use the Unlearn Platform for any of the following commercial uses unless you obtain Unlearn’s prior written approval:</p>
          <ul style="margin-left: 0.3rem;">
            <li>
              <p>the sale of access to the Unlearn Platform;</p>
            </li>
            <li>
              <p>the sale of advertising, sponsorships, or promotions placed on or within the Unlearn Platform or content; or</p>
            </li>
            <li>
              <p>the sale of advertising, sponsorships, or promotions on any page or website that provide similar Unlearn Platform as that of Unlearn.</p>
            </li>
          </ul>
        </li>
        <li>
          <p>In your use of the Unlearn Platform, you will at all times comply with all applicable laws and regulations.</p>
        </li>
        <li>
          <p>Unlearn reserves the right to discontinue any aspect of the Unlearn Platform at any time with or without notice at its sole discretion..</p>
        </li>
      </ol>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-11 col-lg-11 col-sm-11 col-xs-11">
      <h1 style="font-weight: bolder;">Content Use</h1>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-11 col-lg-11 col-sm-11 col-xs-11">
      <p>In addition to the general restrictions mentioned above, the following limitation and conditions shall apply to your use of the Content.</p>
    </div>
  </div>
  <div class="row justify-content-center">
    <div style="margin-left: 0.3rem;" class="col-md-11 col-lg-11 col-sm-11 col-xs-11">
      <ol>
        <li>
          <p>Unlearn Content utilized on the Unlearn Platform which shall include but not be limited to trademarks, service marks and logos ("Marks"), process, images, software, graphics are owned by or licensed to Unlearn and subject to copyright and other intellectual property rights under the law.</p>
        </li>
        <li>
          <p>User Content is provided to you on an AS IS basis. You may access Content for your information and personal use solely as intended through the provided functionality on the Unlearn Platform and as permitted under these Terms and Conditions. You shall not download any User Content unless you see a "download" or similar link displayed by Unlearn on the Unlearn Platform for that User Content. You shall not copy, reproduce, make available online or electronically transmit, publish, adapt, distribute, transmit, broadcast, display, sell, license, or otherwise exploit any User Content for any other purposes other than as provided herein without the prior written consent of Unlearn or the respective licensors of the User Content. Unlearn and its licensors reserve all rights not expressly granted in and to the Unlearn Platform and the User Content.</p>
        </li>
        <li>
          <p>You agree not to circumvent, disable or otherwise interfere with security-related features of the Unlearn Platform or features that prevent or restrict use or copying of any User Content or enforce limitations on use of the Unlearn Platform or the User Content therein.</p>
        </li>
      </ol>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-11 col-lg-11 col-sm-11 col-xs-11">
      <h1 style="font-weight: bolder;">Unlearn Intellectual Property Right</h1>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-11 col-lg-11 col-sm-11 col-xs-11">
      <p>The Unlearn Platform, the processes, and their selection and arrangement, including but not limited to all text, graphics, User interfaces, visual interfaces, sounds and music (if any), artwork and computer code on the Unlearn Platform is owned and controlled by Unlearn and the design, structure, selection, coordination, expression, look and feel and arrangement of such content mentioned hereinabove is protected by copyright, patent and trademark laws, and various other national and international IPR laws and regulations. For clarity, content in the above sentence shall not include User Content.</p>
      <p>Unless otherwise indicated or anything contained to the contrary, or any proprietary material owned by a third-party and so expressly mentioned, Unlearn owns all IPR to and into the trademark Unlearn Platform.</p>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-11 col-lg-11 col-sm-11 col-xs-11">
      <h1 style="font-weight: bolder;">Refunds</h1>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-11 col-lg-11 col-sm-11 col-xs-11">
      <p>Any refunds that are to be processed shall be processed in accordance with Unlearn’s refund policy.</p>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-11 col-lg-11 col-sm-11 col-xs-11">
      <h1 style="font-weight: bolder;">Electronic Communication</h1>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-11 col-lg-11 col-sm-11 col-xs-11">
      <p>When you visit Unlearn Platform or send email to us, you are communicating with us electronically. By communicating with us, you consent to receive communication from us electronically. We will communicate with you by email or posting notices on Unlearn Platform. You agree that all agreements, notices, disclosures, disclaimers, offers and other communications that are provided to you electronically satisfy any legal requirement that such communication should be in writing.</p>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-11 col-lg-11 col-sm-11 col-xs-11">
      <h1 style="font-weight: bolder;">Termination of Account</h1>
    </div>
  </div>
  <div class="row justify-content-center">
    <div style="margin-left: 0.3rem;" class="col-md-11 col-lg-11 col-sm-11 col-xs-11">
      <ol>
        <li>
          <p>Unlearn will terminate a User's access to the Unlearn Platform, if</p>
          <ul>
            <li>
              <p>the User is a repeat copyright infringer;</p>
            </li>
            <li>
              <p>the Users breaches any terms of these Terms and Conditions.</p>
            </li>
            <li>
              <p>Violation of any applicable laws;</p>
            </li>
            <li>
              <p>your use of the Unlearn Platform disrupts our business operations or affects any other party/ User; or</p>
            </li>
            <li>
              <p>you have behaved in a way, which objectively could be regarded as inappropriate or unlawful or illegal or which would bring any claims against Unlearn.</p>
            </li>
          </ul>
        </li>
        <li>
          <p>We may suspend access to the Unlearn Platforms or require You to change Your password if we reasonably believe that the Unlearn Platforms have been or are likely to be misused, and we will notify You accordingly. Any termination of Your registration and/or Account or the Unlearn Platforms will not affect liability previously incurred by You.</p>
        </li>
      </ol>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-11 col-lg-11 col-sm-11 col-xs-11">
      <h1 style="font-weight: bolder;">Privacy policy</h1>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-11 col-lg-11 col-sm-11 col-xs-11">
      <p>In this policy, the words "we", "our", and "us" refer to Unlearn and the privacy mechanism outlined identify personally identifiable or personal information that may be collected, how such information is used, and the choices you have regarding our use of this information.</p>
      <p>In addition to the terms stated in Terms of Service provided under this Website, we are committed to protecting your privacy. We constantly review our systems and data to secure your personal information. Any User who does not agree with any provisions of this Privacy Policy and the Terms of Service is required to leave the Platform immediately. Should you disagree with this Policy (defined below) and still continue to access the Platform and provide your personal information, Unlearn disclaim all the liabilities arising therefrom and it shall be assumed by your continued use of the Platform that you have accepted the Policy and any other online terms and conditions available on the Platform.</p>
      <p>This privacy policy ("Policy") applies to all Users who access the Platform and are therefore required to read and understand the Policy before submitting any Personal Information (defined hereinafter). If you have inadvertently submitted any Personal Information to Unlearn prior to reading the Policy statements set out herein, and you do not agree with the manner in which the Personal Information is collected, stored or used, then you may access, modify and delete all information stored about You by writing to us at info@Unlearnacademy.com. Unlearn is keeping personal data of the User, and every User is also entitled to require Unlearn to delete and destroy all such information (but not that of other Users).</p>
      <p>Unlearn has taken all reasonable precautions to treat Personal Information as confidential with industry standards that it has implemented to protect from unauthorized access, improper use or disclosure, modification and unlawful destruction or accidental loss of the Personal Information.</p>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-11 col-lg-11 col-sm-11 col-xs-11">
      <h1 style="font-weight: bolder;">Access</h1>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-11 col-lg-11 col-sm-11 col-xs-11">
      <p>To create an account, a User is required to provide Personal Information as may be required during the time of registration. Other information requested on the registration page, including the ability to receive promotional offers from Unlearn, is optional. Unlearn may, in future, include other optional requests for information from the User to help Unlearn to customize the Platform to deliver personalized information to the User. Without User's agreement, Unlearn will not share, rent or sell any Personal Information with third parties in any way other than what is disclosed in this Policy. Unlearn may keep records of telephone calls received and made for making enquiries, feedback or for the purpose of rendering services effectively and efficiently. "Personal Information" shall mean the information which identifies the User, name, identification number, email address, age, phone number, password or any financial account information provided to Unlearn at the time of registration or any time thereafter.</p>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-11 col-lg-11 col-sm-11 col-xs-11">
      <h1 style="font-weight: bolder;">Automatic Information Collection</h1>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-11 col-lg-11 col-sm-11 col-xs-11">
      <p>Our system collects information that is sent over the internet without your control. Some examples of the information we collect and analyse include the Internet protocol (IP) address used to connect your computer to the internet; login; email; password; computer and connection information such as browser type and version, operating system, which we sometimes aggregate with similar information from other users to create features, reports such as best rated test etc</p>
      <p>During some visits we may use software tools such as JavaScript to measure and collect session information, including page response times, download errors, length of visits to certain page, course and videos, page interaction information (such as scrolling, clicks and mouse overs), and methods used to browse away from the page.</p>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-11 col-lg-11 col-sm-11 col-xs-11">
      <h1 style="font-weight: bolder;">Information Security</h1>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-11 col-lg-11 col-sm-11 col-xs-11">
      <p>To protect the security of your Personal information during transmission, we use Secure Sockets Layer (SSL) software, which encrypts information you input. To prevent unauthorized access to sensitive information, it is important for you to monitor those who have access to your password and to your computer, laptop or any other device through which your account can be accessed. Be sure to sign off when finished while using a shared computer.</p>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-11 col-lg-11 col-sm-11 col-xs-11">
      <h1 style="font-weight: bolder;">Use of your personal information</h1>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-11 col-lg-11 col-sm-11 col-xs-11">
      <p>While availing our services you will provide access to Unlearn with your Personal Information. If that information is wrong, you can request us to modify or delete the same. Unlearn will take all reasonable measures to ensure that the Personal Information is modified and used for rendering services to you and as otherwise in compliance with laws. When editing or deleting Personal Information, we may ask you to verify your identity before we can act on your request. Unlearn may at its discretion reject any request that is contrary to law, requires un-reasonable technical efforts. We do not assure that we will delete all residual copies and archives made by any third party without our knowledge and consent.</p>
      <p>Unlearn shall use your Personal Information to communicate with you. The said communication can either be by calls, text or emails. If at any time you wish to not receive any communication from our end you can opt-out of the same by writing to us on info@Unlearnacademy.com.</p>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-11 col-lg-11 col-sm-11 col-xs-11">
      <h1 style="font-weight: bolder;">Exceptions</h1>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-11 col-lg-11 col-sm-11 col-xs-11">
      <p>Your Personal Information may be shared with third parties who have a need or authority to receive such information, if we have a good-faith belief that access, use, preservation or disclosure of the information is reasonably necessary to comply with (i) in response to any authority having to receive such information under law (ii) any order of court (iii) detect, prevent, or otherwise address fraud, security or technical issues (iv) protect against harm to the rights, property or safety of Unlearn, our users or the public as required or permitted by law.</p>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-11 col-lg-11 col-sm-11 col-xs-11">
      <h1 style="font-weight: bolder;">User choices with regard to access of information</h1>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-11 col-lg-11 col-sm-11 col-xs-11">
      <p>Unlearn does not make any unsolicited calls or otherwise market any products or services, except for in relation to the purpose for which such information has been provided or taking any feedback, addressing any complaints, informing you about new features. User shall not disclose their Personal Information to any third parties that us not authorised by Unlearn and verify the identity of such person who seek information. Unlearn will communicate with the Users through call, SMS, email or notices posted on the Platform or through any other means available, which shall include but not be limited to text, other forms of messaging, calls etc. The Users can change their e-mail and contact preferences at any time by logging into their account or by emailing at info@Unlearnacademy.com.</p>
      <p>Unlearn may, if you so choose, send direct mailers to you at the address given by you. You have the option to 'opt-out' of this direct mailer by way of links provided at the bottom of each mailer. We respect your privacy and to the extent that you choose not to receive such mailers, we will take all steps to remove you from the list. Should you evidence any violation of our terms and policies, please write to us at info@Unlearnacademy.com and report such incident.</p>
      <p>All the information provided to Unlearn by you, you as a User confirms that the same including sensitive Personal Information, is true, accurate and voluntary. User has the right to withdraw information at any time, in accordance with the terms of this Policy and the Terms of Service.</p>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-11 col-lg-11 col-sm-11 col-xs-11">
      <h1 style="font-weight: bolder;">Sharing of Information</h1>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-11 col-lg-11 col-sm-11 col-xs-11">
      <p>Unlearn may also disclose or transfer User's personal and other information which a User provides, to another third party as part of reorganization or a sale of the assets of Unlearn. Any third party to which Unlearn transfers or sells its assets will have the right to continue to use the personal and other information that a User provide to us.</p>
      <p>To the extent necessary to provide Users with the services on the Platform, Unlearn may provide User’s Personal Information to third party contractors who work on behalf of or with Unlearn to provide Users with such services, to help Unlearn communicate with Users or to maintain the Platform. Generally, these contractors do not have any independent right to share this information, however certain contractors who provide services on the Platform, including the providers of online communications services, online payment gateway services, will have rights to use and disclose the Personal Information collected in connection with the provision of these services in accordance with their own privacy policies.</p>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-11 col-lg-11 col-sm-11 col-xs-11">
      <h1 style="font-weight: bolder;">Interest-Based Ads</h1>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-11 col-lg-11 col-sm-11 col-xs-11">
      <p>On unaffiliated sites, Unlearn may display interest-based advertising using information you make available to us when you interact with our Platform, content, or services. Interest-based ads, also sometimes referred to as personalised or targeted ads, are displayed to you based on information from activities such as subscription on our Platform, visiting sites that contain Unlearn content or ads. In providing interest-based ads, we follow applicable laws, as well as the Code for Self-Regulation in Advertising by the Advertising Standards Council of India and the Self-Regulatory Principles for Online Behavioral Advertising developed by the Digital Advertising Alliance (a coalition of marketing, online advertising, and consumer advocacy organizations).</p>
      <p>Like other online ad networks, we use cookies, web beacons (also known as action tags or single-pixel gifs), and other technologies (collectively, "cookies"). Cookies enable us to learn about what ads you see, what ads you click, and other actions you take on our sites and other sites. This allows us to provide you with more useful and relevant ads. For example, if we know what ads you are shown we can be careful not to show you the same ones repeatedly. We do not associate your interaction with unaffiliated sites with your identity in providing you with interest-based ads.</p>
      <p>We do not provide any personal information to advertisers or to third party sites that display our interest-based ads. However, advertisers and other third-parties (including the ad networks, ad-serving companies, and other service providers they may use) may assume that users who interact with or click on a personalised ad or content are part of the group that the ad or content is directed towards (for example, users in eastern India who bought or browsed for literature books). Also, some third-parties may provide us information about you (such as the sites where you have been shown ads or demographic information) from offline and online sources that we may use to provide you more relevant and useful advertising.</p>
      <p>Advertisers or ad companies working on their behalf sometimes use technology to serve the ads that appear on our sites directly to your browser. They automatically receive your IP address when this happens. They may also use cookies to measure the effectiveness of their ads and to personalise ad content. We do not have access to or control over cookies or other features that advertisers and third party sites may use, and the information practices of these advertisers and third party websites are not covered by our Policy. Please contact them directly for more information about their privacy practices.</p>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-11 col-lg-11 col-sm-11 col-xs-11">
      <h1 style="font-weight: bolder;">Revision of terms & Grievance reporting</h1>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-11 col-lg-11 col-sm-11 col-xs-11">
      <p>Unlearn may update or amend this Policy at any time, with or without advance notice. In the event there are significant changes in the way Unlearn treats User's personally identifiable information, Unlearn will display a notice on the Platform or send Users an email. Unlearn's current Policy to all information that Unlearn has about Users and their account.</p>
      <p>Notwithstanding the above, Unlearn shall not be required to notify the Users of any changes made to the privacy policy. Should you have any concern or reject the changes in the privacy policy you can refuse to accept the amendments and opt for withdrawing your Personal Information by writing to us at info@Unlearnacademy.com</p>
      <p>If you have any compliant or grievances with respect to Website or Privacy Policy please write to us at info@Unlearnacademy.com</p>
    </div>
  </div>
</div>

<app-footer></app-footer>
