<!--<h1 style="text-align: center"> Testimonials</h1>-->
<div class="slider background-image">
  <div class="d-flex flex-column align-items-center justify-content-center p-2">
    <div class="test-head"> Testimonials </div>
    <div class="test-subhead mt-4"> What users feel about ExcelOn Academy </div>
  </div>

  <input type="radio" name="testimonial" id="t-1">
  <input type="radio" name="testimonial" id="t-2" checked>
  <input type="radio" name="testimonial" id="t-3">
  <input type="radio" name="testimonial" id="t-4">

  <div class="testimonials p-2 mt-4">
    <label class="item" for="t-2">
      <img class="start-quote" src="/assets/img/start-quote.png" />
      <h1 style="margin-bottom: 2px;"> All India Rank 658, CSE 2017 </h1>
      <h1 style="margin-bottom: 2px;"> & </h1>
      <h1> TNPSC Group 1 Rank 62 </h1>
      <p>"I attended ExcelOn Academy's AI based online test. The questions were on par with UPSC standards covering all major concepts.They were simple but tricky, on par with UPSC standards. Feedback was amazing, it points out the weak and strong areas by name. Level 2 feedback was really amazing"</p>
      <h2>- Advin </h2>
      <img class="end-quote" src="/assets/img/end-quote.png" />
    </label>
    <label class="item" for="t-1">
      <img class="start-quote" src="/assets/img/start-quote.png" />
      <h1> Cleared UPSC prelims 2019 </h1>
      <p>"I have taken mock test in economics. The test series is designed in a way to assess our weak and strong area holistically covering all conceptual and current topics. Apart from this, it reveals our blind spot where we answer in a haste without a proper reading. Overall, the test series helped me to revise my basics in an short period of time."</p>
      <h2>- Prashant A | Roll No: 1211805</h2>
      <img class="end-quote" src="/assets/img/end-quote.png" />
    </label>
    <label class="item" for="t-3">
      <img class="start-quote" src="/assets/img/start-quote.png" />
      <h1> Cleared UPSC prelims 2019 </h1>
      <p>"I have taken the Polity test. The feature of striking the options, blind guess, direct answering and all were well incorporated and intuitive. Similarly the feedback was also crisp and useful at level 1 and level 2 and very detailed at level 3. The feedback saves a lot of time that I otherwise have to spend for a normal test. Overall it was very helpful "</p>
      <h2>- Abhijith K | Roll No: 1204709</h2>
      <img class="end-quote" src="/assets/img/end-quote.png" />
    </label>
    <label class="item" for="t-4">
      <img class="start-quote" src="/assets/img/start-quote.png" />
      <!-- <h1> Cleared UPSC prelims 2019 </h1> -->
      <p>"I have taken a few tests on the ExcelOn Academy. ​I am delighted to share my AI-based test experience. ​Questions are at par with the UPSC standard. ​Option to strike off the answers gives the real exam feel. A few blind spots such as marking the wrong answer despite spending considerable time were captured. ​Areas for improvement suggested were indeed my weak points. ​The detailed report scrutinizes each answer and suggests measures to improve further. I am looking forward to attempting as many tests as possible on this platform."</p>
      <h2>- Ashwati</h2>
      <img class="end-quote" src="/assets/img/end-quote.png" />
    </label>
  </div>
  <div class="dots pt-4 pb-2">
    <label for="t-1"></label>
    <label for="t-2"></label>
    <label for="t-3"></label>
    <label for="t-4"></label>
  </div>
</div>
