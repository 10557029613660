<div class="row">
  <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-center">
    <h3 style="font-weight: bolder;"> Please specify the mode in which you want to take the exam. Please note that you cannot the pause the exam in test mode.
      Taking an exam in practice mode also counts as one attempt out of total 3 available attempts. Performance report will be available for
      both the modes.
    </h3>
  </div>
</div>
<hr>
<div class="row">
  <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-right">
    <button class="button-class" style="margin: 0.5rem;" (click)="setMode('practice');"> Practice mode </button>
    <button class="button-class" style="margin: 0.5rem;" (click)="setMode('test');"> Test mode </button>
    <button class="button-class" style="margin: 0.5rem;" (click)="close();"> Cancel </button>
  </div>
</div>

