/* tslint:disable:quotemark object-literal-key-quotes */
import {Component, ElementRef, OnInit, ViewChild, Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser'
// import html2canvas from 'html2canvas';
// import * as jspdf from 'jspdf';
// import jsPDF from 'jspdf';
import {ActivatedRoute} from '@angular/router';
import {LinxService} from "../linx.service";
import 'chart.piecelabel.js';
import 'chartjs-plugin-labels';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit {

  constructor(public service: LinxService, public route: ActivatedRoute) {
  }

  data = [
    {
      name: 'Q1',
      status: 'correct'
    },
    {
      name: 'Q2',
      status: 'not answered'
    },
    {
      name: 'Q3',
      status: 'not answered'
    },
    {
      name: 'Q4',
      status: 'incorrect'
    },
    {
      name: 'Q5',
      status: 'not answered'
    },
    {
      name: 'Q6',
      status: 'not answered'
    },
    {
      name: 'Q7',
      status: 'incorrect'
    }
  ];
  labelExtractor = {
    projection: {
      field: 'status',
      function: 'distinct'
    }
  };
  dataExtractor = {
    groupBy: 'status',
    projection: {
      field: 'status',
      function: 'count'
    }
  };
  data1 = [
    {
      "name": "Q1",
      "status": "correct",
      "confidence": "100",
      "marks": "10"
    },
    {
      "name": "Q1",
      "status": "incorrect",
      "confidence": "50",
      "marks": "10"
    },
    {
      "name": "Q1",
      "status": "correct",
      "confidence": "100",
      "marks": "10"
    },
    {
      "name": "Q1",
      "status": "attempted",
      "confidence": "33",
      "marks": "10"
    },
    {
      "name": "Q2",
      "status": "attempted",
      "confidence": "100",
      "marks": "0"
    },
    {
      "name": "Q3",
      "status": "incorrect",
      "confidence": "50",
      "marks": "0"
    },
    {
      "name": "Q4",
      "status": "attempted",
      "confidence": "50",
      "marks": "0"
    },
    {
      "name": "Q5",
      "status": "attempted",
      "confidence": "33",
      "marks": "0"
    },
    {
      "name": "Q6",
      "status": "attempted",
      "confidence": "33",
      "marks": "0"
    },
    {
      "name": "Q7",
      "status": "attempted",
      "confidence": "33",
      "marks": "0"
    }
  ];
  answers = [
    // {
    //   "id": 1,
    //   "href": "https://di.linxmgt.com/Linx/rest/api/UserAnswer/json/6938",
    //   "answered": "false",
    //   "correct": "false",
    //   "timeTaken": "0:0:0",
    //   "toReview": "false",
    //   "blindGuess": "true",
    //   "user": {
    //     "id": 8,
    //     "href": "https://di.linxmgt.com/Linx/rest/api/Person/json/8",
    //     "toString": "Uday Kumar"
    //   },
    //   "confidence": "0",
    //   "answeredOptions": [
    //     {
    //       "id": 27743,
    //       "href": "https://di.linxmgt.com/Linx/rest/api/UserAnswerOption/json/27743",
    //       "answerOption": {
    //         "id": 1560,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1560",
    //         "answerText": "2 only",
    //         "correctAnswer": "true"
    //       },
    //       "excluded": "false",
    //       "included": "false",
    //       "selected": "false"
    //     },
    //     {
    //       "id": 27744,
    //       "href": "https://di.linxmgt.com/Linx/rest/api/UserAnswerOption/json/27744",
    //       "answerOption": {
    //         "id": 1561,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1561",
    //         "answerText": "1 and 3 only",
    //         "correctAnswer": "false"
    //       },
    //       "excluded": "false",
    //       "included": "false",
    //       "selected": "false"
    //     },
    //     {
    //       "id": 27746,
    //       "href": "https://di.linxmgt.com/Linx/rest/api/UserAnswerOption/json/27746",
    //       "answerOption": {
    //         "id": 1562,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1562",
    //         "answerText": "2 and 3 only",
    //         "correctAnswer": "false"
    //       },
    //       "excluded": "false",
    //       "included": "false",
    //       "selected": "false"
    //     },
    //     {
    //       "id": 27748,
    //       "href": "https://di.linxmgt.com/Linx/rest/api/UserAnswerOption/json/27748",
    //       "answerOption": {
    //         "id": 1563,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1563",
    //         "answerText": "1, 2 and 3 only",
    //         "correctAnswer": "false"
    //       },
    //       "excluded": "false",
    //       "included": "false",
    //       "selected": "false"
    //     }
    //   ],
    //   "isBlindspot": "false",
    //   "is100PercentConfidentIncorrect": "false",
    //   "isIncorrectExcluded": "false",
    //   "question": {
    //     "id": 392,
    //     "href": "https://di.linxmgt.com/Linx/rest/api/ExamQuestion/json/392",
    //     "title": "Participatory notes (PNs), sometimes seen in news, is associated with\n1. Inter-state investment in India\n2. Foreign Institutional Investors (FIIs)\n3. Foreign Direct Investment (FDI)\nSelect the correct answer using the codes below.",
    //     "subject": "Economics",
    //     "questionText": "<span style=\"  font-size: 11pt;  color: #000000; \"> Participatory notes (PNs), sometimes seen in news, is associated with<br/>1. Inter-state investment in India<br/>2. Foreign Institutional Investors (FIIs)<br/>3. Foreign Direct Investment (FDI)<br/>Select the correct answer using the codes below.<br/></span>",
    //     "category": {
    //       "id": 90,
    //       "href": "https://di.linxmgt.com/Linx/rest/api/ExamCategory/json/90",
    //       "toString": "Current Affairs"
    //     },
    //     "tags": [],
    //     "source": "Chapter on Security Market in India: Ramesh Singh: Indian Economy",
    //     "questionOptions": [
    //       {
    //         "id": 1560,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1560",
    //         "answerText": "2 only",
    //         "correctAnswer": "true"
    //       },
    //       {
    //         "id": 1561,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1561",
    //         "answerText": "1 and 3 only",
    //         "correctAnswer": "false"
    //       },
    //       {
    //         "id": 1562,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1562",
    //         "answerText": "2 and 3 only",
    //         "correctAnswer": "false"
    //       },
    //       {
    //         "id": 1563,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1563",
    //         "answerText": "1, 2 and 3 only",
    //         "correctAnswer": "false"
    //       }
    //     ],
    //     "cannotRotateAnswers": "false",
    //     "numAnswersToSelect": "1",
    //     "knowledgeArea": {
    //       "id": 376,
    //       "href": "https://di.linxmgt.com/Linx/rest/api/KnowledgeArea/json/376",
    //       "toString": "Private sector funding"
    //     },
    //     "levelOfToughness": {
    //       "id": 106,
    //       "href": "https://di.linxmgt.com/Linx/rest/api/LevelOfToughness/json/106",
    //       "toString": "Hard"
    //     },
    //     "suggestedReading": "Private Commercial Borrowing",
    //     "sourceOfQuestion": "Chapter on Security Market in India: Ramesh Singh: Indian Economy",
    //     "webSitesSourced": ""
    //   },
    //   "sortOrder": "82"
    // },
    // {
    //   "id": 2,
    //   "href": "https://di.linxmgt.com/Linx/rest/api/UserAnswer/json/6940",
    //   "answered": "false",
    //   "correct": "false",
    //   "timeTaken": "0:0:0",
    //   "toReview": "false",
    //   "user": {
    //     "id": 8,
    //     "href": "https://di.linxmgt.com/Linx/rest/api/Person/json/8",
    //     "toString": "Uday Kumar"
    //   },
    //   "confidence": "0",
    //   "answeredOptions": [
    //     {
    //       "id": 27751,
    //       "href": "https://di.linxmgt.com/Linx/rest/api/UserAnswerOption/json/27751",
    //       "answerOption": {
    //         "id": 1564,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1564",
    //         "answerText": "1 only",
    //         "correctAnswer": "false"
    //       },
    //       "excluded": "false",
    //       "included": "false",
    //       "selected": "false"
    //     },
    //     {
    //       "id": 27753,
    //       "href": "https://di.linxmgt.com/Linx/rest/api/UserAnswerOption/json/27753",
    //       "answerOption": {
    //         "id": 1565,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1565",
    //         "answerText": "2 only",
    //         "correctAnswer": "true"
    //       },
    //       "excluded": "false",
    //       "included": "false",
    //       "selected": "false"
    //     },
    //     {
    //       "id": 27755,
    //       "href": "https://di.linxmgt.com/Linx/rest/api/UserAnswerOption/json/27755",
    //       "answerOption": {
    //         "id": 1566,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1566",
    //         "answerText": "Both 1 and 2",
    //         "correctAnswer": "false"
    //       },
    //       "excluded": "false",
    //       "included": "false",
    //       "selected": "false"
    //     },
    //     {
    //       "id": 27757,
    //       "href": "https://di.linxmgt.com/Linx/rest/api/UserAnswerOption/json/27757",
    //       "answerOption": {
    //         "id": 1567,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1567",
    //         "answerText": "None of the above",
    //         "correctAnswer": "false"
    //       },
    //       "excluded": "false",
    //       "included": "false",
    //       "selected": "false"
    //     }
    //   ],
    //   "isBlindspot": "false",
    //   "is100PercentConfidentIncorrect": "false",
    //   "isIncorrectExcluded": "false",
    //   "question": {
    //     "id": 393,
    //     "href": "https://di.linxmgt.com/Linx/rest/api/ExamQuestion/json/393",
    //     "title": "Consider the following statements.\n1. Angel Investors generally buy back failed firms for asset restructuring.\n2. The capital angel investors provide may be a one-time investment to help the business propel or an ongoing injection of money to support and ",
    //     "subject": "Economics",
    //     "questionText": "<span style=\"  font-size: 11pt;  color: #000000; \">Consider the following statements.<br/>1. Angel Investors generally buy back failed firms for asset restructuring.<br/>2. The capital angel investors provide may be a one-time investment to help the business propel or an ongoing injection of money to support and carry the company.<br/>Which of the above is/are correct?<br/></span>",
    //     "category": {
    //       "id": 90,
    //       "href": "https://di.linxmgt.com/Linx/rest/api/ExamCategory/json/90",
    //       "toString": "Current Affairs"
    //     },
    //     "tags": [],
    //     "source": "Chapter on Security Market in India: Ramesh Singh: Indian Economy",
    //     "questionOptions": [
    //       {
    //         "id": 1564,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1564",
    //         "answerText": "1 only",
    //         "correctAnswer": "false"
    //       },
    //       {
    //         "id": 1565,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1565",
    //         "answerText": "2 only",
    //         "correctAnswer": "true"
    //       },
    //       {
    //         "id": 1566,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1566",
    //         "answerText": "Both 1 and 2",
    //         "correctAnswer": "false"
    //       },
    //       {
    //         "id": 1567,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1567",
    //         "answerText": "None of the above",
    //         "correctAnswer": "false"
    //       }
    //     ],
    //     "cannotRotateAnswers": "false",
    //     "numAnswersToSelect": "1",
    //     "knowledgeArea": {
    //       "id": 376,
    //       "href": "https://di.linxmgt.com/Linx/rest/api/KnowledgeArea/json/376",
    //       "toString": "Private sector funding"
    //     },
    //     "levelOfToughness": {
    //       "id": 99,
    //       "href": "https://di.linxmgt.com/Linx/rest/api/LevelOfToughness/json/99",
    //       "toString": "Easy"
    //     },
    //     "suggestedReading": "Private Commercial Borrowing",
    //     "sourceOfQuestion": "Chapter on Security Market in India: Ramesh Singh: Indian Economy",
    //     "webSitesSourced": ""
    //   },
    //   "sortOrder": "83"
    // },
    // {
    //   "id": 3,
    //   "href": "https://di.linxmgt.com/Linx/rest/api/UserAnswer/json/6942",
    //   "answered": "false",
    //   "correct": "false",
    //   "timeTaken": "0:0:0",
    //   "toReview": "false",
    //   "user": {
    //     "id": 8,
    //     "href": "https://di.linxmgt.com/Linx/rest/api/Person/json/8",
    //     "toString": "Uday Kumar"
    //   },
    //   "confidence": "0",
    //   "answeredOptions": [
    //     {
    //       "id": 27760,
    //       "href": "https://di.linxmgt.com/Linx/rest/api/UserAnswerOption/json/27760",
    //       "answerOption": {
    //         "id": 1568,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1568",
    //         "answerText": "1 and 2 only",
    //         "correctAnswer": "false"
    //       },
    //       "excluded": "false",
    //       "included": "false",
    //       "selected": "false"
    //     },
    //     {
    //       "id": 27762,
    //       "href": "https://di.linxmgt.com/Linx/rest/api/UserAnswerOption/json/27762",
    //       "answerOption": {
    //         "id": 1569,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1569",
    //         "answerText": "3 only",
    //         "correctAnswer": "false"
    //       },
    //       "excluded": "false",
    //       "included": "false",
    //       "selected": "false"
    //     },
    //     {
    //       "id": 27764,
    //       "href": "https://di.linxmgt.com/Linx/rest/api/UserAnswerOption/json/27764",
    //       "answerOption": {
    //         "id": 1570,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1570",
    //         "answerText": "1, 2 and 3 only",
    //         "correctAnswer": "false"
    //       },
    //       "excluded": "false",
    //       "included": "false",
    //       "selected": "false"
    //     },
    //     {
    //       "id": 27766,
    //       "href": "https://di.linxmgt.com/Linx/rest/api/UserAnswerOption/json/27766",
    //       "answerOption": {
    //         "id": 1571,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1571",
    //         "answerText": "None of the above",
    //         "correctAnswer": "true"
    //       },
    //       "excluded": "false",
    //       "included": "false",
    //       "selected": "false"
    //     }
    //   ],
    //   "isBlindspot": "false",
    //   "is100PercentConfidentIncorrect": "false",
    //   "isIncorrectExcluded": "false",
    //   "question": {
    //     "id": 394,
    //     "href": "https://di.linxmgt.com/Linx/rest/api/ExamQuestion/json/394",
    //     "title": "Consider the following with reference to the Organisation for Economic Co-operation and Development (OECD).\n1. Its main mission is to enhance the economic interests of only its member countries.\n2. It is a non-profit organization working an attached agenc",
    //     "subject": "Economics",
    //     "questionText": "<span style=\"  font-size: 11pt;  color: #000000; \"> Consider the following with reference to the Organisation for Economic Co-operation and Development (OECD).<br/>1. Its main mission is to enhance the economic interests of only its member countries.<br/>2. It is a non-profit organization working an attached agency under the European Union.<br/>3. India is an accession candidate to OECD.<br/>Select the correct answer using the codes below.<br/></span>",
    //     "category": {
    //       "id": 102,
    //       "href": "https://di.linxmgt.com/Linx/rest/api/ExamCategory/json/102",
    //       "toString": "Static"
    //     },
    //     "tags": [],
    //     "source": "Chapter on Security Market in India: Ramesh Singh: Indian Economy",
    //     "questionOptions": [
    //       {
    //         "id": 1568,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1568",
    //         "answerText": "1 and 2 only",
    //         "correctAnswer": "false"
    //       },
    //       {
    //         "id": 1569,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1569",
    //         "answerText": "3 only",
    //         "correctAnswer": "false"
    //       },
    //       {
    //         "id": 1570,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1570",
    //         "answerText": "1, 2 and 3 only",
    //         "correctAnswer": "false"
    //       },
    //       {
    //         "id": 1571,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1571",
    //         "answerText": "None of the above",
    //         "correctAnswer": "true"
    //       }
    //     ],
    //     "cannotRotateAnswers": "false",
    //     "numAnswersToSelect": "1",
    //     "knowledgeArea": {
    //       "id": 374,
    //       "href": "https://di.linxmgt.com/Linx/rest/api/KnowledgeArea/json/374",
    //       "toString": "Organisation"
    //     },
    //     "levelOfToughness": {
    //       "id": 106,
    //       "href": "https://di.linxmgt.com/Linx/rest/api/LevelOfToughness/json/106",
    //       "toString": "Hard"
    //     },
    //     "suggestedReading": "Organisation",
    //     "sourceOfQuestion": "Chapter on Security Market in India: Ramesh Singh: Indian Economy",
    //     "webSitesSourced": ""
    //   },
    //   "sortOrder": "84"
    // },
    // {
    //   "id": 4,
    //   "href": "https://di.linxmgt.com/Linx/rest/api/UserAnswer/json/6945",
    //   "answered": "false",
    //   "correct": "false",
    //   "timeTaken": "0:0:0",
    //   "toReview": "false",
    //   "user": {
    //     "id": 8,
    //     "href": "https://di.linxmgt.com/Linx/rest/api/Person/json/8",
    //     "toString": "Uday Kumar"
    //   },
    //   "confidence": "0",
    //   "answeredOptions": [
    //     {
    //       "id": 27767,
    //       "href": "https://di.linxmgt.com/Linx/rest/api/UserAnswerOption/json/27767",
    //       "answerOption": {
    //         "id": 1572,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1572",
    //         "answerText": "Upcoming start-ups in the sunrise sector",
    //         "correctAnswer": "false"
    //       },
    //       "excluded": "false",
    //       "included": "false",
    //       "selected": "false"
    //     },
    //     {
    //       "id": 27769,
    //       "href": "https://di.linxmgt.com/Linx/rest/api/UserAnswerOption/json/27769",
    //       "answerOption": {
    //         "id": 1573,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1573",
    //         "answerText": "Institutions that promote organized crime",
    //         "correctAnswer": "false"
    //       },
    //       "excluded": "false",
    //       "included": "false",
    //       "selected": "false"
    //     },
    //     {
    //       "id": 27771,
    //       "href": "https://di.linxmgt.com/Linx/rest/api/UserAnswerOption/json/27771",
    //       "answerOption": {
    //         "id": 1574,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1574",
    //         "answerText": "Distressed assets",
    //         "correctAnswer": "true"
    //       },
    //       "excluded": "false",
    //       "included": "false",
    //       "selected": "false"
    //     },
    //     {
    //       "id": 27773,
    //       "href": "https://di.linxmgt.com/Linx/rest/api/UserAnswerOption/json/27773",
    //       "answerOption": {
    //         "id": 1575,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1575",
    //         "answerText": "Large cap segment of the stock markets",
    //         "correctAnswer": "false"
    //       },
    //       "excluded": "false",
    //       "included": "false",
    //       "selected": "false"
    //     }
    //   ],
    //   "isBlindspot": "false",
    //   "is100PercentConfidentIncorrect": "false",
    //   "isIncorrectExcluded": "false",
    //   "question": {
    //     "id": 395,
    //     "href": "https://di.linxmgt.com/Linx/rest/api/ExamQuestion/json/395",
    //     "title": "“Vulture funds” generally invest in",
    //     "subject": "Economics",
    //     "questionText": "<span style=\"  font-size: 11pt;  color: #000000; \"> &ldquo;Vulture funds&rdquo; generally invest in</span>",
    //     "category": {
    //       "id": 90,
    //       "href": "https://di.linxmgt.com/Linx/rest/api/ExamCategory/json/90",
    //       "toString": "Current Affairs"
    //     },
    //     "tags": [],
    //     "source": "Revision: Indian Economy: Ramesh Singh",
    //     "questionOptions": [
    //       {
    //         "id": 1572,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1572",
    //         "answerText": "Upcoming start-ups in the sunrise sector",
    //         "correctAnswer": "false"
    //       },
    //       {
    //         "id": 1573,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1573",
    //         "answerText": "Institutions that promote organized crime",
    //         "correctAnswer": "false"
    //       },
    //       {
    //         "id": 1574,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1574",
    //         "answerText": "Distressed assets",
    //         "correctAnswer": "true"
    //       },
    //       {
    //         "id": 1575,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1575",
    //         "answerText": "Large cap segment of the stock markets",
    //         "correctAnswer": "false"
    //       }
    //     ],
    //     "cannotRotateAnswers": "false",
    //     "numAnswersToSelect": "1",
    //     "knowledgeArea": {
    //       "id": 376,
    //       "href": "https://di.linxmgt.com/Linx/rest/api/KnowledgeArea/json/376",
    //       "toString": "Private sector funding"
    //     },
    //     "levelOfToughness": {
    //       "id": 106,
    //       "href": "https://di.linxmgt.com/Linx/rest/api/LevelOfToughness/json/106",
    //       "toString": "Hard"
    //     },
    //     "suggestedReading": "Private Commercial Borrowing",
    //     "sourceOfQuestion": "Revision: Indian Economy: Ramesh Singh",
    //     "webSitesSourced": ""
    //   },
    //   "sortOrder": "85"
    // },
    // {
    //   "id": 5,
    //   "href": "https://di.linxmgt.com/Linx/rest/api/UserAnswer/json/6947",
    //   "answered": "false",
    //   "correct": "false",
    //   "timeTaken": "0:0:0",
    //   "toReview": "false",
    //   "user": {
    //     "id": 8,
    //     "href": "https://di.linxmgt.com/Linx/rest/api/Person/json/8",
    //     "toString": "Uday Kumar"
    //   },
    //   "confidence": "0",
    //   "answeredOptions": [
    //     {
    //       "id": 27776,
    //       "href": "https://di.linxmgt.com/Linx/rest/api/UserAnswerOption/json/27776",
    //       "answerOption": {
    //         "id": 1576,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1576",
    //         "answerText": "European Union",
    //         "correctAnswer": "true"
    //       },
    //       "excluded": "false",
    //       "included": "false",
    //       "selected": "false"
    //     },
    //     {
    //       "id": 27777,
    //       "href": "https://di.linxmgt.com/Linx/rest/api/UserAnswerOption/json/27777",
    //       "answerOption": {
    //         "id": 1577,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1577",
    //         "answerText": "China",
    //         "correctAnswer": "false"
    //       },
    //       "excluded": "false",
    //       "included": "false",
    //       "selected": "false"
    //     },
    //     {
    //       "id": 27778,
    //       "href": "https://di.linxmgt.com/Linx/rest/api/UserAnswerOption/json/27778",
    //       "answerOption": {
    //         "id": 1578,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1578",
    //         "answerText": "USA"
    //       },
    //       "excluded": "false",
    //       "included": "false",
    //       "selected": "false"
    //     },
    //     {
    //       "id": 27779,
    //       "href": "https://di.linxmgt.com/Linx/rest/api/UserAnswerOption/json/27779",
    //       "answerOption": {
    //         "id": 1579,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1579",
    //         "answerText": "SAARC",
    //         "correctAnswer": "false"
    //       },
    //       "excluded": "false",
    //       "included": "false",
    //       "selected": "false"
    //     }
    //   ],
    //   "isBlindspot": "false",
    //   "is100PercentConfidentIncorrect": "false",
    //   "isIncorrectExcluded": "false",
    //   "question": {
    //     "id": 396,
    //     "href": "https://di.linxmgt.com/Linx/rest/api/ExamQuestion/json/396",
    //     "title": "India traded the most in terms of market value with which of these countries or organizations in 2018?",
    //     "subject": "Economics",
    //     "questionText": "<span style=\"  font-size: 11pt;  color: #000000; \"> India traded the most in terms of market value with which of these countries or organizations in 2018?<br/></span>",
    //     "category": {
    //       "id": 90,
    //       "href": "https://di.linxmgt.com/Linx/rest/api/ExamCategory/json/90",
    //       "toString": "Current Affairs"
    //     },
    //     "tags": [],
    //     "source": "Economic Survey",
    //     "questionOptions": [
    //       {
    //         "id": 1576,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1576",
    //         "answerText": "European Union",
    //         "correctAnswer": "true"
    //       },
    //       {
    //         "id": 1577,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1577",
    //         "answerText": "China",
    //         "correctAnswer": "false"
    //       },
    //       {
    //         "id": 1578,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1578",
    //         "answerText": "USA"
    //       },
    //       {
    //         "id": 1579,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1579",
    //         "answerText": "SAARC",
    //         "correctAnswer": "false"
    //       }
    //     ],
    //     "cannotRotateAnswers": "false",
    //     "numAnswersToSelect": "1",
    //     "knowledgeArea": {
    //       "id": 368,
    //       "href": "https://di.linxmgt.com/Linx/rest/api/KnowledgeArea/json/368",
    //       "toString": "External Trade"
    //     },
    //     "levelOfToughness": {
    //       "id": 99,
    //       "href": "https://di.linxmgt.com/Linx/rest/api/LevelOfToughness/json/99",
    //       "toString": "Easy"
    //     },
    //     "suggestedReading": "External Trade",
    //     "sourceOfQuestion": "Economic Survey",
    //     "webSitesSourced": ""
    //   },
    //   "sortOrder": "86"
    // },
    // {
    //   "id": 6,
    //   "href": "https://di.linxmgt.com/Linx/rest/api/UserAnswer/json/6948",
    //   "answered": "false",
    //   "correct": "false",
    //   "timeTaken": "0:0:0",
    //   "toReview": "false",
    //   "user": {
    //     "id": 8,
    //     "href": "https://di.linxmgt.com/Linx/rest/api/Person/json/8",
    //     "toString": "Uday Kumar"
    //   },
    //   "confidence": "0",
    //   "answeredOptions": [
    //     {
    //       "id": 27780,
    //       "href": "https://di.linxmgt.com/Linx/rest/api/UserAnswerOption/json/27780",
    //       "answerOption": {
    //         "id": 1580,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1580",
    //         "answerText": "1 only",
    //         "correctAnswer": "false"
    //       },
    //       "excluded": "false",
    //       "included": "false",
    //       "selected": "false"
    //     },
    //     {
    //       "id": 27781,
    //       "href": "https://di.linxmgt.com/Linx/rest/api/UserAnswerOption/json/27781",
    //       "answerOption": {
    //         "id": 1581,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1581",
    //         "answerText": "1 and 2 only",
    //         "correctAnswer": "false"
    //       },
    //       "excluded": "false",
    //       "included": "false",
    //       "selected": "false"
    //     },
    //     {
    //       "id": 27782,
    //       "href": "https://di.linxmgt.com/Linx/rest/api/UserAnswerOption/json/27782",
    //       "answerOption": {
    //         "id": 1582,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1582",
    //         "answerText": "2 and 3 only"
    //       },
    //       "excluded": "false",
    //       "included": "false",
    //       "selected": "false"
    //     },
    //     {
    //       "id": 27783,
    //       "href": "https://di.linxmgt.com/Linx/rest/api/UserAnswerOption/json/27783",
    //       "answerOption": {
    //         "id": 1583,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1583",
    //         "answerText": "1, 2 and 3",
    //         "correctAnswer": "true"
    //       },
    //       "excluded": "false",
    //       "included": "false",
    //       "selected": "false"
    //     }
    //   ],
    //   "isBlindspot": "false",
    //   "is100PercentConfidentIncorrect": "false",
    //   "isIncorrectExcluded": "false",
    //   "question": {
    //     "id": 397,
    //     "href": "https://di.linxmgt.com/Linx/rest/api/ExamQuestion/json/397",
    //     "title": "Poverty Head Count Ratios (HCR) is/are usually affected by which of the following factors?\n1. Nutritional norms included in calculations\n2. Price deflator used to update the poverty line over the years\n3. Consumer expenditure surveys and their methodology",
    //     "subject": "Economics",
    //     "questionText": "<span style=\"  font-size: 11pt;  color: #000000; \"> Poverty Head Count Ratios (HCR) is/are usually affected by which of the following factors?<br/>1. Nutritional norms included in calculations<br/>2. Price deflator used to update the poverty line over the years<br/>3. Consumer expenditure surveys and their methodology<br/>Select the correct answer using the codes below.<br/></span>",
    //     "category": {
    //       "id": 102,
    //       "href": "https://di.linxmgt.com/Linx/rest/api/ExamCategory/json/102",
    //       "toString": "Static"
    //     },
    //     "tags": [],
    //     "source": "AR: Chapter on Human Development: Ramesh Singh",
    //     "questionOptions": [
    //       {
    //         "id": 1580,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1580",
    //         "answerText": "1 only",
    //         "correctAnswer": "false"
    //       },
    //       {
    //         "id": 1581,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1581",
    //         "answerText": "1 and 2 only",
    //         "correctAnswer": "false"
    //       },
    //       {
    //         "id": 1582,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1582",
    //         "answerText": "2 and 3 only",
    //         "correctAnswer" : "false"
    //       },
    //       {
    //         "id": 1583,
    //         "href": "https://di.linxmgt.com/Linx/rest/api/AnswerOption/json/1583",
    //         "answerText": "1, 2 and 3",
    //         "correctAnswer": "true"
    //       }
    //     ],
    //     "cannotRotateAnswers": "false",
    //     "numAnswersToSelect": "1",
    //     "knowledgeArea": {
    //       "id": 373,
    //       "href": "https://di.linxmgt.com/Linx/rest/api/KnowledgeArea/json/373",
    //       "toString": "Poverty"
    //     },
    //     "levelOfToughness": {
    //       "id": 100,
    //       "href": "https://di.linxmgt.com/Linx/rest/api/LevelOfToughness/json/100",
    //       "toString": "Medium"
    //     },
    //     "suggestedReading": "Inflation and Poverty",
    //     "sourceOfQuestion": "AR: Chapter on Human Development: Ramesh Singh",
    //     "webSitesSourced": ""
    //   },
    //   "sortOrder": "87"
    // }
  ];
  wentWell = [];
  canImprove = [];
  labelExtractor1 = {
    orderBy: "desc",
    "projection": {
      "field": "confidence",
      "function": "distinct"
    }
  };
  dataExtractor1 = {
    "groupBy": "confidence",
    "seriesGroupBy": "status",
    "projection": {
      "field": "status",
      "function": "count"
    }
  };
  params: any;
  flag = false;
  confidenceStats = [];
  difficultStats = [];
  subjectLevelStatsChartData = [];
  difficultStatsChartData = [];
  confidenceStatsChartData = [];
  completeData: any;
  componentLoading = true;
  subjectQuestionStats: any;
  level2Synthesis: any;
  correct = 0;
  inCorrect = 0;
  notAttempted = 0;
  generalStats: any;
  maxscore: any;
  knowledgeAreaGroupedByLabel: any;
  subjectGroupedByLabel: any;
  strongAreas = [];
  okAreas = [];
  areaNeedsImprovement = [];
  whatWentWell = [];
  incorrectBlindSpot = [];
  incorrectQuestions = [];
  unAnsweredQuestions = [];
  @ViewChild('content', {static: false}) content: ElementRef;
  srArray = [];
  questions = [];
  feedbackSumbitted: boolean;
  feedbackAnswers = [];
  backupFeedbackAnswers = [];
  title: string;
  textFeedback: string = "";
  feedbackId: number;
  showThankyou: string = "none";
  resultData = [
    {
      title: "What is a Blind Spot ?",
      value: "452",
      logo: "/assets/img/Blind Spot.png",
    },
    {
      title: "Reasons for Blind Spot",
      value: "452",
      logo: "/assets/img/Reasons for Blind Spot.png",
    },
  ];

  totalTimeSpent: any;
  averageTimeTaken: any;
  top5MaxTimeQuestions = [];
  incorrectQuestionsWith100PercentConfidence = 0;
  correctQuestionsStuckedOff = 0;
  maxBlindSpotsKonwledgeArea: string;
  numberOfBlindSpotQuestionsInMaxKnowledgeArea: number;
  public chartColors: any[] = [
    {
      backgroundColor: ["#0ABD0A", "#FFAB48", "#817D7D"]
    }];
  relativePerformanceData: any;

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params.id !== null && params.id !== undefined) {
        this.params = params.id;
        this.service.getQuestion(this.params).subscribe(data => {
          this.completeData = data;
          this.componentLoading = false;
          // if (this.completeData) {
          let averageTimeTaken = 0;
          const knowledgeAreaBlindSpots = {};
          for (let i = 0; i < this.completeData['userAnswers'].length; i++) {
            const answer = this.completeData['userAnswers'][i];
            // if (!(knowledgeAreaBlindSpots.hasOwnProperty(answer['question']['subject']))) {
            if (answer['question']['knowledgeArea'] && !(knowledgeAreaBlindSpots.hasOwnProperty(answer['question']['knowledgeArea']['toString']))) {
              // knowledgeAreaBlindSpots[answer['question']['subject']] = 0;
              knowledgeAreaBlindSpots[answer['question']['knowledgeArea']['toString']] = 0;
            }
            if (answer && answer !== null && answer !== undefined) {
              if (answer['correct'] === 'true') {
                this.correct += 1;
              } else if (answer['correct'] === 'false' && answer['answered'] === 'true') {
                this.inCorrect += 1;
                let stuckedOff = false;
                for (const answerOption of answer['answeredOptions']) {
                  if (answerOption['excluded'] === 'true') {
                    stuckedOff = true;
                  }
                  if (answerOption['excluded'] === 'true' && answerOption['answerOption'].hasOwnProperty('correctAnswer') && answerOption['answerOption']['correctAnswer'] === 'true') {
                    stuckedOff = true;
                    this.correctQuestionsStuckedOff += 1;
                    // knowledgeAreaBlindSpots[answer['question']['subject']] = knowledgeAreaBlindSpots[answer['question']['subject']] + 1;
                    if (knowledgeAreaBlindSpots[answer] && knowledgeAreaBlindSpots[answer['question']] && knowledgeAreaBlindSpots[answer['question']['knowledgeArea']] && knowledgeAreaBlindSpots[answer['question']['knowledgeArea']['toString']]) {
                      knowledgeAreaBlindSpots[answer['question']['knowledgeArea']['toString']] = knowledgeAreaBlindSpots[answer['question']['knowledgeArea']['toString']] + 1;
                    }
                  }
                }
                if (stuckedOff === false) {
                  this.incorrectQuestionsWith100PercentConfidence += 1;
                  // knowledgeAreaBlindSpots[answer['question']['subject']] = knowledgeAreaBlindSpots[answer['question']['subject']] + 1;
                  if (knowledgeAreaBlindSpots[answer] && knowledgeAreaBlindSpots[answer['question']] && knowledgeAreaBlindSpots[answer['question']['knowledgeArea']] && knowledgeAreaBlindSpots[answer['question']['knowledgeArea']['toString']]) {
                    knowledgeAreaBlindSpots[answer['question']['knowledgeArea']['toString']] = knowledgeAreaBlindSpots[answer['question']['knowledgeArea']['toString']] + 1;
                  }
                }
              } else if (answer['correct'] === 'false' && answer['answered'] === 'false') {
                this.notAttempted += 1;
              }
              averageTimeTaken += +answer['timeTaken'];
            }
          }
          this.maxBlindSpotsKonwledgeArea = Object.keys(knowledgeAreaBlindSpots).reduce((a, b) => knowledgeAreaBlindSpots[a] > knowledgeAreaBlindSpots[b] ? a : b);
          this.numberOfBlindSpotQuestionsInMaxKnowledgeArea = knowledgeAreaBlindSpots[this.maxBlindSpotsKonwledgeArea];
          this.totalTimeSpent = this.msToTime(averageTimeTaken.toString());
          this.averageTimeTaken = this.msToTime((averageTimeTaken / this.completeData['userAnswers'].length).toString());
          const top5MaxTime = this.sort_by_key_desc(JSON.parse(JSON.stringify(this.completeData['userAnswers'])), 'timeTaken');
          for (let i = 0; i < 5; i++) {
            if (top5MaxTime[i] && top5MaxTime[i] !== null && top5MaxTime[i] !== undefined) {
              this.top5MaxTimeQuestions.push(top5MaxTime[i]);
            }
          }
          for (const data1 of this.completeData['userAnswers']) {
            if (data1) {
              if (data1['correct'] === 'true' || data1['correct'] === true) {
                this.whatWentWell.push(data1);
              } else if (data1['correct'] === 'false' || data1['correct'] === false) {
                if (data1['answered'] === 'true' || data1['answered'] === true) {
                  if (data1.hasOwnProperty('isBlindspot') && (data1['isBlindspot'] === 'true' || data1['isBlindspot'] === true)) {
                    this.incorrectBlindSpot.push(data1);
                  } else {
                    this.incorrectQuestions.push(data1);
                  }
                } else {
                  this.unAnsweredQuestions.push(data1);
                }
              }
            }
          }
          // }
        }, error => {
          this.componentLoading = false;
        });
      }

      this.service.getMaxscore(this.params).subscribe(response => {
        this.maxscore = response;
      });

      this.service.getGeneralStats(this.params).subscribe(response => {
        this.generalStats = response;
      });

      this.service.getKnowledgeAreaGroupedByLabel(this.params).subscribe(response => {
        this.knowledgeAreaGroupedByLabel = response;
        for (const area of this.knowledgeAreaGroupedByLabel) {
          if (area && area !== null && area !== undefined) {
            if (area['ratioFeedback']['label'] === 'Positive Feedback' && area['label'] !== '') {
              this.strongAreas.push(area['label']);
            } else if (area['ratioFeedback']['label'] === 'Moderate Feedback' && area['label'] !== '') {
              this.okAreas.push(area['label']);
            } else if (area['ratioFeedback']['label'] === 'Improvement Required' && area['label'] !== '') {
              this.areaNeedsImprovement.push(area['label']);
            }
          }
        }
      });

      this.service.getSubjectGroupedByLabel(this.params).subscribe(response => {
        this.subjectGroupedByLabel = response;
      });

      this.service.getLevel2Synthesis(this.params).subscribe(data => {
        this.level2Synthesis = data;
      });

      this.service.getSubjectQuestionStats(this.params).subscribe(response => {
        this.subjectQuestionStats = response;
        for (const stats of this.subjectQuestionStats) {
          if (stats && stats !== null && stats !== undefined) {
            const chartObject = {};
            chartObject['type'] = 'doughnut';
            chartObject['data'] = [stats['numCorrect'], stats['numInCorrect'], stats['numTotal'] - stats['numAnswered']];
            chartObject['totalQuestions'] = stats['numTotal'];
            chartObject['title'] = stats['label'];
            if (stats.hasOwnProperty('remark')) {
              chartObject['remark'] = stats['remark']
            } else {
              chartObject['remark'] = '';
            }
            chartObject['options'] = {
              // labels: {
              //   render: 'label',
              //   fontColor: '#fff',
              // }
              labels: {
                render: 'value',
                color: ['#fff'],
              }
            }
            this.subjectLevelStatsChartData.push(chartObject);
          }
        }
      });

      this.service.getCanImprove(this.params).subscribe(data => {
        const iterator = data[Symbol.iterator]();
        while (true) {
          const tempObject = iterator.next();
          if (tempObject.done) {
            break;
          }
          this.canImprove.push(tempObject.value);
        }
        // const top5MaxTime = this.sort_by_key_desc(JSON.parse(JSON.stringify(this.canImprove)), 'timeTaken');
        // for (let i = 0; i < 5; i++) {
        //   if (top5MaxTime[i] && top5MaxTime[i] !== null && top5MaxTime[i] !== undefined) {
        //     this.top5MaxTimeQuestions.push(top5MaxTime[i]);
        //   }
        // }
        this.service.getWentWell(this.params).subscribe(data1 => {
          const iterator1 = data1[Symbol.iterator]();
          while (true) {
            const tempObject = iterator1.next();
            if (tempObject.done) {
              break;
            }
            this.wentWell.push(tempObject.value);
          }
          this.service.confidenceStats(this.params).subscribe(data2 => {
            const iterator2 = data2[Symbol.iterator]();
            while (true) {
              const tempObject = iterator2.next();
              if (tempObject.done) {
                break;
              }
              this.confidenceStats.push(tempObject.value);
            }
            for (const stats of this.confidenceStats) {
              if (stats && stats !== null && stats !== undefined) {
                const chartObject = {};
                chartObject['type'] = 'doughnut';
                if (!stats.hasOwnProperty('numCorrect')) {
                  stats['numCorrect'] = 0;
                }
                if (!stats.hasOwnProperty('numInCorrect')) {
                  stats['numInCorrect'] = 0;
                }
                if (!stats.hasOwnProperty('numTotal')) {
                  stats['numTotal'] = 0;
                }
                if (!stats.hasOwnProperty('numAnswered')) {
                  stats['numAnswered'] = 0;
                }
                chartObject['data'] = [stats['numCorrect'], stats['numInCorrect'], stats['numTotal'] - stats['numAnswered']];
                chartObject['totalQuestions'] = stats['numTotal'];
                chartObject['title'] = stats['label'];
                if (stats.hasOwnProperty('remark')) {
                  chartObject['remark'] = stats['remark']
                } else {
                  chartObject['remark'] = '';
                }
                chartObject['options'] = {
                  labels: {
                    render: 'value',
                    color: ['#fff'],
                  }
                }
                this.confidenceStatsChartData.push(chartObject);
              }
            }
            this.service.difficultyStats(this.params).subscribe(data3 => {
              const iterator3 = data3[Symbol.iterator]();
              while (true) {
                const tempObject = iterator3.next();
                if (tempObject.done) {
                  break;
                }
                this.difficultStats.push(tempObject.value);
                this.flag = true;
              }
              for (const stats of this.difficultStats) {
                if (stats && stats !== null && stats !== undefined) {
                  const chartObject = {};
                  chartObject['type'] = 'doughnut';
                  chartObject['data'] = [stats['numCorrect'], stats['numInCorrect'], stats['numTotal'] - stats['numAnswered']];
                  chartObject['totalQuestions'] = stats['numTotal'];
                  chartObject['title'] = stats['label'];
                  if (stats.hasOwnProperty('remark')) {
                    chartObject['remark'] = stats['remark']
                  } else {
                    chartObject['remark'] = '';
                  }
                  chartObject['options'] = {
                    // labels: {
                    //   render: (args) => {
                    //     return args.value;
                    //   },
                    //   arc: true,
                    // }
                    labels: {
                      render: 'value',
                      color: '#fff',
                    }
                  }
                  this.difficultStatsChartData.push(chartObject);
                }
              }
            });
          });
        });
      });
    });
    this.createFeedbackSurvey(this.params);
    this.getRelativePerformance(this.params);
  }

  getRelativePerformance(id) {
    this.service.getRelativePerformance(id).subscribe(response => {
      this.relativePerformanceData = response;
      // const result:any = response;
      // console.log(result)
      // for (let index = 0; index < result.length; index++) {
      //   const element = result[index];
      //   let sortOrder = element.sortOrder;
      //   let percentage = 0;
      //   let performance = "";
      //   if(element.answered === true) {
      //     if (element.correct === true) {
      //       performance = "Correct";
      //       percentage = element.otherPerformance.correct /  element.otherPerformance.count;
      //     } else {
      //       performance = "Incorrect"
      //     }
      //   } else {
      //     performance = "Unattempted";
      //   }

      //   let data = {
      //     sortOrder: sortOrder,
      //     percentage: percentage,
      //     performance: performance
      //   }
      //   this.relativePerformanceData.push(data);

      // }
      // console.log(this.relativePerformanceData);

    }, error => {
      return;
    });
  }

  fetchRelativePerformance(element) {
    let performance = "";
    if (element.answered === true) {
      if (element.correct === true) {
        performance = "Correct";
      } else {
        performance = "Incorrect";
      }
    } else {
      performance = "Unattempted";
    }
    return performance;
  }

  fetchRelativePercentage(element) {
    return ((element.otherPerformance.correct / element.otherPerformance.count) * 100).toFixed(2);
  }

  createFeedbackSurvey(id) {
    this.service.createFeedbackSurvey(id).subscribe(response => {
      this.title = response['questionnaire']['title'];
      this.feedbackAnswers = response['feedbackAnswers'];
      this.backupFeedbackAnswers = response['feedbackAnswers'];
      this.feedbackSumbitted = response['submitted'];
      this.feedbackId = response['id'];
    }, error => {
      return;
    });
  }

  selectStar(num, id) {
    var star1 = document.getElementById('star' + id);
    var star2 = document.getElementById('star' + id + 1);
    var star3 = document.getElementById('star' + id + 2);
    var star4 = document.getElementById('star' + id + 3);
    var star5 = document.getElementById('star' + id + 4);
    this.feedbackAnswers.forEach((x) => {
      if (x.id === id) {
        x.intResponse = num;
        x.answered = true;
        if (num === 1) {
          star1.classList.add("starSelected");
          star2.classList.remove("starSelected", "allSelected");
          star3.classList.remove("starSelected", "allSelected");
          star4.classList.remove("starSelected", "allSelected");
          star5.classList.remove("allSelected");
        }
        if (num === 2) {
          star1.classList.add("starSelected");
          star2.classList.add("starSelected");
          star3.classList.remove("starSelected", "allSelected");
          star4.classList.remove("starSelected", "allSelected");
          star5.classList.remove("allSelected");
        }
        if (num === 3) {
          star1.classList.add("starSelected");
          star2.classList.add("starSelected");
          star3.classList.add("starSelected");
          star1.classList.remove("allSelected");
          star2.classList.remove("allSelected");
          star3.classList.remove("allSelected");
          star4.classList.remove("starSelected", "allSelected");
          star5.classList.remove("allSelected");
        }
        if (num === 4) {
          star1.classList.add("starSelected");
          star2.classList.add("starSelected");
          star3.classList.add("starSelected");
          star4.classList.add("starSelected");
          star1.classList.remove("allSelected");
          star2.classList.remove("allSelected");
          star3.classList.remove("allSelected");
          star4.classList.remove("allSelected");
          star5.classList.remove("allSelected");
        }
        if (num === 5) {
          star1.classList.add("allSelected");
          star2.classList.add("allSelected");
          star3.classList.add("allSelected");
          star4.classList.add("allSelected");
          star5.classList.add("allSelected");
        }
      }
    });
  }

  sendFeedback() {
    this.feedbackAnswers.forEach((x) => {
      if (x.question.dataType === 'Integer') {
        this.refreshStars(x);
      }
      if (x.question.dataType === 'String') {
        x.stringResponse = this.textFeedback;
        x.answered = true;
      }
    });

    this.sendFeedbackToServer(this.feedbackAnswers);
    this.textFeedback = "";
    this.feedbackSumbitted = true;
    this.showThankyou = "block";
    setTimeout(() => {
      this.showThankyou = "none";
    }, 3000);
  }

  sendFeedbackToServer(feedbackAnswers) {
    var feedbackArray = [];
    feedbackAnswers.forEach((x) => {
      let fObj = {
        id: x.id,
        intResponse: x.intResponse,
        answered: x.answered,
        timeTaken: x.timeTaken,
        stringResponse: x.stringResponse
      }
      feedbackArray.push(fObj);
    });

    let feedbackObj = {
      id: this.feedbackId,
      feedbackAnswers: feedbackArray
    }

    this.service.submitFeedbackSurvey(feedbackObj).subscribe(response => {
    }, error => {
      return;
    });

  }

  closeModal() {
    this.feedbackAnswers.forEach((x) => {
      if (x.question.dataType === 'Integer') {
        this.refreshStars(x);
      }
    });
    this.textFeedback = "";
    this.feedbackAnswers = this.backupFeedbackAnswers;
  }

  thankyouClose() {
    this.showThankyou = "none";
  }

  refreshStars(x) {
    let star1 = document.getElementById('star' + x.id);
    let star2 = document.getElementById('star' + x.id + 1);
    let star3 = document.getElementById('star' + x.id + 2);
    let star4 = document.getElementById('star' + x.id + 3);
    let star5 = document.getElementById('star' + x.id + 4);

    star1.classList.remove("starSelected", "allSelected");
    star2.classList.remove("starSelected", "allSelected");
    star3.classList.remove("starSelected", "allSelected");
    star4.classList.remove("starSelected", "allSelected");
    star5.classList.remove("allSelected");

  }

  sort_by_key(array, key) {
    return array.sort((a, b) => {
      const x = a[key];
      const y = b[key];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }

  sort_by_key_desc(array, key) {
    return array.sort((a, b) => {
      const x = +a[key];
      const y = +b[key];
      return ((x > y) ? -1 : ((x < y) ? 1 : 0));
    });
  }

  suggestedReadingIsLink(content): boolean {
    if (content.includes('http://') || content.includes('https://')) {
      return true;
    } else {
      return false;
    }
  }

  suggestedReadingIsTextWithLink(content): boolean {
    this.srArray = content.split('+');
    for (let i = 0; i < this.srArray.length; i++) {
      const read = this.srArray[i];
      if (read.includes('http://') || read.includes('https://')) {
        this.srArray[i] = read.replace(new RegExp(' ', 'g'), '');
      }
    }
    if (this.srArray.length > 1) {
      return true;
    } else {
      return false;
    }
  }

  msToTime(duration) {
    let seconds = Math.floor((duration / 1000) % 60);
    let minutes = Math.floor((duration / (1000 * 60)) % 60);
    let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    if (seconds.toString().length === 1) {
      seconds = +('0' + seconds.toString());
    }
    if (minutes.toString().length === 1) {
      minutes = +('0' + minutes.toString());
    }
    if (hours.toString().length === 1) {
      hours = +('0' + hours.toString());
    }

    return hours + ':' + minutes + ':' + seconds;
  }

  // fun(){
  //   // @ts-ignore
  //   const contentEl = document.querySelector('#contentToConvert');
  //   const doc = new jsPDF('p', 'mm', 'a4');
  //   // @ts-ignore
  //   doc.html(contentEl).then(r => doc.save('www.pdf') );
  // }
  // fun2() {
  //   const data = document.getElementById('contentToConvert');
  //   html2canvas(data).then(canvas => {
  //     const imgWidth = 208;
  //     const imgHeight = canvas.height * imgWidth / canvas.width;
  //     const contentDataURL = canvas.toDataURL('image/png');
  //     const pdf = new jsPDF('p', 'mm', 'a4');
  //     const position = 0;
  //     pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
  //     pdf.save('newPDF.pdf');
  //   });
  // }
  // generatePdf() {
  //   let htmlText = "<html><head><link rel=\"stylesheet\" href=\"https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.1.1/css/bootstrap.min.css\" \/><\/head><body>" +
  //       "<div class=\"container-fluid\">\r\n" +
  //       "<div class=\"row\" style=\"padding: 3rem;\">\r\n" +
  //       "                    <div class=\"col-1\"><\/div>\r\n" +
  //       "                    <div class=\"col-5 text-center\" style=\"background-color: #f2f2f2; padding: 1rem\"><h1 style=\"font-weight: 400; margin: 0px\">Economy<\/h1><\/div>\r\n" +
  //       "                    <div class=\"col-5\">\r\n" +
  //       "                        <span class=\"col-12 text-center\"><h1 style=\"margin: 0px\">Exam 1<\/h1><\/span>\r\n" +
  //       "                        <span class=\"col-12 text-center\"><h4>Test Result and Analysis<\/h4><\/span>\r\n" +
  //       "                    <\/div>\r\n" +
  //       "                <\/div>\r\n" +
  //       "                <div class=\"row\">\r\n" +
  //       "                    <p class=\"col-12\">Dear User,<\/p>\r\n" +
  //       "                    <div class=\"col-12\">Thank you for taking the test.Based on your performance , our summary of your performance is<\/div>\r\n" +
  //       "                    <div class=\"col-8\" style=\"background: peachpuff; margin-left: 15px;\"><strong style=\"color: red; font-size: 20px\">There seems to be a few areas you need to work upon on an urgent basis<\/strong><\/div>\r\n" +
  //       "                    <div class=\"col-12\">Kindly refer detailed feedback session to increase your score.<\/div>\r\n" +
  //       "                    <div class=\"col-12\">Please go through 3 levels of personalised performance feedback where your\r\n" +
  //       "                        performance in analysed through multiple dimensions.<\/div>\r\n" +
  //       "                    <div class=\"col-12\">\r\n" +
  //       "                        The test consists of the following <strong>Subjects<\/strong>:\r\n" +
  //       "                    <\/div>\r\n" +
  //       "                    <div class=\"row\" style=\"margin-bottom: 10px; margin-left: 0px; padding-top:1rem; padding-bottom: 1rem\" >\r\n" +
  //       "                        <div class=\"col-2\">\r\n" +
  //       "                            <span style=\"border: 3px solid green; text-align: center; padding: 0.5rem\"><a style=\"color:green\"> Polity <\/a><\/span><\/div>\r\n" +
  //       "                        <div class=\"col-2\">\r\n" +
  //       "                            <span style=\"border: 3px solid green; text-align: center; padding: 0.5rem\"><a style=\"color:green\"> Environment <\/a><\/span><\/div>\r\n" +
  //       "                        <div class=\"col-2\">\r\n" +
  //       "                            <span style=\"border: 3px solid orange; text-align: center; padding: 0.5rem\"><a style=\"color: orange\"> Economy <\/a><\/span><\/div>\r\n" +
  //       "                        <div class=\"col-2\">\r\n" +
  //       "                            <span style=\"border: 3px solid orange; text-align: center; padding: 0.5rem\"><a style=\"color: orange\"> Science & Tech <\/a><\/span><\/div>\r\n" +
  //       "                        <div class=\"col-2\">\r\n" +
  //       "                            <span style=\"border: 3px solid red; text-align: center; padding: 0.5rem\"><a style=\"color: red;\">International Relations <\/a><\/span><\/div>\r\n" +
  //       "                        <div class=\"col-2\">\r\n" +
  //       "                            <span style=\"border: 3px solid red; text-align: center; padding: 0.5rem\"><a style=\" color: red;\"> History <\/a><\/span><\/div>\r\n" +
  //       "                    <\/div>\r\n" +
  //       "                    <div class=\"col-12\">\r\n" +
  //       "                        The test consists of the following <strong>Knowledge Areas<\/strong> :\r\n" +
  //       "                    <\/div>\r\n" +
  //       "                     <div class=\"row\">\r\n" +
  //       "                        <div class=\"col-2\" style=\"margin-bottom: 5px\">\r\n" +
  //       "                            <span style=\"border: 3px solid green; text-align: center; padding: 1px\"><a style=\"color:green\"> Economic Concepts <\/a><\/span><\/div>\r\n" +
  //       "                        <div class=\"col-2\" style=\"margin-bottom: 5px\">\r\n" +
  //       "                            <span style=\"border: 3px solid green; text-align: center; padding: 1px\"><a style=\"color:green\"> Banking & monetary policy <\/a><\/span><\/div>\r\n" +
  //       "                            <div class=\"col-2\" style=\"margin-bottom: 5px\">\r\n" +
  //       "                            <span style=\"border: 3px solid green; text-align: center; padding: 1px\"><a style=\"color:green\"> Organisations <\/a><\/span><\/div>\r\n" +
  //       "                        <div class=\"col-2\" style=\"margin-bottom: 5px\">\r\n" +
  //       "                            <span style=\"border: 3px solid green; text-align: center; padding: 1px\"><a style=\"color:green\"> National Income <\/a><\/span><\/div>\r\n" +
  //       "                        <div class=\"col-2\" style=\"margin-bottom: 5px\">\r\n" +
  //       "                            <span style=\"border: 3px solid orange; text-align: center; padding: 1px\"><a style=\"color: orange\"> Planning <\/a><\/span><\/div>\r\n" +
  //       "                        <div class=\"col-2\" style=\"margin-bottom: 5px\">\r\n" +
  //       "                            <span style=\"border: 3px solid orange; text-align: center; padding: 1px\"><a style=\"color: orange\"> Inflation <\/a><\/span><\/div>\r\n" +
  //       "                        <div class=\"col-2\" style=\"margin-bottom: 5px\">\r\n" +
  //       "                            <span style=\"border: 3px solid orange; text-align: center; padding: 1px\"><a style=\"color: orange\"> International Trade <\/a><\/span><\/div>\r\n" +
  //       "                        <div class=\"col-2\" style=\"margin-bottom: 5px\">\r\n" +
  //       "                            <span style=\"border: 3px solid orange; text-align: center; padding: 1px\"><a style=\"color: orange\"> Agriculture <\/a><\/span><\/div>\r\n" +
  //       "                        <div class=\"col-2\" style=\"margin-bottom: 5px\">\r\n" +
  //       "                            <span style=\"border: 3px solid orange; text-align: center; padding: 1px\"><a style=\"color: orange\"> Economic Survey <\/a><\/span><\/div>\r\n" +
  //       "                        <div class=\"col-2\" style=\"margin-bottom: 5px\">\r\n" +
  //       "                            <span style=\"border: 3px solid red; text-align: center; padding: 1px\"><a style=\"color: red\"> Government Initiatives <\/a><\/span><\/div>\r\n" +
  //       "                        <div class=\"col-2\" style=\"margin-bottom: 5px\">\r\n" +
  //       "                            <span style=\"border: 3px solid red; text-align: center; padding: 1px\"><a style=\" color: red\"> Public Finance <\/a><\/span><\/div>\r\n" +
  //       "                         <div class=\"col-2\" style=\"margin-bottom: 5px\">\r\n" +
  //       "                            <span style=\"border: 3px solid red; text-align: center; padding: 1px\"><a style=\" color: red\"> Economic Reports <\/a><\/span><\/div>\r\n" +
  //       "                         <div class=\"col-2\" style=\"margin-bottom: 5px\">\r\n" +
  //       "                            <span style=\"border: 3px solid red; text-align: center; padding: 1px\"><a style=\" color: red\"> Private sector <\/a><\/span><\/div>\r\n" +
  //       "                        <div class=\"col-2\" style=\"margin-bottom: 5px\">\r\n" +
  //       "                            <span style=\"border: 3px solid red; text-align: center; padding: 1px\"><a style=\" color: red\"> Taxation <\/a><\/span><\/div>\r\n" +
  //       "                    <\/div><div class=\"col-12\">\r\n" +
  //       "                        The structure of the performance feedback is tabulated below:\r\n" +
  //       "                    <\/div>\r\n" +
  //       "                    <ol>\r\n" +
  //       "                        <li>Level-1 feedback<\/li>\r\n" +
  //       "                        <li>Level-2 feedback<\/li>\r\n" +
  //       "                        <li>Level-3 feedback<\/li>\r\n" +
  //       "                        <li>What went well - Questions answered correctly<\/li>\r\n" +
  //       "                        <li>What could be improved<\/li>\r\n" +
  //       "                        <li>Your blind spot questions<\/li>\r\n" +
  //       "                        <li>Incorrectly attempted questions<\/li>\r\n" +
  //       "                        <li>Unanswered questions<\/li>\r\n" +
  //       "                    <\/ol>\r\n";
  //   htmlText += "<div class=\"col-12\">\n" +
  //       "                        <div class=\"row\">\n" +
  //       "                            <div class=\"col-6\" style=\"margin-left: 15px\">\n" +
  //       "                                <div class=\"row\">\n" +
  //       "                                    <h1 class=\"row\">LEVEL 1</h1>\n" +
  //       "                                </div>\n" +
  //       "                                <div class=\"row\">\n" +
  //       "                                    <h4 class=\"row\"><strong>Feedback | High Level statistics</strong></h4>\n" +
  //       "                                </div>\n" +
  //       "                            </div>\n" +
  //       "                        </div>\n" +
  //       "                        <div class=\"row\">\n" +
  //       "                            <div class=\"col-4\">\n" +
  //       "                                <div class=\"row\">\n" +
  //       "                                    <h2>Total Score</h2>\n" +
  //       "                                </div>\n" +
  //       "                                <div class=\"row\">\n" +
  //       "                                    2\n" +
  //       "                                </div>\n" +
  //       "                            </div>\n" +
  //       "                            <div class=\"col-4\">\n" +
  //       "                                    <div class=\"row\">\n" +
  //       "                                        <strong>Correct</strong>\n" +
  //       "                                    </div>\n" +
  //       "                                    <div class=\"row\">\n" +
  //       "                                        58\n" +
  //       "                                    </div>\n" +
  //       "                                    <div class=\"row\">\n" +
  //       "                                        <strong>Incorrect</strong>\n" +
  //       "                                    </div>\n" +
  //       "                                    <div class=\"row\">\n" +
  //       "                                        32\n" +
  //       "                                    </div>\n" +
  //       "                                    <div class=\"row\">\n" +
  //       "                                        <strong>Not attempted</strong>\n" +
  //       "                                    </div>\n" +
  //       "                                    <div class=\"row\">\n" +
  //       "                                        0\n" +
  //       "                                    </div>\n" +
  //       "                            </div>\n" +
  //       "                            <div class=\"col--4\">\n" +
  //       "                                <div class=\"row\">\n" +
  //       "                                    <strong>Time Taken</strong>\n" +
  //       "                                </div>\n" +
  //       "                                <div class=\"row\">\n" +
  //       "                                    1:00:01\n" +
  //       "                                </div>\n" +
  //       "                                <div class=\"row\">\n" +
  //       "                                    <strong>Average Time/Question</strong>\n" +
  //       "                                </div>\n" +
  //       "                                <div class=\"row\">\n" +
  //       "                                    02:00\n" +
  //       "                                </div>\n" +
  //       "                                <div class=\"row\">\n" +
  //       "                                    <strong>Max Time for a Question</strong>\n" +
  //       "                                </div>\n" +
  //       "                                <div class=\"row\">\n" +
  //       "                                    42:00\n" +
  //       "                                </div>\n" +
  //       "                            </div>\n" +
  //       "                        </div>\n" +
  //       "                        <div class=\"row\">\n" +
  //       "                            <h3><strong>Performance across confidence levels</strong></h3>\n" +
  //       "                        </div>\n" +
  //       "                        <div class=\"row\">\n" +
  //       "                            <table class=\"table-striped table\">\n" +
  //       "                                <thead>\n" +
  //       "                                    <th>\n" +
  //       "                                        Conf Level (%)\n" +
  //       "                                    </th>\n" +
  //       "                                    <th>\n" +
  //       "                                        # Attempted\n" +
  //       "                                    </th>\n" +
  //       "                                    <th>\n" +
  //       "                                        # Correct\n" +
  //       "                                    </th>\n" +
  //       "                                    <th>\n" +
  //       "                                        # Incorrect\n" +
  //       "                                    </th>\n" +
  //       "                                    <th>\n" +
  //       "                                        Net Marks\n" +
  //       "                                    </th>\n" +
  //       "                                    <th>\n" +
  //       "                                        Remarks\n" +
  //       "                                    </th>\n" +
  //       "                                </thead>\n" +
  //       "                                <tbody>\n" ;
  //   for (const confidence of this.confidenceStats) {
  //     htmlText += "<tr>\n" +
  //     "<td>";
  //     htmlText += confidence.label;
  //     htmlText += "<\/td>\n<td>";
  //     if (confidence.numAnswered) {
  //       htmlText += confidence.numAnswered;
  //     } else {
  //       htmlText += "-";
  //     }
  //     htmlText += "<\/td>\n<td>";
  //     if (confidence.numCorrect) {
  //       htmlText += confidence.numCorrect;
  //     } else {
  //       htmlText += "-";
  //     }
  //     htmlText += "<\/td>\n<td>";
  //     if (confidence.numInCorrect) {
  //       htmlText += confidence.numInCorrect;
  //     } else {
  //       htmlText += "-";
  //     }
  //     htmlText += "<\/td>\n<td>";
  //     if (confidence.score) {
  //       htmlText += confidence.score;
  //     } else {
  //       htmlText += "-";
  //     }
  //     htmlText += "<\/td>\n<td>";
  //     if (confidence.remark) {
  //       htmlText += confidence.remark;
  //     } else {
  //       htmlText += "-";
  //     }
  //   }
  //   htmlText += "                                </tbody>\n" +
  //       "                                <tfoot>\n" +
  //       "                                <p><strong>Confidence Definition</strong></p>\n" +
  //       "                                <p>100% - Questions where you have chosen the option directly without striking any other option.</p>\n" +
  //       "                                <p>50% - Questions where you struck off 2 options.</p>\n" +
  //       "                                <p>33% - Questions where you struck off 1 option.</p>\n" +
  //       "                                </tfoot>\n" +
  //       "                            </table>\n" +
  //       "                        </div>\n" +
  //       "                        <div class=\"row\">\n" +
  //       "                            <h3><strong>Difficulty Analysis</strong></h3>\n" +
  //       "                        </div>\n" +
  //       "                        <div class=\"row\">\n" +
  //       "                            <table class=\"table table-striped\">\n" +
  //       "                                <thead>\n" +
  //       "                                    <th>\n" +
  //       "                                        Difficulty Level\n" +
  //       "                                    </th>\n" +
  //       "                                <th>\n" +
  //       "                                    # questions\n" +
  //       "                                </th>\n" +
  //       "                                <th>\n" +
  //       "                                    # attempted\n" +
  //       "                                </th>\n" +
  //       "                                <th>\n" +
  //       "                                    # correct\n" +
  //       "                                </th>\n" +
  //       "                                <th>\n" +
  //       "                                    # incorrect\n" +
  //       "                                </th>\n" +
  //       "                                <th>\n" +
  //       "                                    Net Marks\n" +
  //       "                                </th>\n" +
  //       "                                <th>\n" +
  //       "                                    Remarks\n" +
  //       "                                </th>\n" +
  //       "                                </thead>\n" +
  //       "                                <tbody>\n" ;
  //   for (const difficult of this.difficultStats) {
  //       htmlText += "<tr><td>";
  //       htmlText += difficult.label;
  //       htmlText += "<\/td>\n<td>";
  //       if (difficult.numTotal) {
  //       htmlText += difficult.numTotal;
  //     } else {
  //       htmlText += "-";
  //     }
  //       htmlText += "<\/td>\n<td>";
  //       if (difficult.numAnswered) {
  //       htmlText += difficult.numAnswered;
  //     } else {
  //       htmlText += "-";
  //     }
  //       htmlText += "<\/td>\n<td>";
  //       if (difficult.numCorrect) {
  //       htmlText += difficult.numCorrect;
  //     } else {
  //       htmlText += "-";
  //     }
  //       htmlText += "<\/td>\n<td>";
  //       if (difficult.numInCorrect) {
  //       htmlText += difficult.numInCorrect;
  //     } else {
  //       htmlText += "-";
  //     }
  //       htmlText += "<\/td>\n<td>";
  //       if (difficult.score) {
  //       htmlText += difficult.score;
  //     } else {
  //       htmlText += "-";
  //     }
  //       htmlText += "<\/td>\n<td>";
  //       if (difficult.remark) {
  //       htmlText += difficult.remark;
  //     } else {
  //       htmlText += "-";
  //     }
  //   }
  //   htmlText += "                                </tbody>\n" +
  //       "                            </table>\n" +
  //       "                        </div>\n" +
  //       "                        <div class=\"row\">\n" +
  //       "                            <h3><strong>Static Current Affair Analysis</strong></h3>\n" +
  //       "                        </div>\n" +
  //       "                        <div class=\"row\">\n" +
  //       "                            <table class=\"table-striped table\">\n" +
  //       "                                <thead>\n" +
  //       "                                <th>\n" +
  //       "                                    Conf Level (%)\n" +
  //       "                                </th>\n" +
  //       "                                <th>\n" +
  //       "                                    # Question\n" +
  //       "                                </th>\n" +
  //       "                                <th>\n" +
  //       "                                    # Attempted\n" +
  //       "                                </th>\n" +
  //       "                                <th>\n" +
  //       "                                    # Correct\n" +
  //       "                                </th>\n" +
  //       "                                <th>\n" +
  //       "                                    # Incorrect\n" +
  //       "                                </th>\n" +
  //       "                                <th>\n" +
  //       "                                    Net Marks\n" +
  //       "                                </th>\n" +
  //       "                                <th>\n" +
  //       "                                    Remarks\n" +
  //       "                                </th>\n" +
  //       "                                </thead>\n" +
  //       "                                <tbody>\n" +
  //       "                                    <tr>\n" +
  //       "                                        <td>\n" +
  //       "                                            Current Affairs\n" +
  //       "                                        </td>\n" +
  //       "                                        <td>\n" +
  //       "                                            26\n" +
  //       "                                        </td>\n" +
  //       "                                        <td>\n" +
  //       "                                            26\n" +
  //       "                                        </td>\n" +
  //       "                                        <td>\n" +
  //       "                                            15\n" +
  //       "                                        </td>\n" +
  //       "                                        <td>\n" +
  //       "                                            11\n" +
  //       "                                        </td>\n" +
  //       "                                        <td>\n" +
  //       "                                            22.63\n" +
  //       "                                        </td>\n" +
  //       "                                        <td>\n" +
  //       "                                            Needs Imrpovement\n" +
  //       "                                        </td>\n" +
  //       "                                    </tr>\n" +
  //       "                                <tr>\n" +
  //       "                                    <td>\n" +
  //       "                                        Current Affairs\n" +
  //       "                                    </td>\n" +
  //       "                                    <td>\n" +
  //       "                                        74\n" +
  //       "                                    </td>\n" +
  //       "                                    <td>\n" +
  //       "                                        74\n" +
  //       "                                    </td>\n" +
  //       "                                    <td>\n" +
  //       "                                        43\n" +
  //       "                                    </td>\n" +
  //       "                                    <td>\n" +
  //       "                                        31\n" +
  //       "                                    </td>\n" +
  //       "                                    <td>\n" +
  //       "                                        65.23\n" +
  //       "                                    </td>\n" +
  //       "                                    <td>\n" +
  //       "                                        Needs Imrpovement\n" +
  //       "                                    </td>\n" +
  //       "                                </tr>\n" +
  //       "                                </tbody>\n" +
  //       "                            </table>\n" +
  //       "                        </div>";
  //   htmlText += "                    <div class=\"col-12\" style=\"background-color: #f2f2f2;\">\r\n" +
  //       "                        <div class=\"row\">\r\n" +
  //       "                            <h1>LEVEL 2 - FEEDBACK<\/h1>\r\n" +
  //       "                        <\/div>\r\n" +
  //       "                        <div class=\"row\">\r\n" +
  //       "                            <div class=\"col-12\">\r\n" +
  //       "                                <strong>(A) STRONG AREAS<\/strong>\r\n" +
  //       "                                <p>No areas for this category<\/p>\r\n" +
  //       "                            <\/div>\r\n" +
  //       "                            <div class=\"col-12\">\r\n" +
  //       "                                <strong>(B) AREAS OF IMPROVEMENT<\/strong>\r\n" +
  //       "                                <p>Agriculture, Banking & monetary policy, Economic Concepts, Economic\r\n" +
  //       "                                    Reports, Economic Survey, Government Initiatives, Inflation, International\r\n" +
  //       "                                    Trade, National Income, Organisations, Planning, Private sector, Public Finance, Taxation\r\n" +
  //       "                                    Current Affairs, Static<\/p>\r\n" +
  //       "                            <\/div>\r\n" +
  //       "                            <div class=\"col-12\">\r\n" +
  //       "                                <strong>(C) DOING OKAY<\/strong>\r\n" +
  //       "                                <p>No areas for this category<\/p>\r\n" +
  //       "                            <\/div>\r\n" +
  //       "                            <div class=\"col-12\">\r\n" +
  //       "                                <strong>(D) TIME TAKEN<\/strong>\r\n" +
  //       "                                <p>\r\n" +
  //       "                                    The 5 questions for which you have taken the maximum time are 1, 2, 3, 4, 5\r\n" +
  //       "                                    In this you have got 0\/5 correct. Kindly review the following questions on why\r\n" +
  //       "                                    you got them wrong despite sufficient time, 1 (Economics),2 (Economics),3\r\n" +
  //       "                                    (Economics),4 (Economics),5 (Economics)\r\n" +
  //       "                                <\/p>\r\n" +
  //       "                            <\/div>\r\n\r\n" +
  //       "                        <\/div>\r\n" +
  //       "                    <\/div>\r\n" +
  //       "                    <div class=\"col-12\">\r\n" +
  //       "                        <div class=\"row\">\r\n" +
  //       "                            <h1>WHAT WENT WELL<\/h1>\r\n" +
  //       "                        <\/div>\r\n";
  //   for (const data of this.wentWell) {
  //     htmlText +=         "                        <div class=\"row\" style=\"border: 2px solid grey; margin-bottom: 10px\">\r\n";
  //     if (data.correct === 'true' || data.correct === true) {
  //       htmlText += "                            <h3 class=\"col-12\" style=\"color: green\">\r\n" +
  //                                         "                                Question ";
  //       htmlText += data.sortOrder ;
  //       htmlText += " Correct\r\n" +
  //                                         "                            <\/h3>\r\n";
  //                                   }
  //                                   else {
  //                                         htmlText += "                            <h3 class=\"col-12\" style=\"color: red\">\r\n" +
  //                                         "                                Question ";
  //                                         htmlText += data.sortOrder;
  //                                         htmlText += " Incorrect\r\n" +
  //                                         "                            <\/h3>\r\n";
  //                                  }
  //     htmlText += "                            <div class=\"col-5\">\r\n" +
  //                                   "                                <p class=\"col-12\"><strong>Category: ";
  //     htmlText += data.question.levelOfToughness.toString;
  //     htmlText += "<\/strong><\/p>\r\n" +
  //                                   "                                <div class=\"col-12\">\r\n" +
  //                                   "                                    <p>Time spent : ";
  //     htmlText += data.timeTaken;
  //     htmlText += "<\/p>\r\n" +
  //                                   "                                <\/div>\r\n" +
  //                                   "                                <p class=\"col-12\">\r\n";
  //     htmlText += data.question.title;
  //     htmlText += "\r\n" +
  //                                   "                                <\/p>\r\n" +
  //                                   "                                <ol>\r\n";
  //     for (const answer of data.question.questionOptions)
  //                                   {
  //                                    htmlText += "                                    <li>";
  //                                    if (answer.correctAnswer === true || answer.correctAnswer === 'true') {
  //                                       htmlText += "<p style=\"color: green\"><strong>";
  //                                       htmlText += answer.answerText;
  //                                       htmlText += "<\/strong><\/p>";
  //                                    } else {
  //                                      htmlText += "<p>";
  //                                      htmlText += answer.answerText;
  //                                      htmlText += "<\/p><\/li>\r\n";
  //                                    }
  //                                   }
  //     htmlText += "                                <\/ol>\r\n" +
  //                                   "                                <div class=\"col-12\">\r\n" +
  //                                   "                                    <div class=\"col-6 text-left\">\r\n" +
  //                                   "                                        <button class=\"blind\" [class.not]=\"data[\'blindGuess\'] === \'false\' || data[\'blindGuess\'] === undefined\">Blind guess<\/button>\r\n" +
  //                                   "                                        <button class=\"review\" [class.not]=\"data[\'toReview\'] === \'false\'\">Review Later<\/button>\r\n" +
  //                                   "                                    <\/div>\r\n" +
  //                                   "                                <\/div>\r\n" +
  //                                   "                            <\/div>\r\n" +
  //                                   "                            <div class=\"col-7\">\r\n" +
  //                                   "                                <h4><strong>Explanation<\/strong><\/h4>\r\n" +
  //                                   "                                <p [innerHTML]=";
  //     htmlText += data.question.answerFeedback;
  //     htmlText += "\"><\/p>\r\n" +
  //                                   "                                <h4><strong>Suggested Reading<\/strong><\/h4>\r\n" +
  //                                   "                                <p>" ;
  //     htmlText += data.question.suggestedReading;
  //     htmlText += "<\/p>\r\n" +
  //                                   "                            <\/div>\r\n" +
  //                                       "                        <\/div>\r\n";
  //                                 }
  //   htmlText +=  "                    <\/div>\r\n" +
  //       "                    <div class=\"col-12\">\r\n" +
  //       "                        <div class=\"row\">\r\n" +
  //       "                            <h1>COULD BE IMPROVED<\/h1>\r\n" +
  //       "                        <\/div>\r\n";
  //   for (const data of this.canImprove) {
  //     htmlText +=  "                        <div class=\"row\" style=\"border: 2px solid grey; margin-bottom: 10px\">\r\n";
  //     if (data.correct === true) {
  //       htmlText += "                            <h3 class=\"col-12\" style=\"color: green\">\r\n" +
  //           "                                Question ";
  //       htmlText += data.sortOrder;
  //       htmlText += " Correct\r\n" +
  //           "                            <\/h3>\r\n";
  //     }
  //     else if (data.correct !== true) {
  //       htmlText += "                            <h3 class=\"col-12\" style=\"color: red\">\r\n" +
  //           "                                Question ";
  //       htmlText += data.sortOrder;
  //       htmlText += " Incorrect\r\n" +
  //           "                            <\/h3>\r\n";
  //     }
  //     htmlText += "                            <div class=\"col-5\">\r\n" +
  //         "                                <p class=\"col-12\"><strong>Category: " ;
  //     if (data.question && data.question.levelOfToughness) {
  //       htmlText += data.question.levelOfToughness.toString;
  //     }
  //     htmlText += "<\/strong><\/p>\r\n" +
  //         "                                <div class=\"col-12\">\r\n" +
  //         "                                    <p>Time spent : ";
  //     htmlText += data.timeTaken;
  //     htmlText += "<\/p>\r\n" +
  //         "                                <\/div>\r\n" +
  //         "                                <p class=\"col-12\">\r\n";
  //     htmlText += data.question.title;
  //     htmlText += "\r\n" +
  //         "                                <\/p>\r\n" +
  //         "                                <ol>\r\n";
  //     for (const answer of data.question.questionOptions)
  //     {
  //       htmlText += "                                    <li>";
  //       if (answer.correctAnswer === true || answer.correctAnswer === 'true') {
  //             htmlText += "<p style=\"color: green\"><strong>";
  //             htmlText += answer.answerText;
  //             htmlText += "<\/strong><\/p>";
  //           } else {
  //             htmlText += "<p>";
  //             htmlText += answer.answerText;
  //             htmlText +=  "<\/p><\/li>\r\n";
  //           }
  //     }
  //     htmlText += "                                <\/ol>\r\n" +
  //         "                                <div class=\"col-12\">\r\n" +
  //         "                                    <div class=\"col-6 text-left\">\r\n" +
  //         "                                        <button class=\"blind\" [class.not]=\"data[\'blindGuess\'] === \'false\' || data[\'blindGuess\'] === undefined\">Blind guess<\/button>\r\n" +
  //         "                                        <button class=\"review\" [class.not]=\"data[\'toReview\'] === \'false\'\">Review Later<\/button>\r\n" +
  //         "                                    <\/div>\r\n" +
  //         "                                <\/div>\r\n" +
  //         "                            <\/div>\r\n" +
  //         "                            <div class=\"col-7\">\r\n" +
  //         "                                <h4><strong>Explanation<\/strong><\/h4>\r\n" +
  //         "                                <p [innerHTML]=\"";
  //     htmlText += data.question.answerFeedback;
  //     htmlText += "\"><\/p>\r\n" +
  //         "                                <h4><strong>Suggested Reading<\/strong><\/h4>\r\n" +
  //         "                                <p>";
  //     htmlText += data.question.suggestedReading;
  //     htmlText += "<\/p>\r\n" +
  //         "                            <\/div>\r\n" +
  //         "<\/div>\r\n";
  //   }
  //   htmlText +=  "<\/div>\r\n" +
  //       "<\/div>\r\n" +
  //       "<\/div>\r\n" +
  //       "<\/div>\r\n" +
  //       "<\/body>\r\n" +
  //       "<\/html>\r\n";
  //   const options = {
  //     "html": htmlText,
  //     "inlinePdf": true,
  //     "fileName": "origFileName",
  //     "options": {
  //       // "orientation": "portrait",
  //       // "pageSize": "A4",
  //       // "headerCenter": "userAnswerSet.paper.title",
  //       // "headerRight": "www.excelonacademy.com",
  //       // "dpi": 300,
  //       // "zoom": 1.25,
  //       // "footerCenter": "Page [page]"
  //       "landscape": "false",
  //       "printBackground": true
  //     },
  //     // "enableToc": true,
  //     // "tocOptions": {
  //     //   "tocHeaderText": "Contents",
  //     //   "tocLevelIndentation": "1em"
  //     // }
  //   };
  //   this.service.createPdf(options).subscribe(res => {
  //
  //   });
  // }


//   fun1() {
//     const contentEl = document.getElementById('contentToConvert');
//     html2canvas(contentEl).then(canvas => {
//       const imgWidth = 208;
//       const pageHeight = 295;
//       const imgHeight = canvas.height * imgWidth / canvas.width;
//       const heightLeft = imgHeight;
//       const contentDataURL = canvas.toDataURL('image/png');
//       const pdf = new jsPDF('p', 'mm', 'a4');
//       const position = 0;
//       // pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
//       // pdf.save('MYPdf.pdf'); // Generated PDF
//     });
//   }
}

@Pipe({name: 'safeHtml'})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {
  }

  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}
