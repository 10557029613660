import {Injectable} from '@angular/core';
import {environment} from '../environments/environment';
import {map} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LinxService {

  headersWithToken: any;

  constructor(private _http: HttpClient) { }

  getHttp() {

    this.headersWithToken = {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('bearerToken'),
      })
    };
  }

  getAnonymousExamsByModules() {
    return this._http.get(environment.linxHost + '/Linx/rest/api/paper/ext/getExamsByModulesAnon')
      .pipe(map(res => res));
  }

  getExamsByModules() {
    this.getHttp();
    return this._http.get(environment.linxHost + '/Linx/rest/api/paper/ext/getExamsByModules', this.headersWithToken)
      .pipe(map(res => res));
  }

  getQuestion(params) {
    this.getHttp();
    return this._http.get(environment.linxHost + '/Linx/rest/api/UserAnswerSet/json/' + params, this.headersWithToken)
      .pipe(map(res => res));
  }

  updateUserAnswerOption(id, payload) {
    this.getHttp();
    return this._http.post(environment.linxHost + '/Linx/rest/api/UserAnswerOption/json/' + id, payload, this.headersWithToken)
      .pipe(map(res => res));
  }

  updateMultipleOptions(payload) {
    const headers = {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('bearerToken'),
        'Content-Type': 'application/json'
      })
    };
    return this._http.post(environment.linxHost + '/Linx/rest/api/userAnswerOption/ext/updateUserAnswerOption', payload, headers)
      .pipe(map(res => res));
  }

  updateUserAnswer(id, payload) {
    this.getHttp();
    return this._http.post(environment.linxHost + '/Linx/rest/api/userAnswer/json/' + id, payload, this.headersWithToken)
      .pipe(map(res => res));
  }

  getCanImprove(params) {
    this.getHttp();
    return this._http.get(environment.linxHost + '/Linx/rest/api/userAnswerSet/ext/getUserAnswersForCouldBeImproved/' + params, this.headersWithToken)
      .pipe(map(res => res));
  }
  getWentWell(params) {
    this.getHttp();
    return this._http.get(environment.linxHost + '/Linx/rest/api/userAnswerSet/ext/getUserAnswersForWentWell/' + params, this.headersWithToken)
      .pipe(map(res => res));
  }
  createPdf(options) {
    const headers = {
      headers: new HttpHeaders({
        Authorization: 'a3548234-619d-4c04-abc9-62eb59415cc0'
      })
    };
    return this._http.post('https://v2018.api2pdf.com//chrome/html', options, headers)
      .pipe(map(res => res));
  }
  difficultyStats(params) {
    this.getHttp();
    return this._http.get(environment.linxHost + '/Linx/rest/api/userAnswerSet/ext/calcDifficultyStats/' + params, this.headersWithToken)
      .pipe(map(res => res));
  }
  confidenceStats(params) {
    this.getHttp();
    return this._http.get(environment.linxHost + '/Linx/rest/api/userAnswerSet/ext/calcConfLevelStats/' + params, this.headersWithToken)
      .pipe(map(res => res));
  }

  getCharts(partner, account, pkey, chart) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'pkey': pkey,
        'Access-Control-Allow-Origin': '*',
        'observe': 'response'
      })
    };
    return this._http.post('https://services.apiplatform.io/v2/' + partner + '/' + account + '/getchartdata', chart, httpOptions)
      .pipe(map(res => res));
  }

  getSubjectQuestionStats(id) {
    this.getHttp();
    return this._http.get(environment.linxHost + '/Linx/rest/api/userAnswerSet/ext/calcSubjectQuestionStats/' + id, this.headersWithToken)
      .pipe(map(res => res));
  }

  getLevel2Synthesis(id) {
    this.getHttp();
    return this._http.get(environment.linxHost + '/Linx/rest/api/userAnswerSet/ext/examDetails/' + id, this.headersWithToken)
      .pipe(map(res => res));
  }

  initiateSocialAuth(provider, callback) {
    this.getHttp();
    return this._http.get(environment.linxHost + '/Linx/rest/api/person/ext/socialConnect?providerId=' + provider + '&callback=' + callback)
      .pipe(map(res => res));
  }

  getJWTTokenForFB(params) {
    this.getHttp();
    return this._http.get(environment.linxHost + '/Linx/rest/api/person/ext/socialGetJwt?' + params, {observe: 'response'})
      .pipe(map(res => res));
  }

  submitExam(examId) {
    this.getHttp();
    return this._http.post(environment.linxHost + '/Linx/rest/api/userAnswerSet/ext/submitExam/' + examId, {}, this.headersWithToken)
      .pipe(map(res => res));
  }

  getGeneralStats(id) {
    this.getHttp();
    return this._http.get(environment.linxHost + '/Linx/rest/api/userAnswerSet/ext/calcGenStats/' + id, this.headersWithToken)
      .pipe(map(res => res));
  }

   getMaxscore(id) {
      this.getHttp();
      return this._http.get(environment.linxHost + '/Linx/rest/api/userAnswerSet/ext/maxScore/' + id, this.headersWithToken)
        .pipe(map(res => res));
    }

  getKnowledgeAreaGroupedByLabel(id) {
    this.getHttp();
    return this._http.get(environment.linxHost + '/Linx/rest/api/userAnswerSet/ext/calcGenStats/' + id + '/question.knowledgeArea.label', this.headersWithToken)
      .pipe(map(res => res));
  }

  getSubjectGroupedByLabel(id) {
    this.getHttp();
    return this._http.get(environment.linxHost + '/Linx/rest/api/userAnswerSet/ext/calcGenStats/' + id + '/question.subject', this.headersWithToken)
      .pipe(map(res => res));
  }

  getPersonDetails(id) {
    this.getHttp();
    return this._http.get(environment.linxHost + '/Linx/rest/api/person/json/' + id, this.headersWithToken)
      .pipe(map(res => res));
  }

  createExamAttempt(id, mode) {
    this.getHttp();
    return this._http.post(environment.linxHost + '/Linx/rest/api/paper/ext/createExamAttempt/' + id + '/' + mode, {}, this.headersWithToken)
      .pipe(map(res => res));
  }

  performModulePayment(payload) {
    const headers = {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('bearerToken'),
        'Content-Type': 'application/json'
      })
    };
    return this._http.post(environment.linxHost + '/Linx/rest/api/module/ext/performModulePayment', payload, {headers: headers.headers})
      .pipe(map(res => res));
  }

  purchaseIntegratedModules(payload) {
    const headers = {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('bearerToken'),
        'Content-Type': 'application/json'
      })
    };
    return this._http.post(environment.linxHost + '/Linx/rest/api/module/ext/performIntegratedModulePayment', payload, headers)
      .pipe(map(res => res));
  }

  leaveUsAMessage(payload) {
    const header = {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('bearerToken'),
        'Content-Type': 'application/json'
      })
    };
    return this._http.post(environment.linxHost + '/Linx/rest/api/genericApplication/ext/doLeaveAMessage', payload, {headers: header.headers, responseType: 'text'})
      .pipe(map(res => res));
  }

  verifyPaymentAfterRedirect(payload) {
    const header = {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('bearerToken'),
        'Content-Type': 'application/json'
      })
    };
    return this._http.post(environment.linxHost + '/Linx/rest/api/examPaymentRequest/ext/verifyPaymentAfterRedirect', payload, {headers: header.headers, responseType: 'text'})
      .pipe(map(res => res));
  }

  addQuestionComment(payload) {
    const header = {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('bearerToken'),
        'Content-Type': 'application/json'
      })
    };
    return this._http.post(environment.linxHost + '/Linx/rest/api/questionUserComment/ext/addQuestionComment', payload, {headers: header.headers})
      .pipe(map(res => res));
  }

  addToTotalTime(id, time) {
    const header = {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('bearerToken'),
        'Content-Type': 'application/json'
      })
    };
    return this._http.post(environment.linxHost + '/Linx/rest/api/userAnswerSet/ext/addTotalTime/' + id + '/' + time, {}, {headers: header.headers})
      .pipe(map(res => res));
  }

  integratedModulePrice() {
    this.getHttp();
    return this._http.get(environment.linxHost + '/Linx/rest/api/module/ext/integratedModulePrice', this.headersWithToken)
      .pipe(map(res => res));
  }

  updateMobileNumber(id, payload) {
    const headers = {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('bearerToken'),
        'Content-Type': 'application/json'
      })
    };
    return this._http.post(environment.linxHost + '/Linx/rest/api/person/json/' + id, payload, {headers: headers.headers})
      .pipe(map(res => res));
  }

  videoDetails() {
    this.getHttp();
    return this._http.get(environment.linxHost + '/Linx/rest/api/videoLink/ext/videoLinks')
      .pipe(map(res => res));
  }

  getModuleCardDetails() {
    this.getHttp();
    return this._http.get(environment.linxHost + '/Linx/rest/api/module/ext/moduleCard', this.headersWithToken)
      .pipe(map(res => res));
  }

  getTopNExams(numResults) {
    this.getHttp();
    return this._http.get(environment.linxHost + '/Linx/rest/api/userAnswerSet/ext/topNExams/' + numResults, this.headersWithToken)
      .pipe(map(res => res));
  }

  getPerformanceSummaryModule() {
    this.getHttp();
    return this._http.get(environment.linxHost + '/Linx/rest/api/module/ext/performanceSummaryModule', this.headersWithToken)
      .pipe(map(res => res));
  }

  getRelativePerformance(id) {
    this.getHttp();
    return this._http.get(environment.linxHost + '/Linx/rest/api/userAnswer/ext/relativePerformance/'+ id, this.headersWithToken)
      .pipe(map(res => res));
  }

  getPersonalPerformance() {
    this.getHttp();
    return this._http.get(environment.linxHost + '/Linx/rest/api/userAnswer/ext/personPerformance', this.headersWithToken)
      .pipe(map(res => res));
  }
  //===========For PayU integration
  getParamsForPaymentPageWithContinueURL(payload) {
    const header = {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('bearerToken'),
        'Content-Type': 'application/json'
      })
    };

    return this._http.post(environment.linxHost + '/Linx/rest/api/examPaymentRequest/ext/getParamsForPaymentPageWithContinueURL', payload, {headers: header.headers, responseType: 'text'})
      .pipe(map(res => res));
  }


  getParamsForPaymentPage2(payload) {
    const header = {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('bearerToken'),
        'Content-Type': 'application/json'
      })
    };

    return this._http.post(environment.linxHost + '/Linx/rest/api/examPaymentRequest/ext/getParamsForPaymentPage2', payload, {headers: header.headers, responseType: 'text'})
      .pipe(map(res => res));
  }

  payUCallBack(payload) {
    const header = {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('bearerToken'),
        'Content-Type': 'application/json'
      })
    };

    return this._http.post(environment.linxHost + '/Linx/rest/api/examPaymentRequest/ext/payUCallBack', payload, {headers: header.headers, responseType: 'text'})
      .pipe(map(res => res));
  }

  getPayUPaymentPageURL(payload) {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer d9a4536e-62ba-4f60-8017-6053211d3f47'
      })
    };

    return this._http.post('https://secure.snd.payu.com/api/v2_1/orders', payload, {headers: header.headers, responseType: 'json'})
      .pipe(map(res => res));
  }

  getPaymentStatus(payload) {
    const header = {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('bearerToken'),
        'Content-Type': 'application/json'
      })
    };

    return this._http.post(environment.linxHost + '/Linx/rest/api/examPaymentRequest/ext/getPaymentStatus', payload, {headers: header.headers, responseType: 'text'})
      .pipe(map(res => res));
  }

  discountedModulePrice(moduleId, code) {
    this.getHttp();
    return this._http.get(environment.linxHost + '/Linx/rest/api/modulesPurchased/ext/discountedModulePrice/' + moduleId + '/' + code, this.headersWithToken)
      .pipe(map(res => res));
  }

  discountedIntegratedModulePrice(code) {
    this.getHttp();
    return this._http.get(environment.linxHost + '/Linx/rest/api/modulesPurchased/ext/discountedIntegratedModulePrice/' + code, this.headersWithToken)
      .pipe(map(res => res));
  }

  getWhatNew() {
    this.getHttp();
    return this._http.get(environment.linxHost + '/Linx/rest/api/whatsNew/ext/whatsNew' , this.headersWithToken)
      .pipe(map(res => res));
  }

  createFeedbackSurvey(id) {
      const header = {
        headers: new HttpHeaders({
          Authorization: localStorage.getItem('bearerToken'),
          'Content-Type': 'application/json'
        })
      };
      return this._http.post(environment.linxHost + '/Linx/rest/api/feedbackAnswerSet/ext/createFeedbackSurvey/' + id, {}, {headers: header.headers})
        .pipe(map(res => res));
  }

  submitFeedbackSurvey(payload) {
    const header = {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('bearerToken'),
        'Content-Type': 'application/json'
      })
    };
    return this._http.post(environment.linxHost + '/Linx/rest/api/feedbackAnswerSet/ext/submitFeedbackSurvey', payload, {headers: header.headers})
      .pipe(map(res => res));
  }

  telegramGetUpdates(token) {
    return this._http.get("https://api.telegram.org/bot" + token + "/" + "getUpdates")
      .pipe(map(res => res));
  }

  telegramGetChannelInfo(token, chat_id) {
    return this._http.get("https://api.telegram.org/bot" + token + "/" + "getChat?chat_id=" + chat_id)
      .pipe(map(res => res));
  }

  getMainsPdfs() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'pkey': '3fe32c6d274f2a003fd8bfb3214d153c',
        'Access-Control-Allow-Origin': '*',
        'observe': 'response'
      })
    };
    return this._http.get("https://services.apiplatform.io/v1/data/excelonacad/excelonacad/s3connectors/excelonacad/list-files?folder=mains-marathon", httpOptions)
      .pipe(map(res => res));
  }

  getPdfFromS3(url) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'observe': 'response'
      })
    };
    return this._http.get(url, {observe: 'response', headers: httpOptions.headers})
      .pipe(map(res => res));
  }
}
