import { Component, OnInit } from '@angular/core';
import {LinxService} from '../linx.service';

@Component({
  selector: 'app-telegram-page',
  templateUrl: './telegram-page.component.html',
  styleUrls: ['./telegram-page.component.scss']
})
export class TelegramPageComponent implements OnInit {
  chatData:any;
  chat_id = "@excelonacademy";
  infoData = {
    infoHeading:"",
    infoSubheading:"",
    data: [
      {
        value:"642",
        heading:"subscribers"
      },
      {
        value:"4",
        heading:"photos"
      },
      {
        value:"8",
        heading:"videos"
      },
      {
        value:"82",
        heading:"files"
      },
      {
        value:"104",
        heading:"links"
      }
    ]
  };
  constructor(public service: LinxService) { }

  ngOnInit(): void {
    var token = `1925872606:AAGCmQnb77bI8893wYP90EzPaP5UUarnXAs`;
    this.telegramGetUpdates(token);
    this.telegramGetChannelInfo(token, this.chat_id);
    this.smoothScrollToLatestQues();
  }

  telegramGetUpdates(token) {
    this.service.telegramGetUpdates(token).subscribe(response => {
      this.chatData = response['result'];
      console.log(this.chatData)
    });
  }

  telegramGetChannelInfo(token, chat_id) {
    this.service.telegramGetChannelInfo(token, chat_id).subscribe(response => {
      var result = response['result'];
      // console.log(result);
      this.infoData.infoHeading = result.title;
      this.infoData.infoSubheading = "@" + result.username;
    });
  }

  smoothScrollToLatestQues() {
    setTimeout(() => {
      document.getElementById('last_div').scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }, 1000);
  }
}
