<div class="container-fluid" style="background: #003366;">
  <div class="d-flex flex-column flex-lg-row align-items-center justify-content-between banner">
    <div class="p-2" style="font-size: 16px; color: #ffffff;"><i>UPSC Prelims 2022 <span style="font-size: 18px; font-weight: 700; color: #ffffff;">Full Package</span> is available now</i></div>
<!--    <div class="d-flex align-items-center">-->
<!--      <a class="p-2" href="https://www.instagram.com/excelonacademy/" target="_blank"> <img src="/assets/insta.svg"  alt="" /></a>-->
<!--      <a class="p-2" href="https://www.linkedin.com/company/excelon-academy/" target="_blank"><img src="/assets/li.svg"  alt="" /></a>-->
<!--      <a class="p-2" href="https://www.facebook.com/ExcelOn-Academy-108398970865602" target="_blank"><img src="/assets/fb.svg"  alt="" /></a>-->
<!--      <a class="p-2" href="https://www.quora.com/profile/T-Uday-Kumar-1" target="_blank"><img src="/assets/quora_circle.png" width="40" alt="" /></a>-->
<!--      <div class="p-2">-->
<!--        <a href="https://t.me/excelonacademy" target="_blank"><img src="/assets/telegram1.png" alt="" width="30"></a>-->
<!--        <a class="p-2" href="https://t.me/excelonacademy" target="_blank" style="color: #f3f7fd; ">Join us on telegram</a>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</div>

<div id="sticky-top">
  <app-navbar></app-navbar>
</div>

<div class="container-fluid">
  <div class="row p-2">
    <div class="col-lg-6 col-md-12 col-sm-12 col-xl-6 order-0">
      <div class="p-2 main-heading mt-4">{{heading}}</div>
      <div class="heading mt-4 pt-2" [innerHTML]="subheading2"></div>
      <div class="sub-heading1 mt-3" [innerHTML]="subheading1"></div>
      <div class="sub-heading mt-4">{{subheading}}</div>
      <div class="get-started-button-div my-4 d-flex">
        <button (click)="onClickVideoTour()" class="video-button py-3 mr-2"><img src="/assets/Vectorplay.svg"> Video
          Tour</button>
        <button [routerLink]="['/modules']" class="freetest-button py-3">Take a Free Test</button>
      </div>
      <div class="popup__overlay">
        <div class="popup" id="popupVid">
          <a href="#" class="popup__close" (click)="onClickVideoClose()">x</a>
          <iframe src="https://www.youtube.com/embed/0JGoZsM0lPI?autoplay=1&enablejsapi=1&mute=1" frameborder="0"
            allowfullscreen></iframe>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-md-12 col-sm-12 col-xl-6">
      <img src="/assets/img/homepage-landing.png" alt="image">
    </div>
  </div>

  <div class="mt-lg-4 p-4 bg-light">
    <div class="row align-items-center">
      <div class="col-lg-4 col-md-4 col-sm-12 col-xl-4">
        <div class="d-flex align-items-center justify-content-center">
          <p class="partner_heading p-4">Partner</p>
        </div>
      </div>
      <div class="col-lg-8 col-md-8 col-sm-12 col-xl-8 card shadow partner_div pl-lg-4 my-lg-5">
        <div class="row align-items-center py-3">
          <div class="col-lg-6 col-md-6 col-sm-12 col-xl-6">
            <div class="my-4 partner_img">
              <a href="https://www.capstoneiasacademy.com/" target="_blank"><img src="./assets/capstoneLogo.png" alt="capstone"></a>
            </div>
            <div class="mt-4 partner">
              Crack CSE with <br/> Dr. SANTHOSH BABU IAS
            </div>
            <div class="mt-2 partner-sub-heading">
              The man who transformed the lives of many, is now here to help the Aspirants to realize their dream.
            </div>
            <div class="mt-2 partner-sub-heading1">
              <a href="http://www.santhoshbabu.org/" target="_blank">www.santhoshbabu.org</a>
            </div>
            <a href="https://capstoneiasacademy.com/" target="_blank" class="btn mt-2 start_learning">Get Started</a>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 col-xl-6">
            <img class="partner_image" src="./assets/santoshbabu2.jpeg" alt="santoshbabu">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-lg-4 p-4 bg-light">
    <div class="row align-items-center">
      <div class="col-lg-4 col-md-4 col-sm-12 col-xl-4 text-center">
        <p class="partner_heading p-4">Features</p>
        <p class="partner_heading1 p-2">Our #SmartTestSeries come loaded with useful features which will assist you in clearing UPSC prelims</p>
      </div>
      <div class="col-lg-8 col-md-8 col-sm-12 col-xl-8 py-4 card shadow partner_div justify-content-center">
        <div class="row">
          <div class="col-12">
            <ul class="cards">
              <li class="cards_item" *ngFor="let item of featuresData">
                <div class="card shadow featureCard p-3">
                  <div class="card_content">
                    <img src="{{item.svg}}" alt="">
                    <div class="card_title">{{item.heading}}</div>
                    <div class="py-2 card_sub">{{item.subheading}}</div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-lg-4 p-4 bg-light">
    <app-testimonial></app-testimonial>
  </div>

  <div class="mt-lg-4 p-4 bg-light">
    <div class="row align-items-center">
      <div class="col-lg-4 col-md-4 col-sm-12 col-xl-4 text-center">
        <p class="partner_heading p-4">Featured Tests</p>
        <p class="partner_heading1 p-2">Choose from Test Modules to suit your preparation style or purchase the Integrated Module at a discount to
          get everything Unlearn has to offer. <br/>Utilize 3 attempts per test & Valid for 365 days from date of purchase</p>
        <button [routerLink]="['/modules']" class="btn shadow explore-but">Explore</button>
      </div>
      <div class="col-lg-8 col-md-8 col-sm-12 col-xl-8 py-4 mt-4 card shadow partner_div justify-content-center">
        <div class="row">
          <div class="col-12">
            <ul class="cards">
              <li class="cards_item" [routerLink]="['/modules']" style="cursor: pointer;" *ngFor="let item of bottomData">
                <div class="card shadow featureCard p-3">
                  <div class="card_content">
                    <img src="{{item.image}}" alt="">
                    <div class="card_title mt-4">{{item.title}}</div>
                    <div class="py-2 card_sub">{{item.content}}</div>
                  </div>
                  <div class="card_sub1 mt-auto py-2 d-flex justify-content-between">
                    <div>{{item.ques}}</div>
                    <div *ngIf="item['title'] !== 'Free Tests'"><a><img src="assets/img/rupee.png" />{{item.price}}</a></div>
                    <div *ngIf="item['title'] === 'Free Tests'"><a>{{item.price}}</a></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="card shadow partner_div p-4 mt-4">
      <div class="d-flex flex-wrap justify-content-between align-items-center">
        <a class="wire-but p-4 m-2 ml-lg-4"><b>5000 </b> UPSC standard questions</a>
        <a class="wire-but p-4 m-2 ml-lg-4"><b>3 </b> Retakes per test</a>
        <a class="wire-but p-4 m-2 ml-lg-4"><b>4 </b> Sample Free Tests & analyses</a>
        <a class="wire-but p-4 m-2 ml-lg-4">Valid for 365 days from date of purchase</a>
      </div>
    </div>
  </div>

  <div class="mt-lg-4 p-4 bg-light">
    <div class="row align-items-center px-4 text-center">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xl-6 text-center">
        <p class="partner_heading mt-4">Data Analytics</p>
        <p class="partner_heading1 p-2 mt-4">Our #SmartTestSeries use Data Analytics to evaluate aspirants’ performance, identify trends, recognize
          blind spots and provide accurate guidance on strengths and weaknesses pertaining to their test taking. We
          aim to assist maximum aspirants to successfully convert their knowledge to marks through our data driven
          #SmartTestSeries. We are continually investing to refine our algorithms to deliver deeper insights for
          each individual student based on their prior testing performance on the website.</p>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xl-6">
        <div class="row">
          <div class="col-12">
            <img class="partner_div" src="./assets/img/data-analytics.png" alt="First slide">
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <div class="row">
          <div class="col-12 col-md-6">
            <img class="" src="./assets/img/data-analytics.png" alt="First slide">
          </div>
          <div class="col-12 col-md-6 d-flex flex-column justify-content-center dataAnalytics">
            <h2>Data Analytics</h2>
            <p>Our #SmartTestSeries use Data Analytics to evaluate aspirants’ performance, identify trends, recognize
              blind spots and provide accurate guidance on strengths and weaknesses pertaining to their test taking. We
              aim to assist maximum aspirants to successfully convert their knowledge to marks through our data driven
              #SmartTestSeries. We are continually investing to refine our algorithms to deliver deeper insights for
              each individual student based on their prior testing performance on the website.</p>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <!-- what's new popup -->
  <div class="backdrop" [ngStyle]="{'display':showPopup}"></div>
  <div [ngStyle]="{'display': showPopup}" class="modal fade show" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
      <div class="modal-content main_div">
        <div class="m-2 center_div">
          <div class="d-flex justify-content-between">
            <h2 class="pt-4 pl-4 whatNewTitle">What's new</h2>
            <button type="button" class="popupClose" (click)="whatNewPopupClose()" aria-label="Close">&times;</button>
          </div>
          <div class="modal-body">
            <ul class="list-group list-group-flush"  style="font-size: 16px;">
              <li class="list-group-item main_div mt-2" *ngFor="let item of whatNewList">
                <div class="d-flex justify-content-start align-items-center">
                  <img src="/assets/new6.png" alt="New" width="70">
                  <a [href]="item.url" target="_blank" class="whatNewLink"><span class="ml-4">{{ item.label }}</span></a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="footer-fixed">
  <a (click)="sendMessage()" class="sendAMessageButton"><img src="/assets/sendMsg4.png" alt="Send Message" width="70"></a>
</div>

<app-footer></app-footer>
