<div class="row bg-light justify-content-center p-5">
  <div *ngFor="let data of videoList" class="card shadow col-sm-4">
    <div class="container">
      <iframe [src]="data.videoLink | safelink"
      allowfullscreen></iframe>
    </div>
    <div class="card-body">
        <h2 class="card-title">{{ data.title }}</h2>
        <!-- <p class="card-text">{{ data.desc }}</p> -->
        <!-- <a href="#" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter1">{{ data.btnText }}</a> -->
    </div>
  </div>
</div>  
<!-- Modals for future usage -->
<!-- <div class="modal fade" id="exampleModalCenter1" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
      <div class="embed-responsive embed-responsive-16by9 z-depth-1-half">
          <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/tKTu2Jwi6rg"
          allowfullscreen></iframe>
      </div>
      </div>
  </div>
</div> -->
