<div class="row">
  <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-center">
    <h3 style="font-weight: bolder;"> Leave a Message
<!--      <span style="float: right;"> -->
<!--        <i class="fa fa-close" (click)="close()"></i>-->
<!--      </span>-->
    </h3>
  </div><hr>
</div>
<div style="padding: 0.5rem;" class="row">
  <label class="label-style">
    Your Email ID
  </label>
  <input required type="text" [(ngModel)]="email" [value]="email" class="form-control"><br>
  <br>
  <label style="margin-top: 0.7rem;" class="label-style">
    Leave us a message
  </label>
  <textarea style="height: 115px;width: 100%;border-radius: 4px;" [(ngModel)]="message" [value]="message"></textarea>
</div><br>
<div class="row">
  <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-right">
    <button class="message" (click)="leaveMessage();"> Leave a Message </button>
    <button class="message" (click)="close();"> Close </button>
  </div>
</div>
