import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-common-component',
  templateUrl: './common-component.component.html',
  styleUrls: ['./common-component.component.scss']
})
export class CommonComponentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

@Component({
  selector: 'app-loader',
  templateUrl: 'loader-with-progress.component.html',
  styleUrls: ['loader-with-progress.component.css']
})
export class LoaderWithProgressComponent implements OnInit {

  incr: any;
  progressMessage: any;
  progress = 0;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<LoaderWithProgressComponent>) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {

  }
}
