import { Component, OnInit } from "@angular/core";
import {LinxService} from '../linx.service';
import { ChartDataSets, ChartOptions, ChartType } from "chart.js";
import { Label } from "ng2-charts";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  constructor(public service: LinxService) {}

  public integratedCourse_title: any = "";
  public integratedCourse_description: any = "";
  public integratedCourse_completedQues: any = 0;
  public integratedCourse_subDescription: any = "";
  public moduleData: any;
  public recentTestData : any;
  public chartColors: any[] = [];
  public barChartData: ChartDataSets[] = [];
  public barChartLabels: Label[] = [];
  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      labels: {
        render: () => {}
      },
      datalabels: {
        anchor: "end",
        align: "end",
      },
    },
  };
  public barChartLegend = false;
  public barChartType: ChartType = "bar";
  public resultData: any[];
  public performanceData: any;
  public performanceSummaryResponse : any;
  public moduleList = [];
  public recentTestList = [3, 4, 5, 6, 7, 8, 9, 10];
  tab: any;
  backgroundColor = ["#FF7360", "#6FC8CE", "#fccc40", "#ff6699", "#B9E8E0", "#92DCE5", "#993333", "#ffcc00", "#00ffff", "#006666", "#9900ff", "#666633", "#FF7360", "#FF7360", "#6FC8CE", "#fccc40", "#ff6699", "#B9E8E0", "#92DCE5", "#993333", "#ffcc00", "#00ffff", "#006666", "#9900ff", "#666633", "#FF7360", "#FF7360", "#6FC8CE", "#fccc40", "#ff6699", "#B9E8E0", "#92DCE5", "#993333", "#ffcc00", "#00ffff", "#006666", "#9900ff", "#666633", "#FF7360"];

  selectModule(e) {
    this.setsubjectsOnModuleChange(e.target.value);
  }

  selectNTests(e) {
    this.getTopNExams(e.target.value);
  }

  setAllData() {
    this.getModuleCardDetails();
    this.getTopNExams(3);
    this.getPerformanceSummaryModule("CSAT Test Series (400 Questions)"); //module name Hardcoded as of now
    this.getPersonalPerformance();
  }

  getPersonalPerformance() {
    this.service.getPersonalPerformance().subscribe(response => {
      if(response) {
        let result = [
          {
            "title": "Total Tests",
            "value": response['totalTests'],
            "logo": "/assets/Framedb1.svg",
          },
          {
            "title": "Total Questions",
            "value": Math.ceil(response['count']/50)*50, //Round to 50
            "logo": "/assets/Questions And Answers 1logo3.svg",
          },
          {
            "title": "Questions Attempted",
            "value": response['answered'],
            "logo": "/assets/Framelogo5.svg",
          },
          {
            "title": "Questions Correct",
            "value": response['correct'],
            "logo": "/assets/Framelogo2.svg",
          },
          {
            "title": "Accuracy",
            "value": +(100 * response['correct'] / response['count']).toFixed(2) + '%',
            "logo": "/assets/Grouplogo4.svg",
          },
          {
            "title": "Avg time/Question",
            "value": +(response['timeTaken']).toFixed(2) + 's',
            "logo": "/assets/Questions And Answers 1logo3.svg",
          }
          
        ];    
        this.resultData = result;
      }
  });
}

  getModuleCardDetails() {
    this.service.getModuleCardDetails().subscribe(response => {
      if(response) {
        let modules = [];
        let totalTests = 0;
        let completedTests = 0;
  
        modules.push(response);
        for (let i = 0; i < modules[0].length; i++) {
          modules[0][i].completePercentage = +(modules[0][i].completedTests * 100 / modules[0][i].totalTests).toFixed(0);
          totalTests = totalTests + modules[0][i].totalTests;
          completedTests = completedTests + modules[0][i].completedTests;
        }
        this.integratedCourse_title = "Integrated Course";
        this.integratedCourse_description = "Purchase the Integrated Module with a nominal additional charge and benefit from what ExcelOn Academy has to offer.  Utilise three attempts per test & validity is up to 30 June 2022.";
        this.integratedCourse_completedQues = +(completedTests * 100 / totalTests).toFixed(1);
        this.integratedCourse_subDescription = "You have completed " + completedTests + (completedTests === 1 ? " test out of " : " tests out of ") + totalTests + " tests.";
        this.moduleData = modules[0];
      } else {
        this.moduleData = [];
        this.integratedCourse_title = "";
        this.integratedCourse_description = "";
        this.integratedCourse_completedQues = 0;
        this.integratedCourse_subDescription = "";
      }
    });

  }

  getTopNExams(number) {
    this.service.getTopNExams(number).subscribe(response => {
      if(response) {
        let modules = [];
        modules.push(response);
        for (let i = 0; i < modules[0].length; i++) {
          modules[0][i].totalScore = (modules[0][i].totalScore).toFixed(2);
          modules[0][i].timeSubmitted = this.getLocalDate(modules[0][i].timeSubmitted);
          modules[0][i].accuracy = +(modules[0][i].numCorrect * 100 / modules[0][i].totalQuestions).toFixed(2) + '%';
          modules[0][i].avgTimePerQuestion = this.getFormatedTime((modules[0][i].avgTimePerQuestion / 1000).toFixed(1));
          modules[0][i].weakAreas = this.getWeakAreas(modules[0][i].testId);
        }
        this.recentTestData = modules[0];
      } else this.recentTestData = [];
    });
  }

  getWeakAreas(testId) {
    let weakAreaArr = [];
    this.service.getKnowledgeAreaGroupedByLabel(testId).subscribe(response => {
      if(response) {
        let responseArr;
        responseArr = response;
        responseArr.forEach((x) => {
          if(x.ratioFeedback.label === "Improvement Required") {
            weakAreaArr.push(x);
          }
        });
      }
    });
    return weakAreaArr;
  }

  getLocalDate(dateInUTC) {
    let localDate = new Date().toISOString();
    let today_year = localDate.substring(0, 4);
    let today_month = localDate.substring(5, 7);
    let today_day = localDate.substring(8, 10);
    //let utcdate = dateInUTC.substring(0, 10);
    let year = dateInUTC.substring(0, 4);
    let month = dateInUTC.substring(5, 7);
    let day = dateInUTC.substring(8, 10);
    // let hour = dateInUTC.substring(11, 13);
    // let minute = dateInUTC.substring(14, 16);
    // let second = dateInUTC.substring(17, 19);

    if(today_day == day && today_month == month && today_year == year) return "Today";
    else return day + ' ' + this.getMonth(month) + ' ' + year;
  }

  getMonth(num) {
    if(num == 1) {
      return "Jan";
    }
    if(num == 2) {
      return "Feb";
    }
    if(num == 3) {
      return "Mar";
    }
    if(num == 4) {
      return "Apr";
    }
    if(num == 5) {
      return "May";
    }
    if(num == 6) {
      return "Jun";
    }
    if(num == 7) {
      return "Jul";
    }
    if(num == 8) {
      return "Aug";
    }
    if(num == 9) {
      return "Sep";
    }
    if(num == 10) {
      return "Oct";
    }
    if(num == 11) {
      return "Nov";
    }
    if(num == 12) {
      return "Dec";
    }
  }

  getPerformanceSummaryModule(module) {
    this.service.getPerformanceSummaryModule().subscribe(response => {
      if(response) {
        let score = [];
        let subjectname = [];    
        let modules = [];
        let data = [];
        let bgColor = [];
        let moduleArr = []

        modules.push(response);

        for (let i = 0; i < modules[0].length; i++) {
          moduleArr.push(modules[0][i].module);
          if(modules[0][i].module === module) {
            modules[0][i].avgTotal = +(modules[0][i].avgTotal).toFixed(2);
            modules[0][i].percentage = +(modules[0][i].percentage).toFixed(2);
            modules[0][i].subjectColor = this.backgroundColor[i];
            score.push(modules[0][i].avgTotal);
            subjectname.push(modules[0][i].subject)
            data.push(modules[0][i]);
            bgColor.push(this.backgroundColor[i]);
          }
        }

        this.moduleList = [...new Set(moduleArr)];
        let chartColorObj = {
          backgroundColor : bgColor
        };
    
        this.chartColors.push(chartColorObj);
        this.performanceSummaryResponse = modules[0];
        this.performanceData = data;
        this.barChartData = [ { data : score, label : ''}];
        this.barChartLabels = subjectname;
      } else this.performanceData = [];
    });

  }

  setsubjectsOnModuleChange(module) {
    let responseData = this.performanceSummaryResponse;
    this.barChartData = [{ data : [], label : ''}];
    this.barChartLabels = [];
    this.performanceData = [];
    let score = [];
    let bgColor = [];
    this.chartColors = [];

    for (let i = 0; i < responseData.length; i++) {
      if(responseData[i].module === module) {
        responseData[i].avgTotal = +(responseData[i].avgTotal).toFixed(2);
        responseData[i].percentage = +(responseData[i].percentage).toFixed(2);
        responseData[i].subjectColor = this.backgroundColor[i];
        score.push(responseData[i].avgTotal);
        this.barChartLabels.push(responseData[i].subject)
        this.performanceData.push(responseData[i]);
        bgColor.push(this.backgroundColor[i]);
      }
    }
    let chartColorObj = {
      backgroundColor : bgColor
    };

    this.chartColors.push(chartColorObj);
    this.barChartData = [ { data : score, label : ''}];
  }

  getFormatedTime(secs) {
    var sec_num = parseInt(secs, 10)
    var hours   = Math.floor(sec_num / 3600)
    var minutes = Math.floor(sec_num / 60) % 60
    var seconds = sec_num % 60

    return [hours,minutes,seconds]
        .map(v => v < 10 ? "0" + v : v)
        .filter((v,i) => v !== "00" || i > 0)
        .join(":")
  }

  ngOnInit(): void {
    this.tab = "dashboard";
    this.setAllData();
  }

  changeTab(val) {
    this.tab = val;
  }
}