<app-navbar></app-navbar>

<div style="margin-top: 2rem;background: #f2f2f2;" class="container-fluid">
  <div class="row justify-content-center">
    <div style="padding: 1rem; padding-top: 2.2rem;" class="col-md-11 col-lg-11 col-sm-11 col-xs-11">
      <h1>About Unlearn</h1>
    </div>
  </div>
</div>
<div style="padding: 1rem;" class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-md-11 col-lg-11 col-sm-11 col-xs-11">
      <p>Unlearn is an online platform to deliver quality test series for UPSC prelims exam. Its data-driven algorithm enables precise and detailed feedback on Strength, Weakness and Blindspots of the student and auto-suggests where to improve and what to study. The academy has been founded with the objective of helping aspirants “know themselves better” by knowing their weak areas and blind spot.</p>
      <p>Unlearn has been co-founded by Mr. T. Uday Kumar, an IIT Madras Alumnus who cleared UPSC Indian Engineering Service 2000 in his first attempt, and Mr. Muthu Kumar Raju, a U.S.-based tech entrepreneur specialising in building cutting-edge technology platforms for start-ups and aspiring businesses. Unlearn has been incubated by IIT Madras Incubation Cell.</p>
      <p>The academy has been founded with two major objectives:</p>
      <div style="margin-left: 1.5rem;">
        <p><b>Make the test series affordable to an aspirant</b>  – this is done by, firstly, have a highly reasonable cost for each module. The aspirant needs to pay only for those modules that he/she wishes to attempt from the available modules.</p>
        <p>Provides <b>data-driven actionable insights to candidates</b> to improve their chances of success in this ultra-competitive exam.</p>
      </div>
      <p>The founders are united in their vision of enabling the aspirants to better understand their capabilities at an affordable rate from the comfort of their homes.</p>
    </div>
  </div>
</div>
<div style="margin-top: 2rem;background: #f2f2f2;" class="container-fluid">
  <div class="row justify-content-center">
    <div style="padding: 1rem; padding-top: 2.2rem;" class="col-md-11 col-lg-11 col-sm-11 col-xs-11">
      <h1>About civil services examination</h1>
    </div>
  </div>
</div>
<div style="padding: 1rem;" class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-md-11 col-lg-11 col-sm-11 col-xs-11">
      <p>The Civil Services Examination (CSE) is a Nationwide Competitive Examination in India conducted by the Union Public Service Commission for recruitment to various Civil Services of the Government of India, including the Indian Administrative Service (IAS), Indian Foreign Service (IFS), and Indian Police Service (IPS).It is conducted in three phases - a preliminary examination consisting of two objective-type papers (General Studies Paper I and General Studies Paper II known as the Civil Service Aptitude Test or CSAT), and a main examination consisting of nine papers of conventional type, in which two papers are qualifying and marks of seven are counted followed by a personality test (interview).</p>
      <p>It is considered to be the most difficult competitive examination in India. A single attempt takes two complete years of preparation - one year before the prelims and one year from prelims to interview. On average, the number of candidates appearing for the preliminary examination is approximately 5,00,000, each year. Of this, around 1000 make it to the final list , with around 100 getting the coveted IAS. Hence the ratio of success of becoming an IAS through this exam is 100/5,00,000 i.e. one in every 5,000.</p>
    </div>
  </div>
</div>
<div style="margin-top: 2rem;background: #f2f2f2;" class="container-fluid">
  <div class="row justify-content-center">
    <div style="padding: 1rem; padding-top: 2.2rem;" class="col-md-11 col-lg-11 col-sm-11 col-xs-11">
      <h1>Story behind Logo and Tagline</h1>
    </div>
  </div>
</div>
<div style="padding: 1rem;" class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-md-11 col-lg-11 col-sm-11 col-xs-11">
      <p>The image is that of Pantheon (of Rome) - It symbolizes authority, fame and importance. This is inline with aspirants trying for key Government related jobs.</p>
      <p>The pillars of Pantheon are supported by "Tie" indicating corporates. This indicate that Unlearn will also be focusing on exams that might lead to a corporate career (like MBA).</p>
      <p>Pantheon supported by tie is also an indication of the fact that public and private sector are linked and work together for the country. A person can be part of either of them to reach position of authority.</p>
      <p>The tagline "Be the change" is a famous quote of Mahatma Gandhi - Someone who has appeal beyond Government sector. This is moral message which exorts people to "do" rather than "complain"</p>
    </div>
  </div>
</div>
<div style="margin-top: 2rem;background: #f2f2f2;" class="container-fluid">
  <div class="row justify-content-center">
    <div style="padding: 1rem; padding-top: 2.2rem;" class="col-md-11 col-lg-11 col-sm-11 col-xs-11">
      <h1>Contact Us</h1>
    </div>
  </div>
</div>
<div style="padding: 1rem;" class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 text-center">
      <span><img height="30" src="/assets/img/phone.jpg"  alt="" /> &nbsp; +91 44 46314533</span>
    </div>
    <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 text-center"></div>
    <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 text-center"></div>
    <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 text-center">
      <span><img height="30" src="/assets/img/mail.png"  alt="" /> &nbsp;  <span><a href="mailto:info@Unlearnacademy.com" style="color: #212121;">info@Unlearnacademy.com</a></span></span>
    </div>
  </div>
</div>

<app-footer></app-footer>
