<app-navbar></app-navbar>

<div class="row" *ngIf="!flag" style="padding-top:100px;">
  <span class="col-lg-5 col-md-5"></span>
  <mat-spinner></mat-spinner>
  <span class="col-lg-4 col-md-4"></span>
</div>
<div class="row grid-in-mobile" *ngIf="flag" style="padding-top:25px; background-color: #f2f2f2">
  <div class="col-lg-4 col-md-4">
    <app-list [list]="questions" [original]="completeData['userAnswers']" [examId]="examId" [blindCount]="blindCount"
              [reviewCount]="reviewCount" [answeredCount]="answeredCount" [paper]="completeData['paper']"
              [notAnsweredCount]="notAnsweredCount"></app-list>
  </div>
  <div class="col-lg-8 col-md-8">
    <div class="row" style="padding-left:10px; padding-right:50px">
      <mat-card class="col-lg-12 col-md-12" style="margin-bottom: 20px;">
        <div class="col-lg-5 col-md-5"></div>
          <div>Question {{id}} of {{completeData['userAnswers'].length}}
            <span style="float: right;">
<!--              <countdown [config]="{leftTime: completeData['paper']['maxTimeMS']/1000, notify: [30, 60, 1]}" (event)="clickone($event)"></countdown>-->
<!--              <strong style="color: red">{{counter | date:'HH:mm:ss'}}</strong>-->
<!--              <countup-timer [startTime]="completeData['userAnswers'][this.id -1]['timeTaken']"></countup-timer>-->
                  <label style="color: red" id="hours">00</label><span style="color: red">:</span><label style="color: red" id="minutes">00</label><span style="color: red">:</span><label style="color: red" id="seconds">00</label>
            </span>
            <span style="float: right;">
              <p style="color: #9A9595;">Time taken for this question : &nbsp;</p>
            </span>
          </div>
        <hr style="color: lightgray">
        <div class="col-lg-12 col-md-12" style="padding-left:10px;">
          <!--      <p class="row" style="color:dimgray">Question {{id}} of 100</p>-->
          <app-ques [question]="question" [questionType]="questionType" class="row"></app-ques>
          <br>
          <p class="row" style="color:dimgray;">Your Answer</p>
          <app-ans [answers]="answers" [completeAnswerObject] = "completeData['userAnswers'][id-1]" [answerType]="answerType" (answer)="updateAns($event)" class="row"></app-ans>
          <div class="row" style="margin-top:1rem">
            <div style="width: 100%;">
              <button (click)="reviewLater()" class="button-style" style="font-weight: bold" [ngClass]="{'button-style-toReview': completeData['userAnswers'][this.id - 1]['toReview'] === 'true', 'button-style-toReview-default': completeData['userAnswers'][this.id - 1]['toReview'] !== 'true'}">
                Review Later
              </button>
              <button (click)="blindGuess()" class="button-style" style="font-weight: bold" [ngClass]="{'button-style-blindGuess': completeData['userAnswers'][this.id - 1]['blindGuess'] === 'true', 'button-style-blindGuess-default': completeData['userAnswers'][this.id - 1]['blindGuess'] !== 'true'}">
                Blind Guess
              </button>
              <button (click)="clearAnswer()" class="button-style" style="color: #3835E7;border-style: none;font-weight: bold">
                Clear Answer
              </button>
              <button class="button-style comment" style="float: right; border-style: none;font-weight: bold">
                <a (click)="leaveComment(question)"><img src="assets/exam/comment.svg" style="padding-right: 0.3rem;" />Comment on the question</a>
              </button>
            </div>
            <div style="margin-top: 3rem;width: 100%;">
              <div style="background: #2E2C97">
                <div class="row align-items-center">
                  <div class="col-md-3 col-lg-3 col-xs-12 col-sm-12 text-left">
                    <button (click)="setPrev()" class="button-style" style="border-style: none;background: transparent;color:white">
                      Prev <img src="assets/exam/prev.svg" style="padding-left: 1rem;"/>
                    </button>
                  </div>
                  <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12 text-center">
                    <button *ngIf="showPauseButton && isPracticeMode" (click)="showResume(); cd.pause();" class="button-style" style="border-style: none;background: #FFC01D;color: white;font-weight: 900;"><strong>Pause</strong></button>
                    <button *ngIf="showResumeButton && isPracticeMode" (click)="showPause(); cd.resume();" class="button-style" style="border-style: none;background: #FFC01D;color: white;font-weight: 900;"><strong>Resume</strong></button>
                    <button *ngIf="!isPracticeMode" class="button-style" style="border-style: none;background: lightgray;color: white;font-weight: 900;"><strong>Pause</strong></button>
                    <span>
                        <span style="color: white;font-weight: bold"> Time Remaining</span>
                        &nbsp;<countdown #cd style="color: #63ECFF" [config]="{leftTime: examDuration, notify: [30, 60, 1]}" (event)="clickone($event)"></countdown>
                    </span>
                    <button class="button-style" style="border-style: none;background: #10D548;color: white;font-weight: 900;" (click)="completeTest()" [disabled]="testSubmitted">
                      <span *ngIf="!testSubmitted"><strong>Complete Test</strong></span>
                      <img *ngIf="testSubmitted" src="assets/img/button_loading.gif"
                           class="img img-responsive button_loading" style="width: 30px;">
                    </button>
                  </div>
                  <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 text-right">
                    <button (click)="setNext()" class="button-style" style="border-style: none;background: transparent;color: white">
                      Next <img src="assets/exam/next.svg" style="padding-left: 1rem;"/>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>
