<app-navbar></app-navbar>

<div class="container-fluid">
<!--<div class="row px-3 px-lg-8">-->
<!--  <div class="col-12 col-lg-6 background-image d-flex justify-content-center align-items-center">-->
<!--    <div class="heading">Any Module-->
<!--      Same Features</div>-->
<!--  </div>-->
<!--  <div class="col-12 col-lg-6 right-content">-->
<!--    <div class="px-3 py-3 text">An online test platform for UPSC Prelims (General and CSAT), Banking PO, Banking SO, RRB, ESE and GATE providing a flexible test series powered by Artificial Intelligence to generate precise and detailed feedback through personalized test performance analytics which can be translated into actionable insights</div>-->
<!--    <hr class="top">-->
<!--    <div class="d-flex flex-column">-->
<!--      <div class="d-flex py-3" style="border-bottom: 1px solid gray;">-->
<!--        <img src="/assets/Vectormodule_1.svg">-->
<!--        <div class="px-3 text"> Detailed Value Proposition: Cost effective – The entire solution is priced very competitively compared to similar products in the market.</div>-->
<!--      </div>-->
<!--      <div class="d-flex py-3" style="border-bottom: 1px solid gray;">-->
<!--        <img src="/assets/Vectormodule_2.svg">-->
<!--        <div class="px-3 text"> Detailed Value Proposition: Cost effective – The entire solution is priced very competitively compared to similar products in the market.</div>-->
<!--      </div>-->
<!--      <div class="d-flex py-3" style="border-bottom: 1px solid gray;">-->
<!--        <img src="/assets/Groupmodule_3.svg">-->
<!--        <div class="px-3 text"> Detailed Value Proposition: Cost effective – The entire solution is priced very competitively compared to similar products in the market.</div>-->
<!--      </div>-->
<!--      <div class="d-flex py-3" style="border-bottom: 1px solid gray;">-->
<!--        <img src="/assets/Framemodule_4.svg">-->
<!--        <div class="px-3 text"> Detailed Value Proposition: Cost effective – The entire solution is priced very competitively compared to similar products in the market.</div>-->
<!--      </div>-->
<!--      <div class="d-flex py-3">-->
<!--        <img src="/assets/Vectormodule_5.svg">-->
<!--        <div class="px-3 text"> Detailed Value Proposition: Cost effective – The entire solution is priced very competitively compared to similar products in the market.</div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

  <div class="row px-3 px-lg-8 pt-5 no-gutters" style="color:  #2E2C97;">
    <div style="font-weight: 900;font-size: 20px;line-height: 30px;">Key features</div>
  <div class="col-12 d-flex flex-wrap justify-content-center py-3">
    <div class="wire-but weighted-font"> <img class="mr-3" src="/assets/Vectormodule_1.svg"> 4200+ UPSC standard questions </div>
    <div class="wire-but weighted-font"> <img class="mr-3" src="/assets/Vectormodule_2.svg"> Buy once, use it thrice </div>
    <div class="wire-but weighted-font"> <img class="mr-3" src="/assets/Framemodule_4.svg">  Valid for 365 days from date of purchase </div>
    <div class="wire-but weighted-font"> <img class="mr-3" src="/assets/Vectormodule_5.svg"> Multiple free tests to experience the features </div>
  </div>
  </div>

  <div *ngIf="hasPurchasedAtleastAModule === false" class="row px-3 px-lg-8 pt-5 no-gutters">
    <div class="col-12 d-flex justify-content-between align-items-center top-bar pl-2" style="background:peru;border-radius: 10px;">
      <div class="pl-3" style="font-weight: 600;font-size: 20px;">Integrated module for 2022 prelims (All 4200+ Questions)</div>
      <button (click)="buyIntegratedModule()" class="but-right">Buy All Modules <img style="height: 22px !important;" src="assets/img/rupee_white.png" /> {{integratedModulePrice}} & save  <img style="height: 22px !important;" src="assets/img/rupee_white.png" /> {{saving}} <span style="font-size: 12px"> (all inclusive) </span></button>
    </div>
  </div>

  <div class="row px-3 px-lg-8 pt-5 no-gutters" *ngFor="let data of modulesData">
    <div class="col-12 d-flex align-items-center top-bar pl-2" style="background:{{data.colourClass}};">
      <div class="pl-3  mr-auto" style="font-weight: 600;font-size: 15px;">{{data.label}}</div>
      <button *ngIf="data['hasPurchased'] === false" (click)="buyModule(data['id'], data)" class="but-right">Buy <img style="height: 22px !important;" src="assets/img/rupee_white.png" /> {{data.price}} <span style="font-size: 12px"> (all inclusive) </span></button>
      <button *ngIf="data['hasPurchased'] === true && !data['isFree']" disabled class="but-right">Take exams</button>
      <button *ngIf="data['isFree']" disabled class="but-right">Free Tests</button>
    </div>
    <div class="col-12 table-tabs">
      <mat-tab-group class="px-3" style="background: white; border-bottom-left-radius:10px; border-bottom-right-radius: 10px;">
        <mat-tab *ngFor="let item of data['subjects']">
          <ng-template mat-tab-label>
            <img height="25" src="assets/img/{{item.icon}}">
            <b class="pl-2">{{item.title}}</b>
          </ng-template>
<!--          <div *ngIf="data.label !== 'Free exams'" style="margin: 0.6rem;">-->
<!--            <a style="text-decoration: underline !important;color: #2E2C97">{{item.title}} Reference</a>-->
<!--          </div>-->
          <div *ngIf="item.hasOwnProperty('description') && item['description'] !== null && item['description'] !== undefined && item['description'] !== ''" style="margin: 0.6rem;color: gray;font-weight: bold;" [innerHTML]="item['description']">
          </div>
          <div class="wrap col my-3 py-3" *ngFor="let datum of item['subModules']">
<!--          <div class="class pl-2"> {{datum.label}} </div>-->
            <div *ngFor="let paper of datum['papers']" class="grid-container">
              <div class="item1 d-flex align-items-center pl-2" style="font-weight: bold;color: black;">
                <a>{{paper.title}}</a>
<!--                <a style="cursor: pointer;" *ngIf="data['hasPurchased'] === true" (click)="decideToCreateExamAttempt(paper)">{{paper.title}}</a>-->
              </div>
              <div class="item2 d-flex align-items-center pl-2" style="padding: 0.7rem;" [innerHTML]="paper['description']"></div>
              <div class="item3 d-flex align-items-center justify-content-center px-2" style="text-align: center"><div><b>{{paper.numQuestions}}</b><br> Questions</div></div>
              <div class="item4 d-flex flex-column justify-content-center align-items-center px-2">
                <img src="/assets/img/clock.svg">
                <div>{{paper['maxTimeMS']/3600000}}hr</div>
              </div>
              <div class="item5 d-flex align-items-center" *ngIf="paper['userPaper'] && paper['userPaper'] !== null && paper['userPaper'] !== undefined">
                <div style="background: white;" class="container-fluid">
                  <div class="row align-items-center" style="margin: 0.3rem;" *ngFor="let paperObject of paper['userPaper']; let i = index">
                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 text-left">
                      #{{i + 1}} Attempt
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 text-right" *ngIf="paperObject['isSubmitted'] === true || paperObject['isSubmitted'] === 'true'">
                      Total Score : {{paperObject['totalScore'].toFixed(2)}} / {{paper['maxScore']}}
                      <span>
                        <button [routerLink]="['/report']" [queryParams]="{id: paperObject['userAnswerSetId']}" class="attempt">
                          View Report
                        </button>
                      </span>
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 text-right" *ngIf="paperObject['isSubmitted'] !== true && paperObject['isSubmitted'] !== 'true'">
                      <button class="attempt" (click)="continueAttempt(paperObject)"> Continue this Attempt</button>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="(paper['canTakeNewExam'] === true || paper['canTakeNewExam'] === 'true') && paper['numQuestions'] > 0" class="item6 d-flex align-items-center">
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-right">
                  <button style="margin-right: 1.3rem;" class="attempt" (click)="createNewExamAttemptAndRedirect(paper['id'], data.isFree)"> Take a fresh attempt </button>
                </div>
              </div>
              <div *ngIf="paper['canTakeNewExam'] !== true && paper['canTakeNewExam'] !== 'true' && data['hasPurchased'] === true && isLoggedIn === true" class="item6 d-flex align-items-center">
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-right">
                  <button style="margin-right: 1.3rem;" class="attempt"> Maximum Attempts Limit Reached </button>
                </div>
              </div>
              <div *ngIf="paper['canTakeNewExam'] !== true && paper['canTakeNewExam'] !== 'true' && data['hasPurchased'] === true && isLoggedIn === false && paper['numQuestions'] > 0" class="item6 d-flex align-items-center">
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-right">
                  <button [routerLink]="['/login']" style="margin-right: 1.3rem;font-weight: bolder;font-size: 1.1rem;line-height: 1.6rem;" class="attempt"> Please login to take test </button>
                </div>
              </div>
<!--              <div *ngIf="data['hasPurchased'] === false" class="item6 d-flex align-items-center">-->
<!--                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-right">-->
<!--                  <button (click)="performModulePayment(data['id'])" style="margin-right: 1.3rem;" class="attempt"> Purchase to attempt test </button>-->
<!--                </div>-->
<!--              </div>-->
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>

  <!-- promo code modal -->
  <div class="backdrop" [ngStyle]="{'display':codedisplay}"></div>
  <div [ngStyle]="{'display':codedisplay}" class="modal" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header align-items-center" [ngStyle]="{'background': moduleBackground}">
          <h3 class="modal-title" id="exampleModalLongTitle" style="color: whitesmoke;">{{ moduleName }}</h3>
        </div>

        <div class="mx-3 mt-3" style="font-size: 14px;">Price : <span>&#8377;{{ moduleAmount }}</span></div>
        <div class="mx-3 mt-2" style="font-size: 14px;">Discount : <span style="color: tomato;">- &#8377;{{ moduleDiscount }}</span></div>
        <div class="mx-3 mt-2" style="font-size: 14px;"><strong>Total : <span>&#8377;{{ moduleTotalAmount }}</span></strong></div>

        <div class="mx-3 mt-4">
          <form>
            <div class="form-group"> <label>Have partner code?</label>
                <div class="input-group">
                  <input type="text" id="affiliatecode" class="form-control coupon" name="" placeholder="Partner code">
                  <span class="input-group-append">
                    <button (click) = "applyCoupan()" class="btn btn-secondary btn-apply coupon">Apply</button>
                  </span>
                </div>
                <div class="invalidpromo" [ngStyle]="{'visibility':display}">Please enter valid partner code</div>
            </div>
          </form>
        </div>

        <div class="d-flex justify-content-end m-3">
          <button type="button" class="btn btn-white" (click) = "closeModal()" style="color: #808080;" >Cancel</button>
          <button *ngIf="display == 'hidden'" type="button" class="btn btn-secondary ml-2" (click) = "continuePayment()" style="color: whitesmoke;">Proceed to pay</button>
          <button *ngIf="display == 'visible'" type="button" class="btn btn-light ml-2" (click) = "continuePayment()" style="color: gray;" disabled>Proceed to pay</button>
        </div>
      </div>
    </div>
  </div>

  <!-- integrated promo code modal -->
  <div class="backdrop" [ngStyle]="{'display':integratedcodedisplay}"></div>
  <div [ngStyle]="{'display': integratedcodedisplay}" class="modal" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header align-items-center" style="background:peru">
          <h3 class="modal-title" id="exampleModalLongTitle" style="color: whitesmoke;">Integrated module for 2022 prelims (All 4200+ Questions)</h3>
        </div>

        <div class="mx-3 mt-3" style="font-size: 14px;">Price : <span>&#8377;{{ integratedModuleAmount }}</span></div>
        <div class="mx-3 mt-2" style="font-size: 14px;">Discount : <span style="color: tomato;">- &#8377;{{ integratedModuleDiscount }}</span></div>
        <div class="mx-3 mt-2" style="font-size: 14px;"><strong>Total : <span>&#8377;{{ integratedModuleTotalAmount }}</span></strong></div>

        <div class="mx-3 mt-4">
          <form>
            <div class="form-group"> <label>Have partner code?</label>
                <div class="input-group">
                  <input type="text" id="intAffiliatecode" class="form-control coupon" name="" placeholder="Partner code">
                  <span class="input-group-append">
                    <button (click) = "applyIntegratedCoupan()" class="btn btn-secondary btn-apply coupon">Apply</button>
                  </span>
                </div>
                <div class="invalidpromo" [ngStyle]="{'visibility':display}">Please enter valid partner code</div>
            </div>
          </form>
        </div>

        <div class="d-flex justify-content-end m-3">
          <button type="button" class="btn btn-white" (click) = "closeModal()" style="color: #808080;" >Cancel</button>
          <button *ngIf="display == 'hidden'" type="button" class="btn btn-secondary ml-2" (click) = "continueIntegratedPayment()" style="color: whitesmoke;">Proceed to pay</button>
          <button *ngIf="display == 'visible'" type="button" class="btn btn-light ml-2" (click) = "continueIntegratedPayment()" style="color: gray;" disabled>Proceed to pay</button>
        </div>
      </div>
    </div>
  </div>

  <!-- invalid promo code modal -->
  <div class="backdrop" style="display:none"></div>
  <div style="display:none" class="modal" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title" id="exampleModalLongTitle">Invalid Code</h1>
        </div>
        <div class="modal-body">
          Please enter a valid code
        </div>
        <button type="button" class="btn btn-secondary m-3" (click) = "closeModal()">Close</button>
      </div>
    </div>
  </div>

  <div *ngIf="hasPurchasedAtleastAModule === false" class="row px-3 px-lg-8 pt-5 no-gutters" style="padding-bottom: 3rem;">
    <div class="col-12 d-flex justify-content-between align-items-center top-bar pl-2" style="background:peru;border-radius: 10px;">
      <div class="pl-3" style="font-weight: 600;font-size: 20px;">Integrated module for 2022 prelims (All 4200+ Questions)</div>
      <button (click)="buyIntegratedModule()" class="but-right">Buy All Modules <img style="height: 22px !important;" src="assets/img/rupee_white.png" /> {{integratedModulePrice}} & save  <img style="height: 22px !important;" src="assets/img/rupee_white.png" /> {{saving}} <span style="font-size: 12px"> (all inclusive) </span></button>
    </div>
  </div>
</div>


<app-footer></app-footer>
