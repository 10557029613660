<app-navbar></app-navbar>

<div class="container-fluid">

  <div class="row" style="height: 100%">

    <div class="col-lg-4 d-flex flex-column justify-content-center align-items-center" style="background-color: #3936c4;  color: white">
      <div class="ml-3">
        <div class="pb-3" style="font-size: 30px; font-weight: bold; line-height: 40px">Find your blind-spot </div>
        <div style="font-size: 17px; font-weight: normal;line-height: 24px"> Improve exam results | Know your weak points precisely </div>
      </div>
    </div>

    <div class="col-lg-8 d-flex background-image justify-content-center align-items-center">
      <div id="card" class="card">

        <div class="d-flex justify-content-center flex-column flex-sm-row mb-3"  style="">
          <img src="/assets/Line%2028line.svg" alt="">
          <span class="mx-3" style="font-size: small;font-weight: bold">Login using</span>
          <img src="/assets/Line%2028line.svg" alt="">
        </div>

        <div class="d-flex flex-wrap align-items-center" style="width: 100%; justify-content: center">
          <a style="cursor: pointer;" (click)="initiateSocialAuth('googleplus')"><img src="./assets/Rectangle%2069google.svg" alt=""></a>
          <a style="cursor: pointer;" (click)="initiateSocialAuth('facebook')"><img src="./assets/image%201fb.svg" alt=""></a>
          <!--        <a href=""><img src="./assets/image%202apple.svg" alt=""></a>-->
          <!--        <a href=""><img src="./assets/image%203yahoo.svg" alt=""></a>-->
          <!--        <a href=""><img src="./assets/image%204rediff.svg" alt=""></a>-->
          <!--        <a href=""><img src="./assets/image%205outlook.svg" alt=""></a>-->
          <!--        <a href=""><img src="./assets/image%206insta.svg" alt=""></a>-->
          <!--        <a href=""><img src="./assets/Rectangle%2072li.svg" alt=""></a>-->
        </div>
        <div> By logging in, you are agreeing to the <a [routerLink]="'/termsandconditions'" target="_blank">Terms of Service</a></div>
      </div>
    </div>

  </div>
</div>

<app-footer></app-footer>
