import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {environment} from '../../environments/environment';
import {MatSnackBar} from '@angular/material/snack-bar';
import {LinxService} from '../linx.service';
import { tree } from 'd3-hierarchy';

declare let $;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  dialogRef: MatDialogRef<any>;
  showPopup = 'none';
  temp = 0;
  heading: any;
  subheading: any;
  subheading1: any;
  subheading2: any;
  whatNewList: any;
  dataList: any;


  featuresData = [
    {
      heading : 'Data Analytics based',
      subheading : 'Data analytics will hold a mirror about your performance & guide you to revise selectively',
      hash : '#AI #Automated\n' +
        '#Machine LEarning',
      svg : './assets/img/feature1.png'
    },
    {
      heading : 'Buy once, Use Thrice for upto 365 days',
      subheading : 'Considering your time investment for several exams, you can take each test 3 times and upto 365 days',
      hash : '#AI #Automated\n' +
        '#Machine LEarning',
      svg : './assets/img/feature2.png'
    },
    {
      heading : 'Accurate Diagnosis of Blind Spots',
      subheading : 'Identifies and alerts you on your areas of false confidence',
      hash : '#AI #Automated\n' +
        '#Machine LEarning',
      svg : './assets/img/feature3.png'
    },
    {
      heading : 'Personalized and Accurate Performance Analysis',
      subheading : 'The feedback gives you precise improvement areas including exact areas of book or news or news article links as applicable',
      hash : '#AI #Automated\n' +
        '#Machine LEarning',
      svg : './assets/img/feature4.png'
    },
    {
      heading : 'Feedback on webpage and email',
      subheading : 'Get your personalized feedback mailed to you in a PDF format for easy offline reference in addition to viewing them online',
      hash : '#AI #Automated\n' +
        '#Machine LEarning',
      svg : './assets/img/feature5.png'
    },
    {
      heading : 'Strike-off options like a paper test',
      subheading : 'Strike off option to bring the online test closer to a paper based test',
      hash : '#AI #Automated\n' +
        '#Machine LEarning',
      svg : './assets/img/feature6.png'
    },
  ];

  bottomData = [
    {
      title: 'Free Tests',
      content: 'Experience all the features of our #SmartTestSeries by taking one free test each from all four modules',
      time: '10:30 AM',
      price: 'Free',
      ques: '190 Questions',
      image: './assets/img/freetestmodule.jpg'
    },
    {
      title: 'Integrated Module',
      content: 'Experience the complete suite of ExcelOn Academy\'s #SmartTestSeries at a discount for all your Prelims prep needs',
      time: '10:30 AM',
      price: '6000.00',
      ques: '5000 Questions',
      image: './assets/img/integratedmodule.jpg'
    },
    {
      title: 'Current Affairs and Prelims Simulator Full Length Tests',
      // content: '1500 high quality questions from Jan 2020 to April 2021 including Full Length Mock Tests',
      content: '1500 high quality current affairs questions including Monthly current affairs questions, Full Length Tests and dedicated tests with questions from Yojana, Budget and Economic Survey',
      time: '10:30 AM',
      price: '2500.00',
      ques: '1500 Questions',
      image: './assets/img/home-module3.svg'
    },
    {
      title: 'NCERT Test Series',
      content: '1000 high quality questions across all subjects chosen from relevant relevant 6th to 12th standard textbooks',
      time: '10:30 AM',
      price: '1,500.00',
      ques: '1000 Questions',
      image: './assets/img/home-module1.svg'
    },
    {
      title: 'Reference Books based Test Series',
      content: '1300 high quality questions chosen from standard reference books',
      time: '10:30 AM',
      price: '2,000.00',
      ques: '1300 Questions',
      image: './assets/img/home-module2.svg'
    },
    {
      title: 'CSAT',
      content: '400 high quality questions covering each sub-topic under Mathematics, Reading Comprehension, Logical Reasoning etc',
      time: '10:30 AM',
      price: '750.00',
      ques: '400 Questions',
      image: './assets/img/csat.jpg'
    }
  ];

  constructor(public dialog: MatDialog, public service: LinxService) {
    // localStorage.setItem('showCommentPopup', "show");
  }
  openCommentPopup() {
    if(localStorage.getItem('showCommentPopup') === 'show' || localStorage.getItem('showCommentPopup') === undefined || !localStorage.getItem('showCommentPopup')) {
      if(this.dialogRef == undefined) {
        this.dialogRef = this.dialog.open(CommentsVideo, {
          maxWidth: '150vw',
          minWidth: '60vw',
          maxHeight: '150vh',
          minHeight: '80vh'
        });
      }
    }

  }
  ngOnInit(): void {
    // this.openCommentPopup();
    this.heading = "India's 1st Data Driven Smart test series";
    this.subheading2 = 'UPSC Prelims 2022';
    this.subheading1 = 'Assess your preparation';
    this.subheading = 'Take our tests | Know your weak points precisely | Revise selectively | Clear UPSC prelims';
    this.dataList = [];
    this.getWhatsNew();
  }


  getWhatsNew() {
    this.service.getWhatNew().subscribe(response => {
      this.dataList = response;
      if(response && this.dataList.length !== 0) {
        this.whatNewList = this.dataList;
        this.showPopup = 'block';
      } else return;
    }, error => {
      return;
    });
  }

  whatNewPopupClose() {
    this.showPopup = 'none';
  }

  sendMessage() {
    const loaderDialog = this.dialog.open(SendMessageComponent, {
      maxWidth: '95vw',
      minWidth: '50vw',
      maxHeight: '60vh',
    });
  }

  onClickVideoTour() {
    $(".popup__overlay").css("display", "block");
    if(this.temp === 1) {
      let iframe = document.getElementsByTagName("iframe")[0].contentWindow;
      iframe.postMessage('{"event":"command","func":"' + 'playVideo' +   '","args":""}', '*');
    }
  }

  onClickVideoClose() {
    $(".popup__overlay").css("display", "none");
    let iframe = document.getElementsByTagName("iframe")[0].contentWindow;
    iframe.postMessage('{"event":"command","func":"' + 'pauseVideo' +   '","args":""}', '*');
    this.temp = 1;
  }
}

@Component({
  selector: 'app-dialog-content-comments',
  templateUrl: 'dialog-content-comments.html',
  styleUrls: ['dialog-content-comments.scss']
})

export class CommentsVideo implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<CommentsVideo>, public snackBar: MatSnackBar, public service: LinxService) {
    // dialogRef.disableClose = true;
  }
  ngOnInit(): void {
    localStorage.setItem('showCommentPopup', "hide");
    // this.removeItem();
  }
  closeCommentDialog(){
    this.dialogRef.close();
  }
  // removeItem() {
  //   setTimeout(() => {
  //     console.log("Removing..")
  //     localStorage.removeItem('showCommentPopup');
  //   }, 500000);
  // }

}
@Component({
  selector: 'app-sendmessage',
  templateUrl: 'send-message.component.html',
  styleUrls: ['send-message.component.css']
})
export class SendMessageComponent implements OnInit {

  email = '';
  message = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<SendMessageComponent>, public snackBar: MatSnackBar, public service: LinxService) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {

  }

  leaveMessage() {
    if (this.email !== '' && this.email !== null && this.email !== undefined && this.message !== '' && this.message !== null && this.message !== undefined) {
      const payload = {
        email: this.email,
        msg: this.message
      };
      this.service.leaveUsAMessage(payload).subscribe(response => {
        this.snackBar.open('Message Sent.', 'Success', {
          duration: 5000,
          verticalPosition: 'top',
          horizontalPosition: 'center'
        });
        this.close();
      }, error => {
        this.snackBar.open('Error while sending the message. Please try again sometime.', 'Error', {
          duration: 5000,
          verticalPosition: 'top',
          horizontalPosition: 'center'
        });
      });
    } else {
      this.snackBar.open('Please provide a message and an email ID.', 'Error', {
        duration: 5000,
        verticalPosition: 'top',
        horizontalPosition: 'center'
      });
    }
  }

  close() {
    this.dialogRef.close();
  }
}


