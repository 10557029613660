import {Component, Inject, OnInit} from '@angular/core';
import {LinxService} from '../linx.service';
import {ActivatedRoute, Router} from "@angular/router";
import {MatSnackBar} from '@angular/material/snack-bar';
import {LoaderWithProgressComponent} from '../common-component/common-component.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {environment} from '../../environments/environment';
import {SendMessageComponent} from "../home/home.component";
import {ConfirmSubmitExamComponent} from "../exam/exam.component";
import {local} from "d3-selection";
import { LoginComponent } from '../login/login.component';
import {ListenerService} from "../listener.service";

@Component({
  selector: 'app-modules-comp',
  templateUrl: './modules-comp.component.html',
  styleUrls: ['./modules-comp.component.scss']
})
export class ModulesCompComponent implements OnInit {

  constructor(public service: LinxService, public router: Router, public snackBar: MatSnackBar,
  private route: ActivatedRoute, public listenerService: ListenerService, public dialog: MatDialog) { }
  display='hidden';
  codedisplay='none';
  integratedcodedisplay='none';
  module_id : any;
  moduleName : any;
  moduleBackground : any;
  moduleAmount : any;
  moduleDiscount : any;
  moduleTotalAmount : any;
  integratedModuleAmount : any;
  integratedModuleDiscount : any;
  integratedModuleTotalAmount : any;
  affiliateCodeStr = '';
  data : any;

  NCERTDATA = [
    {
      tabName : 'Polity',
      tabIcon : '/assets/Vectorpolity.svg',
      tabContent : [
        {
          title: 'Class XII',
          text1: 'Take Exam 2',
          text1link : '',
          text2 : 'Lorem  simply dummy text of the printing and typesetting industry. Lorem  simply dummy text of the printing  industry.',
          questionNum: '90',
          time : '2 hr'
        },
        {
          title: 'Class XII',
          text1: 'Take Exam 2',
          text1link : '',
          text2 : 'Lorem  simply dummy text of the printing and typesetting industry. Lorem  simply dummy text of the printing  industry.',
          questionNum: '90',
          time : '2 hr'
        }
      ]
    },
    {
      tabName : 'Polity',
      tabIcon : '/assets/Vectorpolity.svg',
      tabContent : [
        {
          title: 'Class XII',
          text1: 'Take Exam 2',
          text1link : '',
          text2 : 'Lorem  simply dummy text of the printing and typesetting industry. Lorem  simply dummy text of the printing  industry.',
          questionNum: '90',
          time : '2 hr'
        }
      ]
    }
  ];

  // modulesData = [
  //   {
  //     title : 'Module – 1 – NCERT Test Series',
  //     price: '5000',
  //     bgcolor: '#2E2C97',
  //     content: this.NCERTDATA
  //   },
  //   {
  //     title : 'Module – 2 – Reference Books Test Series',
  //     price: '5000',
  //     bgcolor: '#9B119E',
  //     content: this.NCERTDATA
  //   },
  // ];

  modulesData: any;
  hasPurchasedAtleastAModule = false;
  isLoggedIn: boolean;
  integratedModulePrice = 0;
  saving = 0;
  user_id : any;
  access_token : any;


  ngOnInit(): void {
    this.route.queryParams.subscribe(async params1 => {
      if(params1 !== undefined && params1 !== null){
        if(params1.user_id !== null && params1.user_id !== undefined && params1.user_id !== '' &&
        params1.access_token !== null && params1.access_token !== undefined && params1.access_token !== ''){
          this.user_id = params1.user_id;
          this.access_token = params1.access_token;
          localStorage.setItem('bearerToken', 'Bearer ' + this.access_token);
        }
      }
    });
    if(this.user_id !== null && this.user_id !== undefined && this.access_token !== null && this.access_token !== undefined) {
      this.service.getPersonDetails(this.user_id).subscribe(response1 => {
        if (response1){
          localStorage.setItem('bearerToken', 'Bearer ' + this.access_token);
          localStorage.setItem('Auth', 'true');
          if (response1.hasOwnProperty('mobile') && response1['mobile'] && response1['mobile'] !== null && response1['mobile'] !== undefined && response1['mobile'] !== '') {
            //localStorage.setItem('bearerToken', 'Bearer ' + this.access_token);
            //localStorage.setItem('Auth', 'true');
            localStorage.setItem('personDetails', JSON.stringify(response1));
            this.listenerService.thirdPartySignIn.emit(true);
            this.router.navigateByUrl('/modules');
           } else {
            const dialogRef = this.dialog.open(GetMobileNumberComponentModule, {
              maxWidth: '65vw',
              minWidth: '50vw',
              maxHeight: '60vh',
            });

            dialogRef.afterClosed().subscribe(response2 => {
              if (response2 && response2 !== '') {
                this.service.updateMobileNumber(this.user_id, { mobile: response2}).subscribe(response3 => {
                  if (response3) {
                    //localStorage.setItem('bearerToken', 'Bearer ' + this.access_token);
                   // localStorage.setItem('Auth', 'true');
                    localStorage.setItem('personDetails', JSON.stringify(response1));
                    this.listenerService.thirdPartySignIn.emit(true);
                    this.router.navigateByUrl('/modules');
                  } else {
                    localStorage.removeItem('bearerToken');
                    localStorage.setItem('Auth', 'false');
                    this.router.navigateByUrl('/login');
                    this.snackBar.open('Error while storing the mobile number. Please try again after sometime.', 'Error', {
                      duration: 5000,
                      verticalPosition: 'top',
                      horizontalPosition: 'center'
                    });
                  }
                }, error => {
                  localStorage.removeItem('bearerToken');
                  localStorage.setItem('Auth', 'false');
                  this.router.navigateByUrl('/login');
                  this.snackBar.open('Error while storing the mobile number. Please try again after sometime.', 'Error', {
                    duration: 5000,
                    verticalPosition: 'top',
                    horizontalPosition: 'center'
                  });
                });
              } else {
                localStorage.removeItem('bearerToken');
                localStorage.setItem('Auth', 'false');
                this.router.navigateByUrl('/login');
              }
            });
          }

          if (localStorage.getItem('Auth')) {
            this.isLoggedIn = true;
            this.service.getExamsByModules().subscribe(response => {
              this.modulesData = response;
              if (this.modulesData && this.modulesData !== null && this.modulesData !== undefined) {
                for (const module of this.modulesData) {
                  if (module && module !== null && module !== undefined) {
                    if (!module.isFree) {
                      if (module.hasPurchased === true) {
                        this.hasPurchasedAtleastAModule = true;
                        break;
                      }
                    }
                  }
                }
              }
              this.getIntegratedModulePrice();
            });

          } else {
            this.isLoggedIn = false;
            this.service.getAnonymousExamsByModules().subscribe(response => {
              this.modulesData = response;
              this.integratedModulePrice = 6000;
              this.saving = 750;
              // this.saving = 3750;
            });
          }
        } else {
          localStorage.removeItem('bearerToken');
          localStorage.setItem('Auth', 'false');
          this.router.navigateByUrl('/login');
          this.snackBar.open('Error while authenticating. Please try again after sometime.', 'Error', {
            duration: 5000,
            verticalPosition: 'top',
            horizontalPosition: 'center'
          });
        }
      }, error => {
         localStorage.removeItem('bearerToken');
         localStorage.setItem('Auth', 'false');
         this.router.navigateByUrl('/login');
         this.snackBar.open('Error while retrieving the user details. Please try again after sometime.', 'Error', {
           duration: 5000,
           verticalPosition: 'top',
           horizontalPosition: 'center'
         });
      });
    } else {
      if (localStorage.getItem('Auth')) {
        this.isLoggedIn = true;
        this.service.getExamsByModules().subscribe(response => {
          this.modulesData = response;
          if (this.modulesData && this.modulesData !== null && this.modulesData !== undefined) {
            for (const module of this.modulesData) {
              if (module && module !== null && module !== undefined) {
                if (!module.isFree) {
                  if (module.hasPurchased === true) {
                    this.hasPurchasedAtleastAModule = true;
                    break;
                  }
                }
              }
            }
          }
		    this.getIntegratedModulePrice();
        });
      } else {
        this.isLoggedIn = false;
        this.service.getAnonymousExamsByModules().subscribe(response => {
          this.modulesData = response;
          this.integratedModulePrice = 6000;
          this.saving = 750;
          // this.saving = 3750;
        });
      }
    }
  }

  getIntegratedModulePrice() {
    this.service.integratedModulePrice().subscribe(response => {
      if (response && response.hasOwnProperty('integratedModulePrice') && response.hasOwnProperty('saving')) {
        this.integratedModulePrice = response['integratedModulePrice'];
        this.saving = response['saving'];
      }
    });
  }

  // decideToCreateExamAttempt(paper) {
  //   if (paper.userPaper) {
  //     if (paper.userPaper.length > 0) {
  //       for (const paperObject of paper.userPaper) {
  //         if (paperObject) {
  //           if (paperObject.hasOwnProperty('isSubmitted') && ( paperObject.isSubmitted === null || paperObject.isSubmitted === 'null' || paperObject.isSubmitted === 'false' || paperObject.isSubmitted === false)) {
  //             if (paperObject.hasOwnProperty('userAnswerSetId') && paperObject.userAnswerSetId !== null && paperObject.userAnswerSetId !== undefined) {
  //               this.router.navigate(['/exams'], { queryParams: {exam: paperObject.userAnswerSetId}});
  //             } else {
  //               this.createNewExamAttemptAndRedirect(paper.id);
  //             }
  //           } else {
  //             this.createNewExamAttemptAndRedirect(paper.id);
  //           }
  //         } else {
  //           // loaderDialog.close();
  //         }
  //       }
  //     } else {
  //       if (paper.canTakeNewExam === true) {
  //         this.createNewExamAttemptAndRedirect(paper.id);
  //       } else {
  //         this.snackBar.open('Limit on number of exam attempts exceeded.', 'Error', {
  //           duration: 9000,
  //           verticalPosition: 'top',
  //           horizontalPosition: 'center'
  //         });
  //         // loaderDialog.close();
  //       }
  //     }
  //   } else {
  //     if (paper.canTakeNewExam === true) {
  //       this.createNewExamAttemptAndRedirect(paper.id);
  //     } else {
  //       // loaderDialog.close();
  //       this.snackBar.open('Limit on number of exam attempts exceeded.', 'Error', {
  //         duration: 9000,
  //         verticalPosition: 'top',
  //         horizontalPosition: 'center'
  //       });
  //     }
  //   }
  // }

  createExamAttempt(id, mode) {
    this.service.createExamAttempt(id, mode).subscribe(response => {
      if (response) {
        localStorage.setItem('examMode', mode);
        this.router.navigate(['/exams'], { queryParams: {exam: response}});
      } else {
        this.snackBar.open('Error while creating an exam attempt or maximum attempts exceeded.', 'Error', {
          duration: 9000,
          verticalPosition: 'top',
          horizontalPosition: 'center'
        });
        // loaderDialog.close();
      }
    }, error => {
      this.snackBar.open('Error while creating an exam attempt or maximum attempts exceeded.', 'Error', {
        duration: 9000,
        verticalPosition: 'top',
        horizontalPosition: 'center'
      });
      // loaderDialog.close();
    });
  }

  continueAttempt(paperObject) {
    if (paperObject.hasOwnProperty('mode') && paperObject['mode'] !== '' && paperObject['mode'] !== null && paperObject['mode'] !== undefined) {
      localStorage.setItem('examMode', paperObject['mode']);
      this.router.navigate(['/exams'], { queryParams: {exam: paperObject['userAnswerSetId']}});
    } else {
      localStorage.setItem('examMode', 'practice');
      this.router.navigate(['/exams'], { queryParams: {exam: paperObject['userAnswerSetId']}});
    }
  }

  createNewExamAttemptAndRedirect(id, isFree) {
    if (isFree) {
      this.createExamAttempt(id, 'practice');
    } else {
      const dialogRef = this.dialog.open(SelectExamModeComponent, {
        maxWidth: '65vw',
        minWidth: '50vw',
        maxHeight: '60vh',
      });

      dialogRef.afterClosed().subscribe(response => {
        if (response && response !== '') {
          this.createExamAttempt(id, response);
        }
      });
    }
  }

  openPost(url, params) {
    var formElement = document.createElement("form");
    formElement.setAttribute("method", "post");
    formElement.setAttribute("action", url);
    formElement.setAttribute("target", "_parent");

    for (var param in params) {
        var hiddenField = document.createElement("input");
        hiddenField.setAttribute("name", param);
        hiddenField.setAttribute("value", params[param]);
        formElement.appendChild(hiddenField);
    }

    document.body.appendChild(formElement);
    formElement.submit();
  }

  buyModule(id, data) {
    this.data = data;
    this.codedisplay='block';
    this.module_id = id;
    this.moduleName = data.label;
    this.moduleBackground = data.colourClass;
    this.moduleAmount = data.price;
    this.moduleDiscount = 0;
    this.moduleTotalAmount = data.price;
  }

  buyIntegratedModule() {
    this.integratedcodedisplay='block';
    this.integratedModuleAmount = this.integratedModulePrice;
    this.integratedModuleDiscount = 0;
    this.integratedModuleTotalAmount = this.integratedModulePrice;
  }

  applyCoupan() {
    this.affiliateCodeStr = ((document.getElementById("affiliatecode") as HTMLInputElement).value);
    if(this.affiliateCodeStr == '') return;

    this.service.discountedModulePrice(this.module_id, this.affiliateCodeStr).subscribe(responseJson => {
      if (responseJson) {
        let response = [];
        response.push(responseJson);

        if(response[0].affiliateCodeStatus && response[0].affiliateCodeStatus == 'invalid') {
          this.display='visible';
          this.moduleAmount = this.data.price;
          this.moduleDiscount = 0;
          this.moduleTotalAmount = this.data.price;

          setTimeout(() => {
            this.display='hidden';
            this.affiliateCodeStr = '';
            (document.getElementById("affiliatecode") as HTMLInputElement).value = "";
          }, 3000);
        }
        else {
          this.moduleAmount = response[0].origAmount;
          this.moduleTotalAmount = response[0].newAmount;
          this.moduleDiscount = -1 * +(response[0].newAmount - response[0].origAmount);
        }
      }
    }, error => {
      this.snackBar.open('Error while making payment. Please try again after sometime.', 'Error', {
        duration: 9000,
        verticalPosition: 'top',
        horizontalPosition: 'center'
      });
    });
  }

  applyIntegratedCoupan() {
    this.affiliateCodeStr = ((document.getElementById("intAffiliatecode") as HTMLInputElement).value);
    if(this.affiliateCodeStr == '') return;

    this.service.discountedIntegratedModulePrice(this.affiliateCodeStr).subscribe(responseJson => {
      if (responseJson) {
        let response = [];
        response.push(responseJson);

        if(response[0].affiliateCodeStatus && response[0].affiliateCodeStatus == 'invalid') {
          this.display='visible';
          this.integratedModuleAmount = this.integratedModulePrice;
          this.integratedModuleDiscount = 0;
          this.integratedModuleTotalAmount = this.integratedModulePrice;

          setTimeout(() => {
            this.display='hidden';
            this.affiliateCodeStr = '';
            (document.getElementById("intAffiliatecode") as HTMLInputElement).value = "";
          }, 3000);
        }
        else {
          this.integratedModuleAmount = response[0].origAmount;
          this.integratedModuleTotalAmount = response[0].newAmount;
          this.integratedModuleDiscount = -1 * +(response[0].newAmount - response[0].origAmount);
        }
      }
    }, error => {
      this.snackBar.open('Error while making payment. Please try again after sometime.', 'Error', {
        duration: 9000,
        verticalPosition: 'top',
        horizontalPosition: 'center'
      });
    });
  }

  continuePayment() {
    this.performModulePayment(this.module_id, this.affiliateCodeStr);
    this.affiliateCodeStr = '';
    (document.getElementById("affiliatecode") as HTMLInputElement).value = "";
  }

  continueIntegratedPayment() {
    this.purchaseIntegratedModules(this.affiliateCodeStr);
    this.affiliateCodeStr = '';
    (document.getElementById("intAffiliatecode") as HTMLInputElement).value = "";
  }

  performModulePayment(id, affiliateCodeStr) {
    if (localStorage.getItem('Auth')) {
      const loaderDialog = this.dialog.open(LoaderWithProgressComponent, {
        maxWidth: '95vw',
        maxHeight: '60vh',
      });

      const payload = {
        moduleId: id,
        affiliateCodeStr: affiliateCodeStr,
        paymentRedirectUrl: environment.paymentRedirectUrl
      };

      this.service.performModulePayment(payload).subscribe(responseJson => {
        loaderDialog.close();
        if (responseJson) {
            var status = responseJson['affiliateCodeStatus'];
            if(status == 'invalid') {
              this.codedisplay='none';
              this.display='block';
              return;
            }

          //var  responseJson = JSON.parse(response);

          var surl  =  environment.linxHost + "/Linx/rest/api/examPaymentRequest/ext/payUCallBack/1";
          var furl = surl;

          var params = [];
          params["key"] = responseJson['key'];
          params["txnid"] = responseJson['txnid'];
          params["amount"] = responseJson['amount'];
          params["productinfo"] = responseJson['productinfo'];

          params["firstname"] = responseJson['firstname'];
          params["email"] = responseJson['email'];
          params["phone"] = responseJson['phone'];
          params["surl"] = surl;
          params["furl"] = furl;
          params["hash"] = responseJson['hash'];
          params["service_provider"] = '';


          //Redirect to PayU payment screen
          this.openPost(environment.paymentPostURL, params);

          localStorage.setItem('paymentID', responseJson['id']);
        }
      }, error => {
        this.snackBar.open('Error while making payment. Please try again after sometime.', 'Error', {
          duration: 9000,
          verticalPosition: 'top',
          horizontalPosition: 'center'
        });
        loaderDialog.close();
      });

      // this.service.performModulePayment(payload).subscribe(response => {
      //   loaderDialog.close();
      //   if (response) {
      //     location.href = response['paymentRedirectUrl'];
      //     localStorage.setItem('paymentID', response['id']);
      //   }
      // }, error => {
      //   this.snackBar.open('Error while making payment. Please try again after sometime.', 'Error', {
      //     duration: 9000,
      //     verticalPosition: 'top',
      //     horizontalPosition: 'center'
      //   });
      //   loaderDialog.close();
      // });


    } else {
      this.router.navigateByUrl('/login');
    }
  }

  purchaseIntegratedModules(affiliateCodeStr) {
    if (localStorage.getItem('Auth')) {
      const loaderDialog = this.dialog.open(LoaderWithProgressComponent, {
        maxWidth: '95vw',
        maxHeight: '60vh',
      });
      const payload = {
        affiliateCodeStr: affiliateCodeStr,
        paymentRedirectUrl: environment.paymentRedirectUrl
      };
      this.service.purchaseIntegratedModules(payload).subscribe(responseJson => {
        loaderDialog.close();
        if (responseJson) {

          var status = responseJson['affiliateCodeStatus'];
          if(status == 'invalid') {
            this.integratedcodedisplay='none';
            this.display='block';
            return;
          }

          //var  responseJson = JSON.parse(response);

          var surl  =  environment.linxHost + '/Linx/rest/api/examPaymentRequest/ext/payUCallBack/1';
          var furl = surl;

          var params = [];
          params["key"] = responseJson['key'];
          params["txnid"] = responseJson['txnid'];
          params["amount"] = responseJson['amount'];
          params["productinfo"] = responseJson['productinfo'];

          params["firstname"] = responseJson['firstname'];
          params["email"] = responseJson['email'];
          params["phone"] = responseJson['phone'];
          params["surl"] = surl;
          params["furl"] = furl;
          params["hash"] = responseJson['hash'];
          params["service_provider"] = '';

          //Redirect to PayU payment screen
          this.openPost(environment.paymentPostURL, params);

          //location.href = response['paymentRedirectUrl'];
          localStorage.setItem('paymentID', responseJson['id']);
        }
      }, error => {
        this.snackBar.open('Error while making payment. Please try again after sometime.', 'Error', {
          duration: 9000,
          verticalPosition: 'top',
          horizontalPosition: 'center'
        });
        loaderDialog.close();
      });
    } else {
      this.router.navigateByUrl('/login');
    }
  }

  closeModal() {
    this.display='hidden';
    this.codedisplay='none';
    this.integratedcodedisplay='none';
    this.module_id = undefined;
    this.affiliateCodeStr = '';
    (document.getElementById("affiliatecode") as HTMLInputElement).value = "";
    (document.getElementById("intAffiliatecode") as HTMLInputElement).value = "";
  }
}

@Component({
  selector: 'app-selectmode',
  templateUrl: '../modules-comp/select-mode.component.html',
  styleUrls: ['../modules-comp/select-mode.component.css']
})
export class SelectExamModeComponent implements OnInit {

  comment = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<SelectExamModeComponent>, public router: Router, public snackBar: MatSnackBar, public service: LinxService) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {

  }

  setMode(mode) {
    this.dialogRef.close(mode);
  }

  close() {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-getmobilenumber',
  templateUrl: 'get-mobile-number.component.html',
  styleUrls: ['get-mobile-number.component.css']
})
export class GetMobileNumberComponentModule implements OnInit {

  mobileNumber = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<GetMobileNumberComponentModule>, public router: Router, public snackBar: MatSnackBar, public service: LinxService) {
    dialogRef.disableClose = true;
  }

  isValidMobileNumber(num) {
    return /^\s*\+?\s*([0-9][\s-]*){9,}$/.test(num);

  }

  ngOnInit() {

  }

  omit_special_char(event) {
    let k;
    k = event.charCode;
    return((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || (k >= 48 && k <= 57));
  }

  saveMobileNumber() {
    if (this.mobileNumber && this.mobileNumber !== '') {
      if (this.isValidMobileNumber(this.mobileNumber)) {
        this.dialogRef.close(this.mobileNumber);
      } else {
        this.snackBar.open('Please provide a valid mobile number to continue.', 'Error', {
          duration: 5000,
          verticalPosition: 'top',
          horizontalPosition: 'center'
        });
      }
    } else {
      this.snackBar.open('Please provide your mobile number to continue.', 'Error', {
        duration: 5000,
        verticalPosition: 'top',
        horizontalPosition: 'center'
      });
    }
  }

  close() {
    this.dialogRef.close();
  }
}
