<div class="row" *ngIf="drillDown === true && Data !== undefined">
        <div style="display: block" class="col-lg-8 col-md-8">
                <canvas baseChart
                        [datasets]="Data"
                        [labels]="labels"
                        [options]="Options"
                        [chartType]="Type"
                        (chartClick)="click($event)">
                </canvas>
        </div>
        <div class="col-lg-4 col-md-4" style="padding:60px" *ngIf="drillData">
                <mat-card style="background-color: #fafafa">
                        <div *ngFor="let key of keys">
                                <p style="font-family: sans-serif"><strong>{{key}}: </strong>{{drillData[key]}}</p>
                        </div>
                </mat-card>
        </div>
</div>
<!--<div class="row" *ngIf="chartType === 'bar'">-->
<!--        <div style="display: block" class="col-lg-12 col-md-12">-->
<!--                <canvas baseChart-->
<!--                        [datasets]="[{data: [1,2,3], label: null}]"-->
<!--                        [labels]="labels"-->
<!--                        [options]="Options"-->
<!--                        [chartType]="chartType"-->
<!--                        (chartClick)="click($event)">-->
<!--                </canvas>-->
<!--        </div>-->
<!--</div>-->

<div class="row" *ngIf="drillDown === false && Data !== undefined">
        <div style="display: block" class="col-lg-12 col-md-12">
                <canvas baseChart
                        [datasets]="Data"
                        [labels]="labels"
                        [options]="Options"
                        [chartType]="Type"
                        (chartClick)="click($event)">
                </canvas>
        </div>
</div>


