import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExamComponent } from './exam.component';
import { QuesComponent, SafeHtmlPipe } from './ques/ques.component';
import { AnsComponent } from './ans/ans.component';
import {MatCardModule} from '@angular/material/card';
import {MatRadioModule} from '@angular/material/radio';
import {FormsModule} from '@angular/forms';
import { ListComponent } from './list/list.component';
import {HttpClientModule} from '@angular/common/http';
import {HttpClient} from '@angular/common/http';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {RouterModule} from '@angular/router';
import {timer} from 'rxjs';
import {CountdownModule} from 'ngx-countdown';
import {JwPaginationModule} from 'jw-angular-pagination';
import { NavbarModule } from '../navbar/navbar.module';

@NgModule({
  declarations: [ExamComponent, QuesComponent, AnsComponent, ListComponent, SafeHtmlPipe],
  exports: [
    ExamComponent,
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatRadioModule,
    FormsModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    RouterModule,
    CountdownModule,
    JwPaginationModule,
    NavbarModule,
  ],
  providers: [HttpClientModule, HttpClient]
})
export class ExamModule { }

