<div class="container-fluid" style="max-width: 1920px;">
  <nav class="navbar navbar-expand-lg navbar-light ">
    <div class="navbar-brand align-items-center">
      <div class="d-flex align-items-center flex-lg-nowrap flex-wrap">
        <div class="ml-2">
          <a id="excelon" class="navbar-brand company-name mt-3" routerLink="/"><span class="fl-p">Un<span class="sl-p">learn</span></span></a>
        </div>
      </div>
    </div>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <div class="form-inline my-2 my-lg-0 ml-auto">
        <ul class="navbar-nav">
          <li class="nav-item ml-4">
            <div class="contact nav-link">
<!--              <img height="25" src="/assets/img/phone.jpg" alt="">-->
<!--              <span style="padding-left: 0.5rem; padding-right: 0.5rem;">{{contactNumber}}</span>-->
              <a href="mailto:saldin@apiplatform.io" style="color: #212121;"><img height="25" src="/assets/img/mail.png" alt=""></a>
            </div>
          </li>
          <li class="nav-item ml-4">
            <a [routerLink]="['/mains']" id="mains" class="nav-link">Mains</a>
          </li>
          <li *ngIf="!loggedIn" class="nav-item ml-4 d-flex">
            <img class="profile-image" src="./assets/Vector.svg" alt=""/>
            <a id="login" class="nav-link" routerLink="/login">Login</a>
          </li>
          <li *ngIf="loggedIn" class="nav-item ml-4">
            <div class="d-flex">
              <img class="profile-image" src="{{profileImage}}" alt=""/>
              <span>
                <a id="user" class="nav-link" (click)="showUserDropdown()">{{userName}}
                <span class="mdi mdi-chevron-down"></span></a>
              </span>
              <ul id ='userdropdowns' class="py-2 px-3 mt-5 ml-5 shadow rounded bg-white border" [hidden]="!userVisibility">
                <li><a class="nav-link" [routerLink] = "['/dashboard']">Dashboard</a></li>
                <li><a class="nav-link" [routerLink] = "['/modules']" >Our Offerings</a></li>
                <li><a class="nav-link" (click)="logout()">Logout</a></li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</div>
