<mat-card class="browser-mat-card">
    <div style="width: 100%;">
        <div class="row exam-title">
        <span>
          <p class="exam-title-text"><strong>{{paper['title']}}</strong></p>
        </span>
        </div>
    </div><br>
    <mat-card-content>
      <div class="row">
        <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6">
          <h4>{{original.length}} Questions </h4>
        </div>
        <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6 text-right">
          <select class="form-control" style="height: 2rem;font-size:0.7rem;" id="xLabel" [(ngModel)]="selectedFilterType">
            <option *ngFor="let type of filterTypes" [value]="type.value">
              {{type['name']}}
            </option>
          </select>
        </div>
      </div>

      <div>
        <ul class="status-style">
          <li><img src="assets/exam/answered.svg"/></li>
          <li><img src="assets/exam/toreview.svg"/></li>
          <li><img src="assets/exam/blindguess.svg"/></li>
          <li><img src="assets/exam/notattempted.svg"/></li>
        </ul>
      </div>

      <div style="height: 100vh;overflow-y: scroll;" *ngIf="original">
        <div *ngIf="selectedFilterType === 'all'">
          <ul class="questionList">
            <li class="question" *ngFor="let item of list; let i = index" [ngClass]="{'not-attempted': original[i] && (original[i]['answered'] === 'false'), 'answered':  original[i] && (original[i]['answered'] === 'true'), 'to-review':  original[i] && (original[i]['toReview'] === 'true'), 'blind-guess':  original[i] && (original[i]['blindGuess'] === 'true') }">
              <a class="question-number" [routerLink]="['/exams']" [queryParams]="{exam: examId, id : item.id}"><p class = "para1 circle">{{i + 1}}</a>
            </li>
          </ul>
        </div>
        <div *ngIf="selectedFilterType === 'notAttempted'">
          <ul class="questionList">
            <div *ngFor="let item of list; let i = index">
              <li class="question not-attempted" *ngIf="original[i] && (original[i]['answered'] === 'false')">
                <a class="question-number" [routerLink]="['/exams']" [queryParams]="{exam: examId, id : item.id}"><p class = "para1 circle">{{i + 1}}</a>
              </li>
            </div>
          </ul>
        </div>
        <div *ngIf="selectedFilterType === 'answered'">
          <ul class="questionList">
            <div *ngFor="let item of list; let i = index">
              <li class="question answered" *ngIf="original[i] && (original[i]['answered'] === 'true')">
                <a class="question-number" [routerLink]="['/exams']" [queryParams]="{exam: examId, id : item.id}"><p class = "para1  circle">{{i + 1}}</a>
              </li>
            </div>
          </ul>
        </div>
        <div *ngIf="selectedFilterType === 'toReview'">
          <ul class="questionList">
            <div *ngFor="let item of list; let i = index">
              <li class="question to-review" *ngIf="original[i] && (original[i]['toReview'] === 'true')">
                <a class="question-number" [routerLink]="['/exams']" [queryParams]="{exam: examId, id : item.id}"><p class = "para1 circle">{{i + 1}}</a>
              </li>
            </div>
          </ul>
        </div>
        <div *ngIf="selectedFilterType === 'blindGuess'">
          <ul class="questionList">
            <div *ngFor="let item of list; let i = index">
              <li class="question blind-guess" *ngIf="original[i] && (original[i]['blindGuess'] === 'true')">
                <a class="question-number" [routerLink]="['/exams']" [queryParams]="{exam: examId, id : item.id}"><p class = "para1 circle">{{i + 1}}</a>
              </li>
            </div>
          </ul>
        </div>
      </div>
    </mat-card-content>
</mat-card>
