<div class="row">
  <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-center">
    <h3 style="font-weight: bolder;"> Do you want to complete and submit the test? This will generate your performance report.
      This particular test attempt cannot be repeated. You may close the browser if you wish to continue this test at a later time. Do you still
      want to submit?
    </h3>
  </div>
</div>
<hr>
<div class="row">
  <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-right">
    <button class="attempt" style="margin: 0.5rem;" (click)="completeTest();">
      <span *ngIf="!testSubmittedConfirm"><strong>Submit</strong></span>
      <span *ngIf="testSubmittedConfirm" ><strong></strong> 
        <img src="assets/img/button_loading.gif" class="img img-responsive button_loading" style="width: 30px;">
      </span>
    </button>
    <button class="attempt" style="margin: 0.5rem;" (click)="close();"> Return to test </button>
  </div>
</div>

