<div class="row">
  <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-center">
    <div style="height: 85vh;
                          display: flex;
                          align-items: center;
                          margin-left: 48%;">
      <div>
        <mat-spinner></mat-spinner>
      </div>
    </div>
  </div>
</div>
