import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import {LinxService} from '../linx.service';
import {environment} from '../../environments/environment';
import {Router} from '@angular/router';


export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  redirectURL: any;

  constructor(public service: LinxService, public router: Router) { }

  hide = true;

  emailFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.email,
  ]);

  matcher = new MyErrorStateMatcher();

  ngOnInit(): void {
    // document.getElementById('container').scrollIntoView({ behavior: 'smooth', block: 'start' });
    if (!(localStorage.getItem('Auth'))) {
      const urlSplitObject = window.location.href.split('/');
      this.redirectURL = urlSplitObject[0] + '//' + urlSplitObject[2];
    } else {
      this.router.navigateByUrl('/dashboard');
    }
  }

  initiateSocialAuth(provider) {
    this.service.initiateSocialAuth(provider, environment.redirectURL).subscribe(response => {
      localStorage.setItem('idToFind', response['idToFind']);
      localStorage.setItem('provider', provider);
      window.location.href = response['authenticationURL'];
    });
  }

}
