<div class="comments pt-4">
    <div class="pd-4">
        <h1 class="heading">Aswathy IPS talks about ExcelOn Academy<span class="float-right"><button class="close" mat-button (click)="closeCommentDialog()">X</button></span></h1>
    </div>
    <div class="content">
        <div class="row justify-content-center">
            <div class="text-center col-md-12 col-lg-12 col-sm-12 col-xs-12 video">
                <video [autoplay]=true muted loop class="commentVideo" controls>
                    <source src="../../../../assets/exam/video/Aswathy IPS (All India Rank 41) talks on a unique data driven test series.mp4" type="video/mp4" />
                    Browser not supported
                </video>
            </div>
        </div>
    </div>
</div>





