<app-navbar></app-navbar>

<div class="container-fluid">
  <div
    class="row no-gutters justify-content-center"
    style="background: aliceblue"
  >
    <div class="col-12">
      <div
        class="col-12 pt-3 d-flex flex-column flex-md-row justify-content-center justify-content-md-around"
        style="background: #3936c4"
      >
        <div class="order-1 order-md-0 align-self-center align-self-md-auto">
          <button
            [ngClass]="
              tab === 'dashboard' ? 'tab-but-active' : 'tab-but-inactive'
            "
            (click)="changeTab('dashboard')"
          >
            Dashboard
          </button>
          <button
            [ngClass]="tab === 'mytest' ? 'tab-but-active' : 'tab-but-inactive'"
            (click)="changeTab('mytest')"
          >
            My test
          </button>
          <button
            [ngClass]="tab === 'subs' ? 'tab-but-active' : 'tab-but-inactive'"
            (click)="changeTab('subs')"
          >
            Subscription
          </button>
        </div>


        <div class="order-0 order-md-1 py-2 py-md-0 align-self-center">
          <a href="" style="color: white" class="mr-3"
            >Previous Question Papers</a
          >
          <a href="" style="color: white"> Daily Practice page</a>
        </div>
      </div>
    </div>

    <div         [hidden]="tab!=='dashboard'"
>

    <div
        class="d-flex flex-wrap justify-content-center align-items-center py-4"
      >
        <div
          class="d-flex px-1 py-1 mx-4 my-3"
          style="background: white; border-radius: 10px"
          *ngFor="let item of resultData"
        >
          <img src="{{ item.logo }}" class="background py-3 px-2" />
          <div
            class="d-flex flex-column justify-content-center align-items-center px-2 py-2"
          >
            <div class="pb-2" style="font-size: smaller">{{ item.title }}</div>
            <div style="font-weight: 600; font-size: 23px">
              {{ item.value }}
            </div>
          </div>
        </div>
      </div>

      <div
        class="py-3 px-lg-8 row justify-content-center no-gutters"
        style="background: aliceblue"
      >
        <div class="box-1 row no-gutters mb-5">
          <div
            class="col-lg-6 pl-3 py-5 d-flex justify-content-end"
            style="background: aliceblue"
          >
            <div
              class="col-12 d-flex flex-column py-3 px-3"
              style="
                background: #33187e;
                color: white;
                border-bottom-left-radius: 10px;
                border-top-left-radius: 10px;
              "
            >
              <button
                class="my-2 py-2"
                style="
                  background: #7254f2;
                  border-radius: 60px;
                  color: white;
                  width: 50%;
                  border: none;
                "
              >
                Integrated course
              </button>
              <div class="mb-2">
                Lorem ipsum, or lipsum as it is sometimes known, is dummy text
                used in laying out print, graphic or web designs.
              </div>
              <div class="mb-2">overall</div>
              <p class="mb-2">
                <ngb-progressbar
                  [showValue]="true"
                  type="success"
                  [value]="25"
                ></ngb-progressbar>
              </p>
              <div class="mb-2">Lorem ipsum, or lipsum as it is sometimes</div>
            </div>
          </div>

          <div
            class="col-lg-6 py-3 px-2 d-flex flex-column justify-content-center"
            style="
              background: white;
              box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
              border-radius: 10px;
            "
          >
            <div class="grid-container py-2 px-2 mb-2">
              <div class="item1">Free Tests</div>
              <div class="item2">
                <img src="/assets/Vectortest.svg" />&nbsp;10 Tests&nbsp;
                <img src="/assets/Vectortick.svg" />&nbsp;1 completed
              </div>
              <div
                class="item3 d-flex flex-column justify-content-center align-items-center"
              >
                <progress value="32" max="100"></progress>
                <div style="font-weight: bold">32%</div>
              </div>
            </div>

            <div class="grid-container py-2 px-2 mb-2">
              <div class="item1">Free Tests</div>
              <div class="item2">
                <img src="/assets/Vectortest.svg" />&nbsp;10 Tests&nbsp;
                <img src="/assets/Vectortick.svg" />&nbsp;1 completed
              </div>
              <div
                class="item3 d-flex flex-column justify-content-center align-items-center"
              >
                <progress value="32" max="100"></progress>
                <div style="font-weight: bold">32%</div>
              </div>
            </div>

            <div class="grid-container py-2 px-2 mb-2">
              <div class="item1">Free Tests</div>
              <div class="item2">
                <img src="/assets/Vectortest.svg" />&nbsp;10 Tests&nbsp;
                <img src="/assets/Vectortick.svg" />&nbsp;1 completed
              </div>
              <div
                class="item3 d-flex flex-column justify-content-center align-items-center"
              >
                <progress value="32" max="100"></progress>
                <div style="font-weight: bold">32%</div>
              </div>
            </div>

            <div class="grid-container pt-2 px-2 mb-2">
              <div class="item1">Free Tests</div>
              <div class="item2">
                <img src="/assets/Vectortest.svg" />&nbsp;10 Tests&nbsp;
                <img src="/assets/Vectortick.svg" />&nbsp;1 completed
              </div>
              <div
                class="item3 d-flex flex-column justify-content-center align-items-center"
              >
                <progress value="32" max="100"></progress>
                <div style="font-weight: bold">32%</div>
              </div>
            </div>
          </div>
        </div>

        <div class="box-2 mb-5" style="background: white; border-radius: 10px">
          <div class="pl-3 black-bar">Recent Activity</div>

          <div class="d-flex flex-column">
            <div class="py-2 px-2"><b>Test Title</b></div>
            <div class="grid-container-2 py-2 px-2">
              <div
                class="item1-2 d-flex justify-content-between align-items-center"
                style="font-size: small"
              >
                <div>24 Correct</div>
                <div>24 Correct</div>
                <div>24 Correct</div>
              </div>
              <div
                class="item2-2 d-flex justify-content-between align-items-center"
                style="font-size: x-small"
              >
                <div>53:18 Time taken</div>
                <div>53:18 Time taken</div>
                <div>53:18 Time taken</div>
              </div>
              <div class="item3-2">
                <div
                  class="vl d-flex flex-column justify-content-center align-items-center py-2 px-2"
                  style="
                    background: rgba(250, 162, 142, 0.2);
                    border-radius: 10px;
                  "
                >
                  <div><b>Total score</b></div>
                  <div
                    style="
                      font-size: larger;
                      font-weight: bold;
                      color: rgb(250, 162, 142);
                    "
                  >
                    61.3/100
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex flex-column">
            <div class="py-2 px-2"><b>Test Title</b></div>
            <div class="grid-container-2 py-2 px-2">
              <div
                class="item1-2 d-flex justify-content-between align-items-center"
                style="font-size: small"
              >
                <div>24 Correct</div>
                <div>24 Correct</div>
                <div>24 Correct</div>
              </div>
              <div
                class="item2-2 d-flex justify-content-between align-items-center"
                style="font-size: x-small"
              >
                <div>53:18 Time taken</div>
                <div>53:18 Time taken</div>
                <div>53:18 Time taken</div>
              </div>
              <div class="item3-2">
                <div
                  class="vl d-flex flex-column justify-content-center align-items-center py-2 px-2"
                  style="
                    background: rgba(250, 162, 142, 0.2);
                    border-radius: 10px;
                  "
                >
                  <div><b>Total score</b></div>
                  <div
                    style="
                      font-size: larger;
                      font-weight: bold;
                      color: rgb(250, 162, 142);
                    "
                  >
                    61.3/100
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="my-3"
          style="background: white; width: 92%; border-radius: 10px"
        >
          <div class="pl-3 black-bar">Performance Summary</div>
          <div class="row align-items-center">
            <div class="col-lg-6" style="display: block">
              <figure id="bar"></figure>
            </div>

            <div class="col-lg-6 table-responsive">
              <table class="table table-striped table-sm">
                <thead>
                  <tr>
                    <th scope="col">Subject</th>
                    <th scope="col">%</th>
                    <th scope="col">Average Score</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Economy</th>
                    <td>80</td>
                    <td>56</td>
                    <td><a href="">view</a></td>
                  </tr>
                  <tr>
                    <th scope="row">Environment</th>
                    <td>78</td>
                    <td>78</td>
                    <td><a href="">view</a></td>
                  </tr>
                  <tr>
                    <th scope="row">History</th>
                    <td>89</td>
                    <td>79</td>
                    <td><a href="">view</a></td>
                  </tr>
                  <tr>
                    <th scope="row">Civics</th>
                    <td>89</td>
                    <td>79</td>
                    <td><a href="">view</a></td>
                  </tr>
                  <tr>
                    <th scope="row">Geography</th>
                    <td>89</td>
                    <td>79</td>
                    <td><a href="">view</a></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
      <div
        [hidden]="tab!=='mytest'"
        class="row px-3 px-lg-8 pt-5 no-gutters"
        *ngFor="let data of modulesData"
        style="background: aliceblue"
      >
        <div
          class="col-12 d-flex justify-content-between align-items-center top-bar pl-2"
          style="background: {{ data.bgcolor }};"
        >
          <div class="pl-3" style="font-weight: 600; font-size: 20px">
            {{ data.title }}
          </div>
        </div>

        <div class="col-12">
          <mat-tab-group
            class="px-3"
            style="
              background: white;
              border-bottom-left-radius: 10px;
              border-bottom-right-radius: 10px;
            "
          >
            <mat-tab *ngFor="let item of data.content">
              <ng-template mat-tab-label>
                <img src="{{ item.tabIcon }}" />
                <b class="pl-2">{{ item.tabName }}</b>
              </ng-template>

              <div
                class="wrap col my-3 py-3"
                *ngFor="let datum of item.tabContent"
              >
                <div class="class pl-2">{{ datum.title }}</div>

                <div class="grid-container">
                  <div
                    class="item1 d-flex align-items-center pl-2"
                    style="font-weight: bold; color: #4976f2"
                  >
                    <a href="{{ datum.text1link }}">{{ datum.text1 }}</a>
                  </div>
                  <div class="item2 d-flex align-items-center pl-2">
                    {{ datum.text2 }}
                  </div>
                  <div
                    class="item3 d-flex align-items-center justify-content-center px-2"
                    style="text-align: center"
                  >
                    <div>
                      <b>{{ datum.questionNum }}</b
                      ><br />
                      Questions
                    </div>
                  </div>
                  <div
                    class="item4 d-flex flex-column justify-content-center align-items-center px-2"
                  >
                    <img src="/assets/Frameclock.svg" />
                    <div>{{ datum.time }}</div>
                  </div>
                  <div
                    class="item5 d-flex justify-content-between align-items-center"
                  >
                    <button class="prac-but">Practise</button>
                  </div>
                  <div
                    class="item6 d-flex justify-content-between align-items-center"
                  >
                    <button class="take-but">Take Test</button>
                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>

