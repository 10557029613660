import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LinxService} from '../linx.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {local} from "d3-selection";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-social-login',
  templateUrl: './social-login.component.html',
  styleUrls: ['./social-login.component.scss']
})
export class SocialLoginComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, private service: LinxService, private snackBar: MatSnackBar, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params && params.code && params.code !== null && params.code !== undefined) {
        let queryParams = '';
        switch (localStorage.getItem('provider')) {
          case 'facebook':
            queryParams = 'idToFind=' + localStorage.getItem('idToFind') + '&code=' + params.code;
            this.getJWTToken(queryParams);
            break;
          case 'googleplus':
            if (params.state && params.scope && params.authuser && params.prompt) {
              queryParams = 'idToFind=' + localStorage.getItem('idToFind') + '&code=' + params.code + '&state=' + params.state + '&scope=' + params.scope + '&authuser=' + params.authuser + '&prompt=' + params.prompt;
              this.getJWTToken(queryParams);
            } else {
              this.snackBar.open('Error while authenticating. Please try again after sometime.', 'Error', {
                duration: 5000,
                verticalPosition: 'top',
                horizontalPosition: 'center'
              });
              this.router.navigateByUrl('/login');
            }
            break;
        }
      } else {
        this.snackBar.open('Error while authenticating. Please try again after sometime.', 'Error', {
          duration: 5000,
          verticalPosition: 'top',
          horizontalPosition: 'center'
        });
        this.router.navigateByUrl('/login');
      }
    });
  }

  getJWTToken(queryParams) {
    this.service.getJWTTokenForFB(queryParams).subscribe(response => {
      if (response && response.status === 200 && response.body) {
        if (response['body'].hasOwnProperty('jwt') && response['body']['jwt'] !== null && response['body']['jwt'] !== undefined && response['body']['jwt'] !== '') {
          localStorage.setItem('bearerToken', 'Bearer ' + response.body['jwt']);
          this.service.getPersonDetails(response.body['userId']).subscribe(response1 => {
            if (response1) {
              if (response1.hasOwnProperty('mobile') && response1['mobile'] && response1['mobile'] !== null && response1['mobile'] !== undefined && response1['mobile'] !== '') {
                localStorage.setItem('bearerToken', 'Bearer ' + response.body['jwt']);
                localStorage.setItem('Auth', 'true');
                localStorage.setItem('personDetails', JSON.stringify(response1));
                this.router.navigateByUrl('/dashboard');
              } else {
                const dialogRef = this.dialog.open(GetMobileNumberComponent, {
                  maxWidth: '65vw',
                  minWidth: '50vw',
                  maxHeight: '60vh',
                });

                dialogRef.afterClosed().subscribe(response2 => {
                  if (response2 && response2 !== '') {
                    this.service.updateMobileNumber(response.body['userId'], { mobile: response2}).subscribe(response3 => {
                      if (response3) {
                        localStorage.setItem('bearerToken', 'Bearer ' + response.body['jwt']);
                        localStorage.setItem('Auth', 'true');
                        localStorage.setItem('personDetails', JSON.stringify(response1));
                        this.router.navigateByUrl('/dashboard');
                      } else {
                        localStorage.removeItem('bearerToken');
                        this.router.navigateByUrl('/login');
                        this.snackBar.open('Error while storing the mobile number. Please try again after sometime.', 'Error', {
                          duration: 5000,
                          verticalPosition: 'top',
                          horizontalPosition: 'center'
                        });
                      }
                    }, error => {
                      localStorage.removeItem('bearerToken');
                      this.router.navigateByUrl('/login');
                      this.snackBar.open('Error while storing the mobile number. Please try again after sometime.', 'Error', {
                        duration: 5000,
                        verticalPosition: 'top',
                        horizontalPosition: 'center'
                      });
                    });
                  } else {
                    localStorage.removeItem('bearerToken');
                    this.router.navigateByUrl('/login');
                  }
                });
              }
            } else {
              localStorage.removeItem('bearerToken');
              this.router.navigateByUrl('/login');
              this.snackBar.open('Error while authenticating. Please try again after sometime.', 'Error', {
                duration: 5000,
                verticalPosition: 'top',
                horizontalPosition: 'center'
              });
            }
          }, error => {
            localStorage.removeItem('bearerToken');
            this.router.navigateByUrl('/login');
            this.snackBar.open('Error while retrieving the user details. Please try again after sometime.', 'Error', {
              duration: 5000,
              verticalPosition: 'top',
              horizontalPosition: 'center'
            });
          });
        } else {
          this.router.navigateByUrl('/login');
          this.snackBar.open('Error while authenticating. Please try again after sometime.', 'Error', {
            duration: 5000,
            verticalPosition: 'top',
            horizontalPosition: 'center'
          });
        }
      } else {
        localStorage.removeItem('bearerToken');
        this.router.navigateByUrl('/login');
        this.snackBar.open('Error while authenticating. Please try again after sometime.', 'Error', {
          duration: 5000,
          verticalPosition: 'top',
          horizontalPosition: 'center'
        });
      }
    }, error => {
      this.router.navigateByUrl('/login');
      this.snackBar.open('Error while authenticating. Please try again after sometime.', 'Error', {
        duration: 5000,
        verticalPosition: 'top',
        horizontalPosition: 'center'
      });
    });
  }

}

@Component({
  selector: 'app-getmobilenumber',
  templateUrl: 'get-mobile-number.component.html',
  styleUrls: ['get-mobile-number.component.css']
})
export class GetMobileNumberComponent implements OnInit {

  mobileNumber = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<GetMobileNumberComponent>, public router: Router, public snackBar: MatSnackBar, public service: LinxService) {
    dialogRef.disableClose = true;
  }

  isValidMobileNumber(num) {
    return /^\s*\+?\s*([0-9][\s-]*){9,}$/.test(num);

  }

  ngOnInit() {

  }

  omit_special_char(event) {
    let k;
    k = event.charCode;
    return((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || (k >= 48 && k <= 57));
  }

  saveMobileNumber() {
    if (this.mobileNumber && this.mobileNumber !== '') {
      if (this.isValidMobileNumber(this.mobileNumber)) {
        this.dialogRef.close(this.mobileNumber);
      } else {
        this.snackBar.open('Please provide a valid mobile number to continue.', 'Error', {
          duration: 5000,
          verticalPosition: 'top',
          horizontalPosition: 'center'
        });
      }
    } else {
      this.snackBar.open('Please provide your mobile number to continue.', 'Error', {
        duration: 5000,
        verticalPosition: 'top',
        horizontalPosition: 'center'
      });
    }
  }

  close() {
    this.dialogRef.close();
  }
}

