<app-navbar></app-navbar>

<div class="container-fluid p-0 m-0">
  <div class="row no-gutters justify-content-center" style="background: aliceblue">
    <div class="col-12">
      <div class="d-flex justify-content-center" style="background: #3936c4; width: 100%;">
        <div class="pt-3 d-flex justify-content-start" style="width: 1020px">
          <a class="py-2 px-3 rounded-top border-0" style="color: #2E2C97; background-color: aliceblue; text-decoration: none;">Dashboard</a>
        </div>
      </div>

      <div [hidden]="tab!=='dashboard'" class="py-3 px-lg-8 row justify-content-center no-gutters" style="background: aliceblue">

        <!-- Overall Performance -->
        <div class="m-3" style="width: 1020px">
          <div class="d-flex flex-wrap flex-lg-nowrap justify-content-center align-items-center">
            <div class="d-flex px-1 py-1 mx-2 my-3" style="background: white; border-radius: 10px" *ngFor="let item of resultData">
              <img src="{{ item.logo }}" class="background py-3 px-2" />
              <div class="d-flex flex-column justify-content-center align-items-center px-2 py-2 text-center">
                <div class="pb-2" style="font-size: smaller">{{ item.title }}</div>
                <div style="font-weight: 600; font-size: 23px">{{ item.value }}</div>
              </div>
            </div>
          </div>
        </div>

        <!-- Modules -->
        <div class="m-3 box" style="background: #E3EEFF;">
          <div class="d-flex flex-wrap flex-lg-nowrap justify-content-center align-items-center">
            <div class="card p-2 border-0 integrated_course_div">
              <a [routerLink]="['/modules']" class="integrated_course_btn py-2 px-2 border-0">{{ integratedCourse_title }}</a>
              <p class="para py-3 pr-4">{{ integratedCourse_description }}</p>
              <p class="overall my-2">Overall</p>
              <div class="d-flex justify-content-start align-items-center">
                <div class="progress" style="background-color: #e7e7e7;width:80%;">
                  <div class="progress-bar" [style.width] = "integratedCourse_completedQues + '%'" style="background-color: #0DDBBF;width:20%"></div>
                </div>
                <div class="ml-3 para" style="font-weight: bold">{{ integratedCourse_completedQues + '%' }}</div>
              </div>
              <p class="para2 my-3">{{ integratedCourse_subDescription }}</p>
            </div>

            <div class="card border-0 rounded shadow module_div">
              <div class="row p-2 m-2 rounded" *ngFor="let item of moduleData" style="background-color : #F3F7FD">
                <div class="col-8">
                  <div class="item1" style="font-size: 16px; font-weight: 800;">{{ item.moduleName }}</div>
                  <div class="item2 mt-1" style="font-size: smaller;">
                    <img src="/assets/Vectortest.svg" />&nbsp; {{ item.totalTests + ' Tests' }}
                    <img src="/assets/Vectortick.svg" />&nbsp; {{ item.completedTests + ' Completed' }}
                  </div>  
                </div>
                <div class="col-4 d-flex flex-wrap flex-lg-nowrap justify-content-center align-items-center">
                  <div class="progress">
                    <div class="progress-bar" [ngStyle]="{'width': item.completePercentage + '%'}"></div>
                  </div>
                  <div class="ml-2" style="font-size: 16px; font-weight: 600;">{{ item.completePercentage + '%' }}</div>
                </div>
              </div>
            </div>  
          </div>
        </div>

        <!-- Recent Completed Tests -->
        <div class="m-3 box">
          <div class="black-bar d-flex flex-lg-nowrap flex-wrap justify-content-between align-items-center">
            <div class="pl-3">Completed Tests</div>
            <div class="dropdown m-2">
              <select name="latesTests" id="latesTests" (change)="selectNTests($event)" class="btn border border-white dropdown-btn">
                <option class="text-center p-3" [value]="item" *ngFor="let item of recentTestList">{{ "Recent " + item }}</option>
              </select>
            </div>
          </div>

          <div class="card border-0 rounded shadow mx-3 my-4" style="background-color: #F3F7FD;"  *ngFor="let item of recentTestData">
            <div class="d-flex p-3">
              <div class="justify-content-start align-self-center">
                <div class="px-3" ><a [routerLink]="['/report']" [queryParams]="{id: item.userAnswerSetId}" class="testName">{{ item.testName }}</a></div>
                <div class="pl-3 testData" style="font-size: smaller;">{{ item.timeSubmitted }}</div>
                <div class="d-flex flex-wrap m-2 testData">
                  <div class="p-2 testName1"><span><b>{{ item.totalQuestions }}</b></span> Questions</div>
                  <div class="p-2 testName1"><span><b>{{ item.numAnswered }}</b></span> Attemped</div>
                  <div class="p-2 testName1"><span><b>{{ item.numCorrect }}</b></span> Correct</div>
                  <div class="p-2 testName1"><span><b>{{ item.accuracy  }}</b></span> Accuracy</div>
                  <div class="p-2 testName1"><span><b>{{ item.avgTimePerQuestion }}</b></span> Avg time/Qn</div>
                </div>
                <div class="px-3 testName1"><b>Ranking</b><span class="ml-2" data-toggle="modal" data-target="#exampleModal"><i class="bi bi-info-circle-fill infoBtn"></i></span><span class="testData"> - <b>{{ item.rank.personRank + '/' + item.rank.numPeople }}</b></span></div>
              </div>
              <div class="align-self-center ml-auto">
                <div class="justify-content-center align-items-center px-4 py-2" style="background: rgba(250, 162, 142, 0.2);border-radius: 10px;">
                  <div style="font-size: smaller;"><b>total score</b></div>
                  <div style="font-size: larger; font-weight: bold; color: rgb(250, 162, 142);"><span>{{ item.totalScore }}</span>/<span>{{ item.maxScore }}</span></div>
                </div>
              </div>
            </div>
            <div class="ml-4 p-2 testName"><b>Weak Areas</b></div>
            <div class="ml-4">
              <div class="d-flex flex-wrap align-items-center list-unstyled">
                <div class="to-improve-feedback m-2 p-2" *ngFor="let data of item.weakAreas">{{ data.subject }}</div>
              </div>
            </div>

          </div>
        </div>

        <!-- Ranking info popup -->
        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header" style="background-color: #e6f2ff;">
                <strong>Ranking</strong> - You / People who attempted this test.
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="outline: none; border:none">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Performance summery -->
        <div class="m-3 box">
          <div class="black-bar d-flex flex-lg-nowrap flex-wrap justify-content-between align-items-center">
            <div class="pl-3">Performance Summary</div>
            <div class="dropdown m-2">
              <select name="modules" id="moduleList" (change)="selectModule($event)" class="btn border border-white dropdown-btn">
                <option *ngFor="let item of moduleList" [value]="item">{{ item }}</option>
              </select>              
            </div>
          </div>
          <div class="row p-3 align-items-center justify-content-center">
            <div class="p-3 col-lg-8 col-xl-8 col-md-12 col-sm-12 col-xs-12">
              <canvas baseChart 
                [colors]="chartColors" 
                [datasets]="barChartData" 
                [labels]="barChartLabels" 
                [options]="barChartOptions" 
                [legend]="barChartLegend" 
                [chartType]="barChartType">
              </canvas>
            </div>
            <div class="p-3 col-lg-4 col-xl-4 col-md-12 col-sm-12 col-xs-12">
              <div class="card shadow rounded border-0 text-center">
                <table class="table table-striped">
                  <thead>
                    <tr style="background: aliceblue;">
                      <th scope="col" class="align-item-center">Subject</th>
                      <th scope="col">% Score</th>
                      <th scope="col">Average Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of performanceData">
                      <td scope="row" [ngStyle]= "{ 'color' : item.subjectColor }">{{ item.subject }}</td>
                      <td>{{ item.percentage + '%' }}</td>
                      <td>{{ item.avgTotal }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>