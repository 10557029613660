<app-navbar></app-navbar>
<div class="main">
    <div class="row justify-content-center">
        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 text-center">
            <div class="heading p-4">Select Paper <span *ngIf="!collapsed" (click)="collapseList()" class="ml-2 mt-2"><i class="bi bi-caret-up-fill"></i></span><span *ngIf="collapsed" (click)="collapseList()" class="ml-2 mt-2"><i class="bi bi-caret-down-fill"></i></span></div>
            <div *ngFor="let data of pdfs">
                <div *ngIf="!collapsed" class="month p-2">{{ data.month }}</div>
                <ul *ngIf="!collapsed" class="list-unstyled beautify px-4">
                    <li class="p-3 mt-2" (click)="getPdf(item.title, item.url)" *ngFor="let item of data.pdf">{{ item.title }}</li>
                </ul>
            </div>
        </div>
        <div [ngClass]="collapsed ? 'col-12' : 'col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12'" class=" text-center">
            <img *ngIf="isImgVisible" src="/assets/img/homepage-landing.png" width="100%" class="p-4" alt="image">
            <div *ngIf="!isImgVisible" class="p-4 pdf-title">{{ pdf_title }}</div>
            <div *ngIf="!isImgVisible && spinner" class="spinner-border mt-5" role="status" style="width: 100px; height:100px">
                <span class="sr-only">Loading...</span>
            </div>
            <pdf-viewer *ngIf="!isImgVisible && !spinner" 
                        [src]="pdfSrc" 
                        [render-text]="true" 
                        [external-link-target]="'blank'" 
                        [original-size]="false"
                        [autoresize]="true"
                        style="display: block;"></pdf-viewer>
        </div>
    </div>
</div>