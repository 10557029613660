import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {LinxService} from '../linx.service';

@Component({
  selector: 'app-chartscomp',
  templateUrl: './chartscomp.component.html',
  styleUrls: ['./chartscomp.component.scss']
})
export class ChartscompComponent implements OnInit {

  constructor(private service: LinxService, public dialog: MatDialog) {
  }
  Options: any;
  Data: any;
  labels: any;
  drillData: any;
  @Input() Type: any;
  @Input() chartLibrary: any;
  @Input() chartType: any;
  @Input() data: any;
  @Input() inputSource: any;
  @Input() inputType: any;
  @Input() labelExtractor: any;
  @Input() dataExtractor: any;
  @Input() drillDown: any;
  result: any;
  keys: any;
  ngOnInit() {
    const data = {
      chartLibrary: this.chartLibrary,
      chartType: this.chartType,
      data: this.data,
      inputSource: this.inputSource,
      inputType: this.inputType,
      labelExtractor: this.labelExtractor,
      dataExtractor: this.dataExtractor,
      drillDown: this.drillData
    };
    this.service.getCharts('linkzy', 'linkzy', '3fdfb5476cd996603febe74f67d39715', data).subscribe( data1 => {
      this.labels = data1['body'].labels;
      this.Data = data1['body'].dataSet;
      this.Options = data1['body'].options;
      this.result = data1['body'];
    });
  }
  click(event) {
    this.drillData = this.result.tabularData[event.active[0]._index];
    this.keys = Object.keys(this.drillData);
    // const dialogRef = this.dialog.open(HvrComponent, {
    //   width: '50%',
    //   data: this.result.tabularData[event.active[0]._index]
    // });
    // dialogRef.afterClosed().subscribe(result => {
    // });
  }
}

@Component({
  selector: 'app-hvr',
  templateUrl: './hvr.component.html',
  styleUrls: ['./hvr.component.scss']
})
export class HvrComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
  }
  keys: any;
  ngOnInit() {
    this.keys = Object.keys(this.data);
  }
  stringifyjson(val) {
    return JSON.stringify(val);
  }
}
