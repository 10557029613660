import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LinxService} from '../linx.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ConfirmSubmitExamComponent} from "../exam/exam.component";

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  constructor(public route: ActivatedRoute, public service: LinxService, public router: Router, public snackBar: MatSnackBar, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {

        //payment_id: "423"
        //payment_request_id: "e45bb438-fece-438c-931a-40835e043bc4"
        //payment_status: "success"

        if (params && params.payment_request_id && params.payment_id && params.payment_status) {
          const payload = {
            paymentId: params.payment_id,
            paymentStatus: params.payment_status,
            paymentRequestId: params.payment_request_id,
            examPaymentRequestId: localStorage.getItem('paymentID')
          }; 

          this.service.verifyPaymentAfterRedirect(payload).subscribe(response => {
            
          //   {
          //     "isSuccess":false,
          //     "errorMsg":{
          //        "transactionId":"9b080e70-3bd5-43b1-81db-99243403e48c",
          //        "invoiceNumber":425,
          //        "amount":1500.0,
          //        "itemBought":"Invoice for Module 1 - NCERT Test Series : INR1500.00. (inclusive of 18% GST) Invoice # 425",
          //        "msg":null,
          //        "status":"success"
          //     }
          //  }

            if (response) {
              var jsonRes = JSON.parse(response);
              //moduleName: "Module 1"
              //status: "Failed"
              if (jsonRes.isSuccess) {
              
                localStorage.removeItem('paymentID');
                this.router.navigateByUrl('/modules');
                // this.snackBar.open('Payment successful.', 'Success', {
                //   duration: 5000,
                //   verticalPosition: 'top',
                //   horizontalPosition: 'center'
                // });
                const dialogRef = this.dialog.open(PaymentPromptComponent, {
                  maxWidth: '65vw',
                  minWidth: '50vw',
                  maxHeight: '60vh',
                  data: {

                    status: "Success",
                    transactionId:jsonRes.errorMsg.transactionId,
                    invoiceNumber:jsonRes.errorMsg.invoiceNumber,
                    itemBought:jsonRes.errorMsg.itemBought,
                    amount:jsonRes.errorMsg.amount,
                    errorMessage:"Thank you."

                  }
                });
              }
              else {
              
                localStorage.removeItem('paymentID');
                this.router.navigateByUrl('/modules');
                // this.snackBar.open(
                //   'Error while making payment. ' + jsonRes.errorMsg + " Please contact ExcelOn support.", 'Error', {
                //   duration: 5000,
                //   verticalPosition: 'top',
                //   horizontalPosition: 'center'
                // });
                const dialogRef = this.dialog.open(PaymentPromptComponent, {
                  maxWidth: '65vw',
                  minWidth: '50vw',
                  maxHeight: '60vh',
                  data: {

                    status: "Failed",
                    transactionId:jsonRes.errorMsg.transactionId,
                    invoiceNumber:jsonRes.errorMsg.invoiceNumber,
                    itemBought:jsonRes.errorMsg.itemBought,
                    amount:jsonRes.errorMsg.amount,
                    errorMessage:"Transaction verification failed. Please contact support."
                    
                  }
                });
              }

            }
          }, error => 
          {
            localStorage.removeItem('paymentID');
            this.router.navigateByUrl('/modules');
            // this.snackBar.open('Error while making payment. Please try again after sometime or contact ExcelOn admin.', 'Error', {
            //   duration: 5000,
            //   verticalPosition: 'top',
            //   horizontalPosition: 'center'
            // });
            const dialogRef = this.dialog.open(PaymentPromptComponent, {
              maxWidth: '65vw',
              minWidth: '50vw',
              maxHeight: '60vh',
              data: {
                status: "Failed",
                transactionId:params.payment_request_id,
                invoiceNumber:params.payment_id,
                itemBought:"",
                amount:"",
                errorMessage:"Error from payment gateway. Please contact support."
              }
            });
          });
        }
      });
  }

}

@Component({
  selector: 'app-confirmsubmittest',
  templateUrl: '../payment/payment-prompt.component.html',
  styleUrls: ['../payment/payment-prompt.component.css']
})
export class PaymentPromptComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<PaymentPromptComponent>, public router: Router, public snackBar: MatSnackBar, public service: LinxService) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }
}
