<app-navbar></app-navbar>
<div class="card p-4 shadow mx-4">
  <div class="row align-items-center">
    <div class="col-lg-8 col-md-8 col-sm-12 mentorbox">
      <h1 class="text-center"><strong>Who is a mentor?</strong></h1>
      <hr>
      <div class="mentor-list text-left">
        <ol class="mt-2">
          <li class="p-3">A mentor is one who sees your talent and ability and also your blindspots that you may not be even aware of.</li>
          <li class="p-3">A Mentor is a brain to pick, a ear to listen. He/She is gentle when you are low but tough on you when you are operating below your capacity. </li>
          <li class="p-3">His/Her aim is to keep pushing you in the right direction till you reach your goal.</li>
          <li class="p-3">A mentor walks alongside you through your ups and downs in your journey</li>
          <li class="p-3"> Above all, he/she is a guide, a navigator, a ray of hope when you find yourself lost in wilderness.</li>
        </ol>
      </div>
    </div>
    <img class="col-lg-4 col-md-4 col-sm-12 mt-4" src="/assets/mentor1.png" alt="">
  </div>
</div>

<div class="m-4 p-4">
  <div class="text-center interested">
    Interested to speak with our experienced mentor?<br/>
    <a href="https://docs.google.com/forms/d/1T03ynIY3OCa8JNgo64s3iynBi-SaU2aCPB8AA1vC9LE/edit#question=1921999998&field=1040979357" target="_blank" class="btn btn-success mt-4 py-2 px-3">Register For Free Session<span class="ml-2 mt-3"><i class="bi bi-arrow-right"></i></span></a>
  </div>
</div>

<div class="card p-4 shadow mx-4 mt-4">
  <div>
    <h1 class="text-center"><strong>What are the challenges faced in UPSC preparation?</strong></h1>
    <hr>
    <div class="row mt-4 justify-content-center align-items-center">
      <div class="mt-4 col-lg-6 col-md-6 col-sm-12 text-center">
        <div class="chakra_div">
          <div class="chakra_div1">
            UPSC “CHAKRVYUH”
          </div>
          <div class="mt-2 chakra_exp">
            – Factors that prevent YOU from becoming an IAS officer.
          </div>
        </div>
       
        <img class="chakra_img mt-4" width="100%" src="/assets/mentorchakra.png" alt="">
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 mentorbox">
        <div class="mt-4 chakra_exp1">
          You need to break this “chakravyuh” to realise your dreams.
        </div>
        <div class="challanges-list">
          <ol class="mt-2">
            <li class="p-3"><span><strong>Information overload</strong></span> - If you are self preparing, there is plethora of information which confuses you on where to start, when to start, how to read and where to stop?</li>
            <li class="p-3"><span><strong>Coaching is expensive but not always effective</strong></span> - If you have joined a foundation course paying a hefty amount of 1.5 lakh and by the end of the program you are right exactly at the position you started.</li>
            <li class="p-3"><span><strong>Low face time with faculty</strong></span> - You need to someone to talk to after the class but your faculty is not available personally.</li>
            <li class="p-3"><span><strong>Inability to work on the plan</strong></span> - You make a schedule for a whole year and fell short after few days.</li>
            <li class="p-3"><span><strong>Long and arduous journey</strong></span> - You feel low as it a lengthy and monotonous process in which you feel lost and low a lot of times.</li>
            <li class="p-3"><span><strong>Impact on morale</strong></span> - Lack of motivation and confidence as the competition is high.</li>
            <li class="p-3"><span><strong>Unfavourable study environment</strong></span> - Not having a good peer group or conditions at home</li>
            <li class="p-3"><span><strong>Job - study balance</strong></span> - Efficient time management to maximise study time and output</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card p-4 shadow mx-4 mt-4">
  <h1 class="text-center"><strong>Assess whether you need mentoring?</strong></h1>
  <hr>
  <div class="mt-4 row align-items-center">
    <div class="col-lg-4 col-md-4 col-sm-8 col-xs-12 p-2 text-center chakra_exp1">Answer these 9 questions to find out</div>
    <div class="card shadow question-div-card col-lg-8 col-md-8 col-sm-12 col-xs-12">
      <div *ngIf="!isSubmitted">
        <ul class="list-unstyled" *ngFor="let item of questionsArray;">
            <li class="p-2">
                <div>
                  <div class="d-flex justify-content-end p-2">
                    <div class="counter-div py-2 px-4">{{ item.index+1 + ' / ' + questions.length }}</div>
                  </div>
                  <div class="p-3">
                    <h4 class="pb-2"><strong>{{ item.question }}</strong></h4>
                    <div class="p-2">
                      <mat-radio-group class="example-radio-group" [(ngModel)]="answer">
                        <mat-radio-button class="example-radio-button" [ngClass]="i===0? '' : 'ml-4'" *ngFor="let answer of answers; index as i" [value]="answer">
                          {{answer}}
                        </mat-radio-button>
                      </mat-radio-group>                            
                    </div>
                    <div [ngStyle]="{'display':errorMsg}" class="ml-2 errormsg">
                      Please select an option
                    </div>
                    <div class="d-flex align-items-center mt-2" [ngClass]="item.index!==0 ? 'justify-content-between' : 'justify-content-end'">
                      <button *ngIf="item.index!==0" class="btn btn-primary" (click)="clickPrev()"><i class="bi bi-arrow-left"></i></button>
                      <button *ngIf="item.index!== questions.length-1" class="btn btn-primary" (click)="clickNext()"><i class="bi bi-arrow-right"></i></button>
                      <button *ngIf="item.index=== questions.length-1" class="btn btn-primary" (click)="clickSubmit()">Submit</button>
                    </div>
                  </div>
                </div>
            </li>
        </ul>
      </div>
      <div *ngIf="isSubmitted">
        <div class="m-4 p-4 result" [ngClass]="resultClass">
          {{result}}
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card p-4 shadow mx-4 mt-4">
  <div class="row align-items-center chakra">
    <div class="col-lg-10 col-md-8 col-sm-12 text-center">
      <h1><strong>What kind of mentoring support we will provide?</strong></h1>
      <hr>
    </div>
    <img class="col-lg-2 col-md-4 col-sm-12" src="/assets/confuse.png" alt="">
  </div>   
  <div class="challanges-list">
      <ol class="mt-2">
          <li class="p-3"><span><strong>Customised study schedule</strong></span> based on your level of preparation.</li>
          <li class="p-3"><span><strong>Syllabus break down</strong></span> for both prelims and MAINS</li>
          <li class="p-3"><span><strong>Soft copy</strong></span> of all relevant NCERT books</li>
          <li class="p-3"><span><strong>Structured one to one discussion twice a month</strong></span> with experienced mentors to clear your doubts (through video conferencing)</li>
          <li class="p-3"><span><strong>24X7 availability of mentoring</strong></span> on Whatsapp/telegram to resolve immediate queries.</li>
          <li class="p-3"><span><strong>Monthly progress report card</strong></span> that will contain your achievements and areas of improvements</li>
          <li class="p-3"><span><strong>Periodic meet the civil servant session</strong></span> will be arranged for motivation as well as a doubt clearance/strategy.</li>
      </ol>
  </div>
  <div class="p-2 mt-4 exp">
    UPSC preparation is a rigorous and tough task which demands time of atleast 1 year. During this phase you will come across a lot of problems and sometimes fell short of solving them. This is where we want to help you. We want to be your <span><strong> mentor/guide/experienced friend </strong></span> to be on your side and make your goals achievable to push you towards realising your full potential. <br><br> 
    We will use our accumulated experience and knowledge of having mentored hundreds of aspirants. The aim is to alert you on a timely basis in avoiding potential mistakes that aspirants make. Learning from one’s own mistakes will be time consuming. Hence why not learn from other’s mistakes as we share our experience with you?      
  </div>

</div>

<div class="card p-4 shadow mx-4 mt-4">
  <h1 class="text-center"><strong>Meet Your Mentors</strong></h1>
  <hr>
  <div class="m-lg-4">
    <div class="row align-items-center justify-content-center">
      <div class="col-lg-8 col-md-8 col-sm-10">
        <div class="py-3 row mentorbox">
          <div class="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center">
            <div class="card shadow mentorcard">
              <img src="/assets/mentorpic1.jpg" class="card-img-top" alt="...">
              <div class="card-body">
                <h2 class="card-title">T Uday Kumar</h2>
                <p class="card-text">Uday is the co-founder of Excelon Academy and has personally mentored more than 500 UPSC aspirants. He teaches Environment, Science & Technology and International Relations.</p>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center">
            <div class="card shadow mentorcard">
              <img src="/assets/mentorpic2.jpeg" class="card-img-top" alt="...">
              <div class="card-body">
                <h2 class="card-title">Datha Sai</h2>
                <p class="card-text">Datha Sai, an expert mentor for UPSC aspirants, He has mentored several UPSC candidates</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="mt-4">
  <app-footer></app-footer>
</div>