
<h2>{{data['itemBought']}} </h2>
<hr>
<h3>
 <table>
    <tr>
        <td>  Payment status</td> <td>  <b>: {{data['status']}} </b>  </td>
    </tr>    

    <tr>
        <td> Transaction Id </td> <td>: {{data['transactionId']}} </td>
    </tr>

    <tr>
        <td> Invoice Number</td>  <td>: {{data['invoiceNumber']}} </td>
    </tr>
    
    <tr>
        <td> Amount :</td> <td>: {{data['amount']}} </td>
    </tr>
    
</table>
</h3>

<h2>{{data['errorMessage']}}</h2>


<!-- 
<span *ngIf="data['status'] === 'Failed'" style="color: red"> 
    
    Failed 

</span> 

<span *ngIf="data['status'] === 'Success'" style="color: greenyellow"> 
    Success 
</span>  -->

<div class="row">
  <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-right">
    <button class="button-class" (click)="close()">Close</button>
  </div>
</div>

