import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mentor',
  templateUrl: './mentor.component.html',
  styleUrls: ['./mentor.component.scss']
})
export class MentorComponent implements OnInit {
  activeBtn = false;
  index = 0;
  questionsArray = [];
  answer: string;
  answers: string[] = ['YES', 'NO'];
  errorMsg: string = "none";
  isSubmitted: boolean = false;
  result: string;
  resultClass : string;

  questions = [
    {
      question : "Have you enrolled in a coaching institute?",
      answer : ""
    },
    {
      question : "Are you satisfied with your faculty?",
      answer : ""
    },
    {
      question : "Are you able to have a one-on-one interaction with your faculty regularly?",
      answer : ""
    },
    {
      question : "Do you have clarity on dividing your time between prelims, mains, between GS and Optional, between reading and writing?",
      answer : ""
    },
    {
      question : "Do you have mood swings and lows while preparing?",
      answer : ""
    },
    {
      question : "Do you have a peer group or a mentor to talk to?",
      answer : ""
    },
    {
      question : "Do you live in a rural/remote/tier-3 town in India?",
      answer : ""
    },
    {
      question : "Is this your 1st attempt?",
      answer : ""
    },
    {
      question : "Do you have a support system at home (e.g. parents, siblings) who encourage you?",
      answer : ""
    },
  ];

  constructor() { }

  ngOnInit(): void {
    this.getQuesData();
  }

  clickPrev() {
    this.index--;
    this.getQuesData();
    this.answer = this.questions[this.index].answer;
  }

  clickNext() {
    if(this.answer === undefined) {
      this.showErrorMsg();
      return;
    }

    this.questions[this.index].answer = this.answer;
    this.index++;
    this.getQuesData();
    this.answer = undefined;
  }

  clickSubmit() {
    if(this.answer === undefined) {
      this.showErrorMsg();
      return;
    }

    this.isSubmitted = true;
    this.questions[this.index].answer = this.answer;
    this.getResults(this.questions);
  }

  getQuesData() {
    this.questionsArray[0] = this.questions[this.index];
    this.questionsArray[0].index = this.index;
  }

  getResults(ques) {
    if(ques[0].answer === "NO" || ques[1].answer === "NO" || ques[2].answer === "NO" || ques[3].answer === "NO" || ques[4].answer === "YES" || ques[5].answer === "NO" || ques[6].answer === "YES" || ques[7].answer === "YES" || ques[8].answer === "NO") {
      this.result = "Mentoring is strongly recommended";
      this.resultClass = "result_bad";
    } 
    else if(ques[2].answer === "YES" && ques[3].answer === "YES" && ques[8].answer === "YES") {
      this.result = "Mentoring not necessary as of now";
      this.resultClass = "result_good";
    }
    else if(ques[1].answer === "YES" && ques[3].answer === "YES" && ques[6].answer === "YES") {
      this.result = "Mentoring may be considered";
      this.resultClass = "result_intermediate";
    }
  }

  showErrorMsg() {
    this.errorMsg = "block";
    setTimeout(() => {
      this.errorMsg = "none";
    }, 2000);
    return;
  }
}
