import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ListenerService {

  clearAnswer: EventEmitter<any> = new EventEmitter();
  navBarLoggedInUpdation: EventEmitter<any> = new EventEmitter();
  thirdPartySignIn: EventEmitter<any> = new EventEmitter();

  constructor() { }
}
