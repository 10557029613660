<div class="row">
  <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-center">
    <h3 style="font-weight: bolder;"> Comment on the Question</h3>
  </div><hr>
</div>
<mat-dialog-content>
<!--  <div style="padding: 0.5rem;" class="row">-->
    <div class="row">
      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
        <p class="label-style">
          Question
        </p>
      </div>
    </div>
    <div class="row" style="margin: 0.6rem">
      <div style="border:1px solid #E1DBDB;border-radius: 5px;padding: 1rem;margin: 0.3rem; width: 100%;" class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div style="float: left">
          <img src="assets/question.svg" style="height: 1.4rem;" />
        </div>
        <div class="col-lg-12 col-md-12" [innerHTML]="data['ques']" style="margin-left: 1rem;"></div><br>
        <div style="float: left">
          <img src="assets/answer.svg" style="height: 1.4rem;" />
        </div>
        <ol style="list-style-type: none;margin-left: 1rem;padding-left: 1rem;">
          <li style="margin-bottom: 0.8rem;" *ngFor="let answer of data['ans']">
            <div>
              <span><div class="circle"></div><span style="margin-left: 0.4rem; color: #0478ED">{{answer['answerOption']['answerText']}}</span></span>
            </div>
          </li>
        </ol>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
        <p style="margin-top: 0.7rem;" class="label-style">
          Comment
        </p>
        <textarea style="height: 200px;width: 100%;border-radius: 4px;" [(ngModel)]="comment" [value]="comment"></textarea>
      </div>
    </div>
<!--  </div>-->
</mat-dialog-content>
<div class="row">
  <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-right">
    <button class="button-class" (click)="leaveComment();"> Comment </button>
    <button class="button-class" (click)="close();"> Close </button>
  </div>
</div>
