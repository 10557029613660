import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-excelon-on-news',
  templateUrl: './excelon-on-news.component.html',
  styleUrls: ['./excelon-on-news.component.scss']
})
export class ExcelonOnNewsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
