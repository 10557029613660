import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {InputDecorator} from '@angular/core';
import {Input} from '@angular/core';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, OnChanges {
  pager: any = {};
  @Input() list: any;
  @Input() original: any;
  @Input() blindCount: number;
  @Input() reviewCount: number;
  @Input() answeredCount: number;
  @Input() notAnsweredCount: number;
  @Input() paper: any;
  @Input() examId: number;
  filter = false;
  answeredFlag = false;
  blindFlag = false;
  reviewFlag = false;
  nonAnsweredFlag = false;
  pageEvent: any;
  private allItems: any[];
  items = [];
  pageOfItems: Array<any>;
  constructor() { }

  filterTypes = [
    {
      name: 'Not Attempted',
      value: 'notAttempted'
    },
    {
      name: 'Answered',
      value: 'answered'
    },
    {
      name: 'To Review',
      value: 'toReview'
    },
    {
      name: 'Blind Guess',
      value: 'blindGuess'
    },
    {
      name: 'All',
      value: 'all'
    }
  ];

  selectedFilterType = 'all';

  ngOnInit(): void {
    this.items = this.list;
    // for (let i in this.list) {
    //   if(this.list[i]['answered'] === 'true') {
    //     this.answeredCount++;
    //   }
    //   if(this.list[i]['toReview'] === 'true') {
    //     this.reviewCount++;
    //   }
    //   if(this.list[i]['isBlindSpot']==='true') {
    //     this.blindCount++;
    //   }
    // }
  }
  // reviewIncrement() {
  //   this.reviewCount++;
  //   alert('this.reviewCount' + this.reviewCount);
  // }
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }
  unsetFlag() {
    this.filter = false;
    this.answeredFlag = false;
    this.nonAnsweredFlag = false;
    this.blindFlag = false;
    this.reviewFlag = false;
  }
  setFlag(str: string) {
    this.filter = true;
    if (str === 'answered') {
      this.answeredFlag = true;
    }
    if (str === 'answered1') {
      this.nonAnsweredFlag = true;
      this.blindFlag = false;
      this.reviewFlag = false;
      // tslint:disable-next-line:align
    }
    if (str === 'blindGuess') {
      this.blindFlag = true;
    }
    if (str === 'review') {
      this.reviewFlag = true;
    }
  //   setTimeout(function(){
  //     var lenggg = (document.getElementsByClassName('para4')).length;
  //     alert('para4 '+ lenggg);
  // }, 0);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.notAnsweredCount = this.list.length - this.answeredCount;
  }
}
