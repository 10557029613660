import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl  } from '@angular/platform-browser'
import {LinxService} from '../linx.service';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  videoList : any;
  constructor(public service: LinxService) { }

  ngOnInit(): void {
    this.videoDetails();
  }

  videoDetails() {
    this.service.videoDetails().subscribe(response => {
      if (response) {
        this.videoList = response;
      } else {
        //show some message
      }
    });
  }
}

@Pipe({ name: 'safelink'})

export class safeVideoLink implements PipeTransform  {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) {
    return this.sanitized.bypassSecurityTrustResourceUrl(value);
  }
}