import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.scss']
})
export class TestimonialComponent implements OnInit {

  testimonialData = [
    {
      title : 'Testimonials',
      content : 'Lorem  simply dummy text of the printing and typesetting industry. Lorem  simply dummy text of the printing and typesetting industry. Lorem  simply dummy text of the printing and industry. Lorem  simply lorem ipsum dummy text of the printing and typesetting industry. ',
      name : 'John Doe',
      company : 'Hubspire',
      for: 't-1'
    },
    {
      title : 'Testimonials',
      content : 'Lorem  simply dummy text of the printing and typesetting industry. Lorem  simply dummy text of the printing and typesetting industry. Lorem  simply dummy text of the printing and industry. Lorem  simply lorem ipsum dummy text of the printing and typesetting industry. ',
      name : 'John Doe',
      company : 'Hubspire',
      for: 't-2'
    },
    {
      title : 'Testimonials',
      content : 'Lorem  simply dummy text of the printing and typesetting industry. Lorem  simply dummy text of the printing and typesetting industry. Lorem  simply dummy text of the printing and industry. Lorem  simply lorem ipsum dummy text of the printing and typesetting industry. ',
      name : 'John Doe',
      company : 'Hubspire',
      for: 't-3'
    }
  ];

  constructor() { }

  ngOnInit(): void {}

}
