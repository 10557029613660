import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

export interface FooInterface {
    status: string;
    fooString: string;
    fooNumber: number;
    id: string;
    sid: string;
}

@Injectable()
export class Loggedin {
    constructor(private _http: HttpClient, public router: Router) {
    }

    public getLogin(): boolean {
        return true;
    }

    public logout() {
        localStorage.removeItem('idToFind');
        localStorage.removeItem('provider');
        localStorage.removeItem('Auth');
        localStorage.removeItem('bearerToken');
        localStorage.removeItem('currentExamData');
        localStorage.removeItem('personDetails');
        localStorage.removeItem('examMode');
        localStorage.removeItem('showCommentPopup');
        window.location.href = '/';
    }
}

@Injectable()
export class PlatformCentralAuth implements CanActivate {
    constructor(public router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        if (localStorage.getItem('Auth')) {
            return true;
        } else {
            this.router.navigate(['login'], {queryParams: {return: state.url}});
            return false;
        }
    }
}

