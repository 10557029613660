import { Component, OnInit } from '@angular/core';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {Label} from 'ng2-charts';
import * as d3 from 'd3';

@Component({
  selector: 'app-dashboard2',
  templateUrl: './dashboard2.component.html',
  styleUrls: ['./dashboard2.component.scss']
})
export class Dashboard2Component implements OnInit {

  constructor() { }

  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public barChartLabels: Label[] = ['Economy', 'Environment', 'History', 'Civics', 'Geography'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public chartColors: any[] = [
    {
      backgroundColor: ['#FF7360', '#6FC8CE', '#fccc40', '#FFFCC4', '#B9E8E0']
    }];
  public barChartData: ChartDataSets[] = [
    { data: [80, 78, 89, 81, 56], label: 'Series A' },
  ];


  resultData = [
    {
      title : 'Overall Ranking',
      value : '452',
      logo : '/assets/Framedb1.svg'
    },
    {
      title : 'Overall Ranking',
      value : '452',
      logo : '/assets/Framelogo2.svg'
    },
    {
      title : 'Overall Ranking',
      value : '452',
      logo : '/assets/Questions And Answers 1logo3.svg'
    },
    {
      title : 'Overall Ranking',
      value : '452',
      logo : '/assets/Grouplogo4.svg'
    },
    {
      title : 'Overall Ranking',
      value : '452',
      logo : '/assets/Framelogo5.svg'
    }
  ];

  NCERTDATA = [
    {
      tabName : 'Polity',
      tabIcon : '/assets/Vectorpolity.svg',
      tabContent : [
        {
          title: 'Class XII',
          text1: 'Take Exam 2',
          text1link : '',
          text2 : 'Lorem  simply dummy text of the printing and typesetting industry. Lorem  simply dummy text of the printing  industry.',
          questionNum: '90',
          time : '2 hr'
        },
        {
          title: 'Class XII',
          text1: 'Take Exam 2',
          text1link : '',
          text2 : 'Lorem  simply dummy text of the printing and typesetting industry. Lorem  simply dummy text of the printing  industry.',
          questionNum: '90',
          time : '2 hr'
        }
      ]
    },
    {
      tabName : 'Polity',
      tabIcon : '/assets/Vectorpolity.svg',
      tabContent : [
        {
          title: 'Class XII',
          text1: 'Take Exam 2',
          text1link : '',
          text2 : 'Lorem  simply dummy text of the printing and typesetting industry. Lorem  simply dummy text of the printing  industry.',
          questionNum: '90',
          time : '2 hr'
        }
      ]
    }
  ];

  modulesData = [
    {
      title : 'Module – 1 – NCERT Test Series',
      price: '5000',
      bgcolor: '#2E2C97',
      content: this.NCERTDATA
    },
    {
      title : 'Module – 2 – Reference Books Test Series',
      price: '5000',
      bgcolor: '#9B119E',
      content: this.NCERTDATA
    },
  ];

  tab: any;

  ngOnInit(): void {
    this.tab = 'dashboard';
  }

  changeTab(val) {
    this.tab = val;
  }

}
