import { Component, OnInit } from '@angular/core';
import {LinxService} from '../linx.service';

@Component({
  selector: 'app-mains',
  templateUrl: './mains.component.html',
  styleUrls: ['./mains.component.scss']
})

export class MainsComponent implements OnInit {
  isImgVisible:boolean;
  pdfSrc:any;
  pdf_title:string;
  collapsed:boolean;
  spinner:boolean;
  pdfs=[];

  constructor(public service: LinxService) { }

  ngOnInit(): void {
    this.spinner = true;
    this.isImgVisible = true;
    this.collapsed = false;
    this.getMainsPdfs();
  }

  getMainsPdfs() {
    this.service.getMainsPdfs().subscribe(response => {
      let month = [];
      let pdf = [];
      response['filePaths'].forEach(x => {
        if(this.getTitle(x)) {
          // let temp = x.split('://');
          // const temp1 = 'http://'.concat(temp[1]);
          var pdfObj = {
            title : this.getTitle(x),
            url : this.getPdfUrl(x),
            month :  this.getMonth(x)
          }
          month.push(this.getMonth(x));
          pdf.push(pdfObj);
        }
      });
      var months = [...new Set(month)];
      months.forEach((x) => {
        let obj = {
          month : x,
          pdf : []
        }
        pdf.forEach((y) => {
          if(x === y.month) {
            obj.pdf.push(y)
          }
        });
        this.pdfs.push(obj);
        this.spinner = false;

      });
    }, error => {
      return;
    });
  }

  getPdf(title, url) {
    this.spinner = true;
    this.isImgVisible = false;
    this.pdfSrc = url;
    this.pdf_title = title;
    this.spinner = false;
  }

  collapseList() {
    this.collapsed = ! this.collapsed;
  }

  getTitle(name) {
    let a = name.substring(name.indexOf("/") + 1, name.length);
    return a.substring(a.indexOf("/") + 1, a.length);
  }

  getMonth(name) {
    var str = name.substring(0, name.lastIndexOf("/") + 1);
    return str.substring(str.indexOf("/") + 1, str.length-1);
  }

  getPdfUrl(name) {
    return "https://s3.amazonaws.com/excelonacad/" + encodeURIComponent(name);
  }

  getPdfFromS3(url) {
    this.service.getPdfFromS3(url).subscribe(response => {
      return response;
    }, error => {
      return;
    });
  }
}
