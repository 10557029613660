<div *ngIf="componentLoading">
  <div class="text-center loader">
    <div style="height: 20vh;" class="d-flex justify-content-center align-items-center">
      <mat-spinner></mat-spinner>
    </div>
    <div class="mt-4">
      Your 3-level personalised Performance Report is being generated...
    </div>
  </div>
</div>

<div *ngIf="!componentLoading">
  <app-navbar></app-navbar>
</div>
<br>

<div *ngIf="!componentLoading" style="background: #faf7f7">
  <div class="row">
    <div class="col-md-1 col-lg-1"></div>
    <div class="col-md-10 col-lg-10" style="background: white;border-radius: 5px;margin-top: 1rem;">
      <div *ngIf="completeData" class="row" style="padding: 1rem;align-items: baseline;">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <p>
            <span><img height = "25" src="assets/img/{{completeData['paper']['paperSubject']['icon']}}"/></span> &nbsp;  <span style="font-weight: bold;font-size: 1.2rem;">{{completeData['paper']['title']}}</span> &nbsp; <span style="font-size: 1rem;"> Test Result and Analysis</span>
          </p>
        </div>
		    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 d-flex justify-content-end">
          <button type="button" class="btn btn-info" data-toggle="modal" data-target="#myModal" [ngStyle]="{'display':feedbackSumbitted ? 'none' : 'block'}">Share Feedback</button>
          <button class="download-test-report-button noExport ml-2" id="printPageButton" onclick="window.print();return false;">Print PDF</button>
        </div>
      </div>
      <hr>
	    <div class="legenddiv d-flex justify-content-end align-items-center">
        <div class="legend_label">Legend:</div>
        <div class="legend positive-feedback" style="font-weight:bold;">Doing Good</div>
        <div class="legend moderate-feedback" style="font-weight:bold;">Can do better</div>
        <div class="legend to-improve-feedback" style="font-weight:bold;">Needs improvement</div>
      </div>
      <div *ngIf="completeData" class="row" style="padding: 1rem;">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
          <p style="font-size: 0.8rem"> Dear {{completeData['user']['name']}},</p>
          <p style="font-size: 0.8rem;font-weight: bolder;"> Thank you for taking the test. Based on your performance, our summary of your performance is</p>
        </div>
      </div>
      <div *ngIf="generalStats">
        <div *ngIf="generalStats[0]['ratioFeedback']['label'] === 'Positive Feedback'" class="row" style="padding: 1rem;">
          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-center positive-feedback">
            <p style="font-size: 1.2rem;font-weight: bolder;"> {{generalStats[0]['ratioFeedback']['feedBackText']}} </p>
          </div>
        </div>
        <div *ngIf="generalStats[0]['ratioFeedback']['label'] === 'Moderate Feedback'" class="row" style="padding: 1rem;">
          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-center moderate-feedback">
            <p style="font-size: 1.2rem;font-weight: bolder;"> {{generalStats[0]['ratioFeedback']['feedBackText']}} </p>
          </div>      </div>
        <div *ngIf="generalStats[0]['ratioFeedback']['label'] === 'Improvement Required'" class="row" style="padding: 1rem;">
          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-center to-improve-feedback">
            <p style="font-size: 1.2rem;font-weight: bolder;"> {{generalStats[0]['ratioFeedback']['feedBackText']}} </p>
          </div>
        </div>
        <div class="row" style="padding: 1rem;">
          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
<!--            <p style="font-size: 0.8rem;font-weight: bolder;"> There seems to be few areas that you need to work upon on an urgent basis. Kindly refer detailed feedback section to increase your score.</p>-->
            <p style="font-size: 0.8rem"> Please go through 3 levels of personalised performance feedback where your performance in analysed through multiple dimensions.</p>
          </div>
        </div>
      </div>
      <div *ngIf="subjectGroupedByLabel" class="row" style="padding: 1rem;">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
          <p style="font-size: 0.8rem;font-weight: bolder;"> The test consists of the following Subjects:</p>
        </div>
      </div>
      <div *ngIf="subjectGroupedByLabel" class="row" style="padding-left: 1rem;">
        <div *ngFor="let subject of subjectGroupedByLabel" class="col-md-2 col-lg-2 col-sm-2 col-xs-2 text-center positive-feedback" style="width: 97%;margin: 1%" [ngClass]="{'positive-feedback': subject['ratioFeedback']['label'] === 'Positive Feedback','moderate-feedback': subject['ratioFeedback']['label'] === 'Moderate Feedback', 'to-improve-feedback': subject['ratioFeedback']['label'] === 'Improvement Required'}">
          <p style="font-size: 0.8rem;font-weight: bolder;"> {{subject['label']}}</p>
        </div>
      </div>
      <div *ngIf="knowledgeAreaGroupedByLabel">
        <div class="row" style="padding: 1rem;">
          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
            <p style="font-size: 0.8rem;font-weight: bolder;"> The tests consists of the following Major knowledge areas:</p>
          </div>
        </div>
        <div class="row" style="padding-left: 1rem;">
          <div *ngFor="let area of knowledgeAreaGroupedByLabel" class="col-md-2 col-lg-2 col-sm-2 col-xs-2 text-center positive-feedback" style="width: 97%;margin: 1%" [ngClass]="{'positive-feedback': area['ratioFeedback']['label'] === 'Positive Feedback','moderate-feedback': area['ratioFeedback']['label'] === 'Moderate Feedback', 'to-improve-feedback': area['ratioFeedback']['label'] === 'Improvement Required'}">
            <p style="font-size: 0.8rem;font-weight: bolder;"> {{area['label']}}</p>
          </div>
        </div>
      </div>
      <div class="row" style="padding: 1rem;">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
          <p style="font-size: 1.2rem;font-weight: bolder;color: #2E2C97"> Level-1 Feedback | High Level Statistics </p>
        </div>
      </div>
      <div style="background: #f7f7f7;padding-bottom: 1rem;">
        <div class="row" style="padding: 1rem;">
          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
            <p style="font-size: 0.9rem;font-weight: bolder;color: #4976F2"> Exam 1 Test Result And Analysis </p>
          </div>
        </div>
        <div *ngIf="generalStats" style="padding-left: 1rem">
          <div class="flex-box">
            <div class="flex-item">
              <div class="outer-circle">
                <div class="total-marks">
                  <div style="display: flex; flex-direction: row;justify-content: center">
                    <div class="marks-secured-font">{{generalStats[0]['score'].toFixed(2)}}</div> <div class="marks-slash">/</div> <div class="marks-total-font">{{maxscore}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex-item">
              <div class="top-five-background correct" style="border-radius: 10px;">
                <div class="overall-status-tiles">
                  <img height="70" width="55" src="assets/img/Correct.png" class="py-3 px-2" />
<!--                  <p style="margin-bottom: 0px;color: white;">{{correct}}</p>-->
                  <p style="margin-bottom: 0px;color: white;">{{generalStats[0]['numCorrect']}}</p>
                  <p style="color: white;">Correct</p>
                </div>
              </div>
            </div>
            <div class="flex-item">
              <div class="top-five-background incorrect" style="border-radius: 10px;">
                <div class="overall-status-tiles">
                  <img height="70" width="55" src="assets/img/InCorrect.png" class="py-3 px-2" />
<!--                  <p style="margin-bottom: 0px;color: white;">{{inCorrect}}</p>-->
                  <p style="margin-bottom: 0px;color: white;">{{generalStats[0]['numInCorrect']}}</p>
                  <p style="color: white;">Incorrect</p>
                </div>
              </div>
            </div>
            <div class="flex-item">
              <div class="top-five-background not-attempted" style="border-radius: 10px;">
                <div class="overall-status-tiles">
                  <img height="70" width="55" src="assets/img/Not attempted.png" class="py-3 px-2" />
                  <p style="margin-bottom: 0px;color: white;">{{notAttempted}}</p>
                  <p style="color: white;">Not Attempted</p>
                </div>
              </div>
            </div>
            <div class="overall-status">
              <div style="width: 350px;">
                <span><img style="height: 2rem;" src="assets/img/Time Spend.png"/> <span style="font-size: 1rem"> Time Spent : </span><span style="font-weight: bolder;font-size: 1.2rem"> {{totalTimeSpent}} </span></span><hr>
              </div>
              <div>
                <span><img style="height: 2rem;" src="assets/img/question 3.png"/> <span style="font-size: 1rem"> Avg time/question : </span> <span style="font-weight: bolder;font-size: 1.2rem"> {{averageTimeTaken}} </span></span><hr>
              </div>
              <div *ngIf="top5MaxTimeQuestions.length > 0">
                <span><img style="height: 2rem;" src="assets/img/question.png"/> <span style="font-size: 1rem"> Max time/question : </span> <span style="font-weight: bolder;font-size: 1.2rem"> {{msToTime(top5MaxTimeQuestions[0]['timeTaken'])}} </span></span>
              </div>
            </div>
          </div>
        </div>
        <div class="row" style="padding: 1rem;">
          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
            <p style="font-size: 1.2rem;font-weight: bolder;color: #2E2C97"> Performance across confidence levels</p>
          </div>
        </div>
        <div class="row" style="padding: 1rem;background: white;margin-left: 1rem;margin-right: 1rem;border-radius: 10px;">
<!--          <div style="padding: 1rem">-->
          <div *ngFor="let confStats of this.confidenceStatsChartData" class="col-lg-3 col-md-3 col-sm-6 col-xs-12 text-center">
            <p><span style="font-weight: 900;"> {{confStats['title']}} </span></p>
            <canvas *ngIf="confStats['remark']" baseChart
                    [chartType]="confStats['type']"
                    [data]="confStats['data']"
                    [options]="confStats['options']"
                    [colors]="chartColors">
            </canvas><br>
            <p *ngIf="confStats['remark']"> Remarks : <span style="font-weight: bolder;">{{confStats['remark']}}</span></p>
            <div *ngIf="!confStats['remark']">
              <img src="/assets/img/donutForNotFound.svg"/>
              <br>
              <p style="margin-top: 1.2rem;"> Remarks : <span style="font-weight: bolder;">There are no blind spots under this category</span></p>
            </div>
            <br>
          </div>
<!--          </div>-->
          <div class="row" style="padding: 0.6rem;width: 100%">
            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
              <p style="font-size: 0.9rem;font-weight: bolder;"> Confidence Definition</p>
            </div>
          </div>
          <div class="row" style="padding: 1rem;">
            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
              <p style="font-size: 0.9rem;font-weight: bolder;"> <span style="font-weight: 900"> 100% </span> - Questions where you have chosen the option directly without striking off any other options.</p>
              <p style="font-size: 0.9rem;font-weight: bolder;"> <span style="font-weight: 900;color: #5B4688"> 50% </span> - Questions where you have struck off 2 options.</p>
              <p style="font-size: 0.9rem;font-weight: bolder;"> <span style="font-weight: 900;color: #B06833"> 33% </span> - Questions where you have struck off 1</p>
            </div>
          </div>
          <div style="width: 100%" class="row justify-content-center">
            <div style="background: #EFF5FF;border-radius: 10px;width: 70%;">
              <ul class="status">
                <li class="status-list">
                  <div style="background: #0ABD0A;" class="status-icon"></div><span> Correct </span>
                </li>
                <li class="status-list">
                  <div style="background: #FFAB48;" class="status-icon"></div><span> Incorrect </span>
                </li>
<!--                <li class="status-list">-->
<!--                  <div style="background: #817D7D;" class="status-icon"></div><span> Un-Attempted </span>-->
<!--                </li>-->
              </ul>
            </div>
          </div>
        </div>
        <div class="row" style="padding: 1rem;">
          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
            <p style="font-size: 1.2rem;font-weight: bolder;color: #2E2C97"> Difficulty Analysis</p>
          </div>
        </div>
        <div class="row justify-content-center" style="padding: 1rem;background: white;margin-left: 1rem;margin-right: 1rem;border-radius: 10px;">
          <div *ngFor="let difficultyStats of this.difficultStatsChartData" class="col-lg-4 col-md-4 col-sm-6 col-xs-12 text-center">
            <p><span style="font-weight: 900;"> {{difficultyStats['title']}} </span></p>
            <p><span style="font-weight: 900;"> Total Question : {{difficultyStats['totalQuestions']}} </span></p>
            <canvas baseChart
                    [chartType]="difficultyStats['type']"
                    [data]="difficultyStats['data']"
                    [options]="difficultyStats['options']"
                    [colors]="chartColors">
            </canvas><br>
            <p *ngIf="difficultyStats['remark']"> Remarks : <span style="font-weight: bolder;">{{difficultyStats['remark']}}</span></p>
            <br>
          </div>
          <br>
          <div style="background: #EFF5FF;border-radius: 10px;width: 70%;">
            <ul class="status-non-confidence">
              <li class="status-list">
                <div style="background: #0ABD0A;" class="status-icon"></div><span> Correct </span>
              </li>
              <li class="status-list">
                <div style="background: #FFAB48;" class="status-icon"></div><span> Incorrect </span>
              </li>
              <li class="status-list">
                <div style="background: #817D7D;" class="status-icon"></div><span> Un-Attempted </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="row" style="padding: 1rem;">
          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
            <p style="font-size: 1.2rem;font-weight: bolder;color: #2E2C97"> Subject Analysis</p>
          </div>
        </div>
        <div class="row justify-content-center" style="padding: 1rem;background: white;margin-left: 1rem;margin-right: 1rem;border-radius: 10px;">
          <div *ngFor="let subjectStats of this.subjectLevelStatsChartData" class="col-lg-4 col-md-4 col-sm-6 col-xs-12 text-center">
            <p><span style="font-weight: 900;"> {{subjectStats['title']}} </span></p>
            <p><span style="font-weight: 900;"> Total Question : {{subjectStats['totalQuestions']}} </span></p>
            <canvas baseChart
                    [chartType]="subjectStats['type']"
                    [data]="subjectStats['data']"
                    [options]="subjectStats['options']"
                    [colors]="chartColors">
            </canvas><br>
            <p *ngIf="subjectStats['remark']"> Remarks : <span style="font-weight: bolder;">{{subjectStats['remark']}}</span></p>
          </div>
          <br>
          <div style="background: #EFF5FF;border-radius: 10px;width: 70%">
            <ul class="status-non-confidence">
              <li class="status-list">
                <div style="background: #0ABD0A;" class="status-icon"></div><span> Correct </span>
              </li>
              <li class="status-list">
                <div style="background: #FFAB48;" class="status-icon"></div><span> Incorrect </span>
              </li>
              <li class="status-list">
                <div style="background: #817D7D;" class="status-icon"></div><span> Un-Attempted </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row" style="padding: 1rem;">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
          <p style="font-size: 1.2rem;font-weight: bolder;color: #2E2C97"> Level-2 Feedback | High Level Synthesis</p>
        </div>
      </div>
      <div class="row" style="padding: 1rem;">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
          <p style="font-size: 0.9rem;font-weight: bolder;color: #4976F2"> (A) Blind Spot Analysis </p>
        </div>
      </div>
      <div class="row" style="padding: 1rem;">
        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
          <div class="d-flex mx-2 my-3 col-xs-12 col-xm-12" style="background: white; border-radius: 10px;border: 3px solid #3161E2;width: 100%;padding: 1rem;">
            <div class="background">
              <div style="position: relative;top: 24%">
                <img height="130" width="120" src="assets/img/Blind Spot.png" class="py-3 px-2" />
              </div>
            </div>
            <div style="margin-left: 1rem;" class="d-flex flex-column justify-content-center py-2">
              <div class="pb-2" style="font-size: 1.2rem;font-weight: bold">
                What is a Blind Spot?
              </div>
              <div style="font-size: 1rem;line-height: 2rem;">
                A blind spot is an area where you feel you are confident about your knowledge, but you still choose the incorrect option
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
          <div class="d-flex mx-2 my-3 col-xs-12 col-xm-12" style="background: white; border-radius: 10px;border: 3px solid #FA5521;width: 100%;padding: 1rem;">
            <div class="background">
              <div style="position: relative;top: 24%">
                <img height="130" width="120" src="assets/img/Reasons for Blind Spot.png" class="py-3 px-2" />
              </div>
            </div>
            <div style="margin-left: 1rem;" class="d-flex flex-column justify-content-center px-2 py-2">
              <div class="pb-2" style="font-size: 1.2rem;font-weight: bold">
                Reasons for Blind Spot
              </div>
              <div style="margin-top: 0.5rem;">
                <ul style="list-style-type:none;padding-left: 0px;">
                  <li class="li-placement">
                    <img style="height: 2rem;" src="assets/img/Incorrect Reading of Question.png"/> <span style="font-size: 1rem"> Incorrect Reading of Question </span>
                  </li>
                  <li class="li-placement">
                    <img style="height: 2rem;" src="assets/img/Conceptual Error.png"/> <span style="font-size: 1rem"> Conceptual Error </span>
                  </li>
                  <li class="li-placement">
                    <img style="height: 2rem;" src="assets/img/Overconfidence.png"/> <span style="font-size: 1rem"> Overconfidence </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" style="padding: 1rem">
        <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
          <div class="row">
            <div style="text-align: -webkit-center" class="col-md-3 col-lg-3 col-sm-3 col-xs-3">
              <div class="circle-blue">
                <div style="padding-top: 21px;" class="content-inside-circle">
                  <p>{{incorrectQuestionsWith100PercentConfidence}}</p>
                </div>
              </div>
            </div>
            <div class="content col-md-9 col-lg-9 col-sm-9 col-xs-9">
              <p class="level-2-suggestion"> Questions where an incorrect option was chosen with 100% confidence level. <span style="color: #3161E2; font-weight: bold"> This could be your Blind Spot. </span> </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
          <div class="row">
            <div style="text-align: -webkit-center" class="col-md-3 col-lg-3 col-sm-3 col-xs-3">
              <div class="circle-blue">
                <div style="padding-top: 21px;" class="content-inside-circle">
                  <p>{{correctQuestionsStuckedOff}}</p>
                </div>
              </div>
            </div>
            <div class="content col-md-9 col-lg-9 col-sm-9 col-xs-9">
              <p class="level-2-suggestion"> Questions where the correct option has been struck off. <span style="color: #3161E2; font-weight: bold"> This could be your Blind Spot. </span> </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
          <div class="row">
            <div style="text-align: -webkit-center" class="col-md-3 col-lg-3 col-sm-3 col-xs-3">
              <div class="circle-orange">
                <div style="padding-top: 21px;" class="content-inside-circle">
                  <p>{{numberOfBlindSpotQuestionsInMaxKnowledgeArea}}</p>
                </div>
              </div>
            </div>
            <div class="content col-md-9 col-lg-9 col-sm-9 col-xs-9">
              <p class="level-2-suggestion"> Blind Spot Questions in  <span style="color: #F57851; font-weight: bold"> {{maxBlindSpotsKonwledgeArea}} Knowledge Area. </span> </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row" style="padding: 1rem;">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
          <p style="font-size: 0.9rem;font-weight: bolder;color: #4976F2"> (B) Strong Areas </p>
        </div>
      </div>
      <div *ngIf="strongAreas.length === 0" class="row" style="padding: 1rem;">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-center positive-feedback">
          <p style="font-size: 0.8rem;font-weight: bolder;"> No areas for this category</p>
        </div>
      </div>
      <div *ngIf="strongAreas.length > 0" class="row" style="padding: 1rem;">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-center positive-feedback">
          <p style="font-size: 0.8rem;font-weight: bolder;"> {{strongAreas.toString()}}</p>
        </div>
      </div>
      <div class="row" style="padding: 1rem;">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
          <p style="font-size: 0.9rem;font-weight: bolder;color: #4976F2"> (C) Doing ok </p>
        </div>
      </div>
      <div *ngIf="okAreas && okAreas.length > 0" class="row" style="padding: 1rem;">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-center moderate-feedback">
          <p style="font-size: 0.8rem;font-weight: bolder;"> {{okAreas.toString()}}</p>
        </div>
      </div>
      <div *ngIf="okAreas && okAreas.length === 0" class="row" style="padding: 1rem;">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-center moderate-feedback">
          <p style="font-size: 0.8rem;font-weight: bolder;"> No areas for this category</p>
        </div>
      </div>
      <div class="row" style="padding: 1rem;">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
          <p style="font-size: 0.9rem;font-weight: bolder;color: #4976F2"> (D) Areas of Improvement </p>
        </div>
      </div>
      <div *ngIf="areaNeedsImprovement && areaNeedsImprovement.length === 0" class="row" style="padding: 1rem;">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-center to-improve-feedback">
          <p style="font-size: 0.8rem;font-weight: bolder;">No areas for this category</p>
        </div>
      </div>
      <div *ngIf="areaNeedsImprovement && areaNeedsImprovement.length > 0" class="row" style="padding: 1rem;">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-center to-improve-feedback">
          <p style="font-size: 0.8rem;font-weight: bolder;">{{areaNeedsImprovement.toString()}}</p>
        </div>
      </div>
      <div class="row" style="padding: 1rem;">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
          <p style="font-size: 0.9rem;font-weight: bolder;color: #4976F2"> (E) Time Taken </p>
        </div>
      </div>
      <div class="row" style="padding: 1rem;">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
          <p style="font-size: 0.9rem;font-weight: bolder;color: black"> Top 5 questions where you have taken the maximum time are :</p>
        </div>
      </div>
      <div class="row" style="padding: 1rem;">
        <div *ngFor="let question of top5MaxTimeQuestions;let i = index" class="col-md-4 col-lg-4 col-sm-12 col-xs-12">
          <div class="d-flex mx-2 my-3 col-xs-12 col-xm-12" [ngClass]="{'incorrect-background': question['correct'] === 'false', 'correct-background': question['correct'] === 'true'}" style="background: #FFFAF0; border-radius: 10px;width: 100%;padding: 1rem;">
            <div class="top-five-background" [ngClass]="{'incorrect': question['correct'] === 'false', 'correct': question['correct'] === 'true'}" style="border-radius: 10px;">
              <div style="position: relative;top: 2%;width: 85px;text-align: center;">
                <img *ngIf="question['correct'] === 'false'" height="70" width="55" src="assets/img/InCorrect.png" class="py-3 px-2" />
                <img *ngIf="question['correct'] === 'true'" height="70" width="55" src="assets/img/Correct.png" class="py-3 px-2" />
                <p style="margin-bottom: 0px;color: white;">{{question['sortOrder']}}</p>
                <p style="color: white;">Question</p>
              </div>
            </div>
            <div style="margin-left: 1rem;" class="d-flex flex-column justify-content-center py-2">
              <div class="pb-2" style="font-size: 0.9rem;">
                Subject : <span style="font-weight: 900">{{question['question']['subject']}}</span>
              </div>
              <div class="pb-2" style="font-size: 0.9rem;">
                Time Taken : <span style="font-weight: 900">{{msToTime(question['timeTaken'])}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" style="padding: 1rem;">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
          <p style="font-size: 1.2rem;font-weight: bolder;color: #2E2C97"> Relative Performance</p>
        </div>
      </div>
      <div class="row justify-content-center" style="padding: 1rem;">
        <div class="col-lg-9 col-md-12 col-xs-12 col-sm-12 table-responsive">
          <table class="table table-sm">
            <thead>
              <tr class="text-center">
                <th scope="col">Question no</th>
                <th scope="col">Your performance</th>
                <th scope="col">% test takers who got it right</th>
              </tr>
            </thead>
            <tbody *ngFor="let data of relativePerformanceData">
              <tr>
                <th class="text-center" scope="row">{{data.sortOrder}}</th>
                <td class="text-center">{{fetchRelativePerformance(data)}}</td>
                <td class="text-center">{{fetchRelativePercentage(data)}}%</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div *ngIf="completeData">
        <div class="row" style="padding: 1rem;">
          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
            <p style="font-size: 1.2rem;font-weight: bolder;color: #2E2C97"> Level-3 Feedback | Detailed Synthesis.</p>
          </div>
        </div>
<!--        <div class="row" *ngFor="let data of completeData['userAnswers']; let i = index" style="padding: 1rem">-->
<!--          <div class="row" *ngIf="data['correct'] === 'true'" style="background: black; width: 100%;border-top-left-radius: 5px;border-bottom-left-radius: 5px;margin-left: 0.3rem;">-->
<!--            <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="background: #48BB48;color: white;padding: 12px;border-radius: 5px;">-->
<!--              &lt;!&ndash;              Question {{data['sortOrder']}} Correct&ndash;&gt;-->
<!--              Question {{i + 1}} Correct-->
<!--            </div>-->
<!--            <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">-->
<!--              Category: {{data['question']['levelOfToughness']['toString']}}-->
<!--            </div>-->
<!--            <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">-->
<!--              Time spent : {{msToTime(data['timeTaken'])}}-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="row" *ngIf="data['correct'] === 'false' && data['answered'] === 'true'" style="background: black; width: 100%;border-top-left-radius: 5px;border-bottom-left-radius: 5px;margin-left: 0.3rem;">-->
<!--            <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="background: #FB3737;color: white;padding: 12px;border-radius: 5px;">-->
<!--              &lt;!&ndash;              Question {{data['sortOrder']}} Correct&ndash;&gt;-->
<!--              Question {{i + 1}} Incorrect-->
<!--            </div>-->
<!--            <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">-->
<!--              Category: {{data['question']['levelOfToughness']['toString']}}-->
<!--            </div>-->
<!--            <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">-->
<!--              Time spent : {{msToTime(data['timeTaken'])}}-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="row" *ngIf="data['correct'] === 'false' && data['answered'] === 'false'" style="background: black; width: 100%;border-top-left-radius: 5px;border-bottom-left-radius: 5px;margin-left: 0.3rem;">-->
<!--            <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="background: #817D7D;color: white;padding: 12px;border-radius: 5px;">-->
<!--              &lt;!&ndash;              Question {{data['sortOrder']}} Correct&ndash;&gt;-->
<!--              Question {{i + 1}} Un-attempted-->
<!--            </div>-->
<!--            <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">-->
<!--              Category: {{data['question']['levelOfToughness']['toString']}}-->
<!--            </div>-->
<!--            <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">-->
<!--              Time spent : {{msToTime(data['timeTaken'])}}-->
<!--            </div>-->
<!--          </div>-->
<!--          &lt;!&ndash;          <div class="row" *ngIf="data['correct'] !== 'true' && data['answered'] === 'true'" style="background: black; width: 100%;border-top-left-radius: 5px;border-bottom-left-radius: 5px;margin-left: 0.3rem;">&ndash;&gt;-->
<!--          &lt;!&ndash;            <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="background: #FB3737;color: white;padding: 12px;border-radius: 5px;">&ndash;&gt;-->
<!--          &lt;!&ndash;&lt;!&ndash;              Question {{data['sortOrder']}} Incorrect&ndash;&gt;&ndash;&gt;-->
<!--          &lt;!&ndash;              Question {{i + 1}} Incorrect&ndash;&gt;-->
<!--          &lt;!&ndash;            </div>&ndash;&gt;-->
<!--          &lt;!&ndash;            <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">&ndash;&gt;-->
<!--          &lt;!&ndash;              <strong>Category: {{data['question']['levelOfToughness']['toString']}}</strong>&ndash;&gt;-->
<!--          &lt;!&ndash;            </div>&ndash;&gt;-->
<!--          &lt;!&ndash;            <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">&ndash;&gt;-->
<!--          &lt;!&ndash;              <p>Time spent : {{msToTime(data['timeTaken'])}}</p>&ndash;&gt;-->
<!--          &lt;!&ndash;            </div>&ndash;&gt;-->
<!--          &lt;!&ndash;          </div>&ndash;&gt;-->
<!--          &lt;!&ndash;        <div class="row" *ngIf="data['correct'] !== 'true' && data['answered'] === 'false'" style="background: black; width: 100%;border-top-left-radius: 5px;border-bottom-left-radius: 5px;margin-left: 0.3rem;">&ndash;&gt;-->
<!--          &lt;!&ndash;          <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="background: #817D7D;color: white;padding: 12px;border-radius: 5px;">&ndash;&gt;-->
<!--          &lt;!&ndash;            &lt;!&ndash;              Question {{data['sortOrder']}} Incorrect&ndash;&gt;&ndash;&gt;-->
<!--          &lt;!&ndash;            Question {{i + 1}} Un-attempted&ndash;&gt;-->
<!--          &lt;!&ndash;          </div>&ndash;&gt;-->
<!--          &lt;!&ndash;          <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">&ndash;&gt;-->
<!--          &lt;!&ndash;            <strong>Category: {{data['question']['levelOfToughness']['toString']}}</strong>&ndash;&gt;-->
<!--          &lt;!&ndash;          </div>&ndash;&gt;-->
<!--          &lt;!&ndash;          <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">&ndash;&gt;-->
<!--          &lt;!&ndash;            <p>Time spent : {{msToTime(data['timeTaken'])}}</p>&ndash;&gt;-->
<!--          &lt;!&ndash;          </div>&ndash;&gt;-->
<!--          &lt;!&ndash;        </div>&ndash;&gt;-->
<!--          <div class="row" style="margin: 0.6rem">-->
<!--            <div style="border:1px solid #E1DBDB;border-radius: 5px;padding: 1rem;margin: 0.3rem" class="col-lg-5 col-md-5">-->
<!--              <div style="float: left">-->
<!--                <img src="assets/question.svg" style="height: 1.4rem;" />-->
<!--              </div>-->
<!--              <div class="col-lg-12 col-md-12" style="margin-left: 1rem;">-->
<!--                {{data['question']['title']}}-->
<!--              </div><br>-->
<!--              &lt;!&ndash;            <div class="row">&ndash;&gt;-->
<!--              <div style="float: left">-->
<!--                <img src="assets/answer.svg" style="height: 1.4rem;" />-->
<!--              </div>-->
<!--              <ol style="list-style-type: none;margin-left: 1rem;padding-left: 1rem;">-->
<!--                &lt;!&ndash;                <li *ngFor="let answer of data['question']['questionOptions']">&ndash;&gt;-->
<!--                <li style="margin-bottom: 0.8rem;" *ngFor="let answer of data['answeredOptions']">-->
<!--                  &lt;!&ndash;                  <div class="circle"></div><span><p *ngIf="answer['correctAnswer'] === 'true'" style="color: green"><strong>{{answer['answerText']}}</strong></p></span>&ndash;&gt;-->
<!--                  &lt;!&ndash;                  <div class="circle"></div><span><p *ngIf="answer['correctAnswer'] !== 'true'">{{answer['answerText']}}</p></span>&ndash;&gt;-->
<!--                  <div *ngIf="answer['selected'] === 'true'">-->
<!--                    <span *ngIf="answer['answerOption']['correctAnswer'] === 'true'"><div class="correct-option-circle"></div><span style="margin-left: 0.4rem; color: #0478ED">{{answer['answerOption']['answerText']}}<span style="color: #02C26A"><strong> (Correctly Chosen) </strong></span></span></span>-->
<!--                    <span *ngIf="answer['answerOption']['correctAnswer'] !== 'true'"><div class="incorrect-option-circle"></div><span style="margin-left: 0.4rem;color: #FB3737">{{answer['answerOption']['answerText']}}<span><strong> (Incorrectly Chosen) </strong></span></span></span>-->
<!--                  </div>-->
<!--                  <div *ngIf="answer['selected'] !== 'true' && answer['excluded'] === 'false'">-->
<!--                    <span *ngIf="answer['answerOption']['correctAnswer'] === 'true'"><div class="correct-option-circle"></div><span style="margin-left: 0.4rem; color: #0478ED">{{answer['answerOption']['answerText']}}<span style="color: #02C26A"><strong> (Correct option not chosen) </strong></span></span></span>-->
<!--                    <span *ngIf="answer['answerOption']['correctAnswer'] !== 'true'"><div class="circle"></div><span style="margin-left: 0.4rem">{{answer['answerOption']['answerText']}}</span></span>-->
<!--                  </div>-->
<!--                  <div *ngIf="answer['selected'] !== 'true' && answer['excluded'] === 'true'">-->
<!--                    <span *ngIf="answer['answerOption']['correctAnswer'] === 'true'"><div class="correct-option-circle"></div><span style="margin-left: 0.4rem; color: #0478ED"><s>{{answer['answerOption']['answerText']}}</s><span style="color: #02C26A"><strong> (Correct option Struck Off) </strong></span></span></span>-->
<!--                    <span *ngIf="answer['answerOption']['correctAnswer'] !== 'true'"><div class="circle"></div><span style="margin-left: 0.4rem"><s>{{answer['answerOption']['answerText']}}</s></span></span>-->
<!--                  </div>-->
<!--                </li>-->
<!--              </ol>-->
<!--              &lt;!&ndash;            </div>&ndash;&gt;-->
<!--              <div class="row">-->
<!--                <div class="col-lg-12 col-md-12">-->
<!--                  &lt;!&ndash;              <div class="col-lg-6 col-md-6">&ndash;&gt;-->
<!--                  &lt;!&ndash;                <button class="blind" [class.not]="data['blindGuess'] === 'false' || data['blindGuess'] === undefined">Blind guess</button>&ndash;&gt;-->
<!--                  &lt;!&ndash;                <button class="review" [class.not]="data['toReview'] === 'false'">Review Later</button>&ndash;&gt;-->
<!--                  <button [ngClass]="{'button-style-toReview': data['toReview'] === 'true', 'button-style-toReview-default': data['toReview'] !== 'true'}" style="font-weight: bold">-->
<!--                    Review Later-->
<!--                  </button>-->
<!--                  <button [ngClass]="{'button-style-blindGuess': data['blindGuess'] === 'true', 'button-style-blindGuess-default': data['blindGuess'] !== 'true'}" style="font-weight: bold">-->
<!--                    Blind Guess-->
<!--                  </button>-->
<!--                  &lt;!&ndash;              </div>&ndash;&gt;-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div style="border: 1px solid #EFEAEA;background: #FBFBFB;padding: 1rem;margin: 0.3rem;border-radius: 5px;" class="col-lg-6 col-md-6">-->
<!--              <h4><strong>Explanation</strong></h4>-->
<!--              <p [innerHTML]="data['question']['answerFeedback']"></p>-->
<!--              <h4><strong>Suggested Reading</strong></h4>-->
<!--              <a style="text-decoration: underline !important;overflow-wrap: anywhere" href="{{data['question']['suggestedReading']}}" target="_blank">{{data['question']['suggestedReading']}}</a>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <div class="row" style="padding: 1rem;">
          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-center positive-feedback">
            <p style="font-size: 1.2rem;font-weight: bolder;"> What went well </p>
          </div>
        </div>
        <div class="row" style="padding: 1rem;">
          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
            <p style="font-size: 1.2rem;font-weight: bolder;color: #4976F2"> Correct Questions </p>
          </div>
        </div>
        <div>
          <div class="row" *ngFor="let data of whatWentWell; let i = index" style="padding: 1rem">
            <div class="row" *ngIf="data['correct'] === 'true'" style="background: black; width: 100%;border-top-left-radius: 5px;border-bottom-left-radius: 5px;margin-left: 0.3rem;">
              <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="background: #48BB48;color: white;padding: 12px;border-radius: 5px;">
                <!--              Question {{data['sortOrder']}} Correct-->
                Question {{data['sortOrder']}} Correct
              </div>
              <div *ngIf="data['question'].hasOwnProperty('levelOfToughness')" class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">
                Category: {{data['question']['levelOfToughness']['toString']}}
              </div>
              <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">
                Time spent : {{msToTime(data['timeTaken'])}}
              </div>
            </div>
            <div class="row" *ngIf="data['correct'] === 'false' && data['answered'] === 'true'" style="background: black; width: 100%;border-top-left-radius: 5px;border-bottom-left-radius: 5px;margin-left: 0.3rem;">
              <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="background: #FB3737;color: white;padding: 12px;border-radius: 5px;">
                <!--              Question {{data['sortOrder']}} Correct-->
                Question {{data['sortOrder']}} Incorrect
              </div>
              <div *ngIf="data['question'].hasOwnProperty('levelOfToughness')" class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">
                Category: {{data['question']['levelOfToughness']['toString']}}
              </div>
              <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">
                Time spent : {{msToTime(data['timeTaken'])}}
              </div>
            </div>
            <div class="row" *ngIf="data['correct'] === 'false' && data['answered'] === 'false'" style="background: black; width: 100%;border-top-left-radius: 5px;border-bottom-left-radius: 5px;margin-left: 0.3rem;">
              <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="background: #817D7D;color: white;padding: 12px;border-radius: 5px;">
                <!--              Question {{data['sortOrder']}} Correct-->
                Question {{data['sortOrder']}} Un-attempted
              </div>
              <div *ngIf="data['question'].hasOwnProperty('levelOfToughness')" class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">
                Category: {{data['question']['levelOfToughness']['toString']}}
              </div>
              <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">
                Time spent : {{msToTime(data['timeTaken'])}}
              </div>
            </div>
            <!--          <div class="row" *ngIf="data['correct'] !== 'true' && data['answered'] === 'true'" style="background: black; width: 100%;border-top-left-radius: 5px;border-bottom-left-radius: 5px;margin-left: 0.3rem;">-->
            <!--            <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="background: #FB3737;color: white;padding: 12px;border-radius: 5px;">-->
            <!--&lt;!&ndash;              Question {{data['sortOrder']}} Incorrect&ndash;&gt;-->
            <!--              Question {{i + 1}} Incorrect-->
            <!--            </div>-->
            <!--            <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">-->
            <!--              <strong>Category: {{data['question']['levelOfToughness']['toString']}}</strong>-->
            <!--            </div>-->
            <!--            <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">-->
            <!--              <p>Time spent : {{msToTime(data['timeTaken'])}}</p>-->
            <!--            </div>-->
            <!--          </div>-->
            <!--        <div class="row" *ngIf="data['correct'] !== 'true' && data['answered'] === 'false'" style="background: black; width: 100%;border-top-left-radius: 5px;border-bottom-left-radius: 5px;margin-left: 0.3rem;">-->
            <!--          <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="background: #817D7D;color: white;padding: 12px;border-radius: 5px;">-->
            <!--            &lt;!&ndash;              Question {{data['sortOrder']}} Incorrect&ndash;&gt;-->
            <!--            Question {{i + 1}} Un-attempted-->
            <!--          </div>-->
            <!--          <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">-->
            <!--            <strong>Category: {{data['question']['levelOfToughness']['toString']}}</strong>-->
            <!--          </div>-->
            <!--          <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">-->
            <!--            <p>Time spent : {{msToTime(data['timeTaken'])}}</p>-->
            <!--          </div>-->
            <!--        </div>-->
            <div class="row" style="margin: 0.6rem">
              <div style="border:1px solid #E1DBDB;border-radius: 5px;padding: 1rem;margin: 0.3rem" class="col-lg-5 col-md-5">
                <div style="float: left">
                  <img src="assets/question.svg" style="height: 1.4rem;" />
                </div>
                <div class="col-lg-12 col-md-12" [innerHTML]="data['question']['questionText'] | safeHtml" style="margin-left: 1rem;">
<!--                  {{data['question']['title']}}-->
                </div><br>
                <!--            <div class="row">-->
                <div style="float: left">
                  <img src="assets/answer.svg" style="height: 1.4rem;" />
                </div>
                <ol style="list-style-type: none;margin-left: 1rem;padding-left: 1rem;">
                  <!--                <li *ngFor="let answer of data['question']['questionOptions']">-->
                  <li style="margin-bottom: 0.8rem;" *ngFor="let answer of data['answeredOptions']">
                    <!--                  <div class="circle"></div><span><p *ngIf="answer['correctAnswer'] === 'true'" style="color: green"><strong>{{answer['answerText']}}</strong></p></span>-->
                    <!--                  <div class="circle"></div><span><p *ngIf="answer['correctAnswer'] !== 'true'">{{answer['answerText']}}</p></span>-->
                    <div *ngIf="answer['selected'] === 'true'">
                      <span *ngIf="answer['answerOption']['correctAnswer'] === 'true'"><div class="correct-option-circle"></div><span style="margin-left: 0.4rem; color: #0478ED"><span [innerHTML]="answer['answerOption']['answerText'] | safeHtml"></span> <span style="color: #02C26A"><strong> (Correctly Chosen) </strong></span></span></span>
                      <span *ngIf="answer['answerOption']['correctAnswer'] !== 'true'"><div class="incorrect-option-circle"></div><span style="margin-left: 0.4rem;color: #FB3737"><span [innerHTML]="answer['answerOption']['answerText'] | safeHtml"></span><span><strong> (Incorrectly Chosen) </strong></span></span></span>
                    </div>
                    <div *ngIf="answer['selected'] !== 'true' && answer['excluded'] === 'false'">
                      <span *ngIf="answer['answerOption']['correctAnswer'] === 'true'"><div class="correct-option-circle"></div><span style="margin-left: 0.4rem; color: #0478ED"><span [innerHTML]="answer['answerOption']['answerText'] | safeHtml"></span><span style="color: #02C26A"><strong> (Correct option not chosen) </strong></span></span></span>
                      <span *ngIf="answer['answerOption']['correctAnswer'] !== 'true'"><div class="circle"></div><span style="margin-left: 0.4rem"><span [innerHTML]="answer['answerOption']['answerText'] | safeHtml"></span></span></span>
                    </div>
                    <div *ngIf="answer['selected'] !== 'true' && answer['excluded'] === 'true'">
                      <span *ngIf="answer['answerOption']['correctAnswer'] === 'true'"><div class="correct-option-circle"></div><span style="margin-left: 0.4rem; color: #0478ED"><s><span [innerHTML]="answer['answerOption']['answerText'] | safeHtml"></span></s><span style="color: #02C26A"><strong> (Correct option Struck Off) </strong></span></span></span>
                      <span *ngIf="answer['answerOption']['correctAnswer'] !== 'true'"><div class="circle"></div><span style="margin-left: 0.4rem"><s><span [innerHTML]="answer['answerOption']['answerText'] | safeHtml"></span></s></span></span>
                    </div>
                  </li>
                </ol>
                <!--            </div>-->
                <div class="row">
                  <div class="col-lg-12 col-md-12">
                    <!--              <div class="col-lg-6 col-md-6">-->
                    <!--                <button class="blind" [class.not]="data['blindGuess'] === 'false' || data['blindGuess'] === undefined">Blind guess</button>-->
                    <!--                <button class="review" [class.not]="data['toReview'] === 'false'">Review Later</button>-->
                    <button [ngClass]="{'button-style-toReview': data['toReview'] === 'true', 'button-style-toReview-default': data['toReview'] !== 'true'}" style="font-weight: bold">
                      Review Later
                    </button>
                    <button [ngClass]="{'button-style-blindGuess': data['blindGuess'] === 'true', 'button-style-blindGuess-default': data['blindGuess'] !== 'true'}" style="font-weight: bold">
                      Blind Guess
                    </button>
                    <!--              </div>-->
                  </div>
                </div>
              </div>
              <div style="border: 1px solid #EFEAEA;background: #FBFBFB;padding: 1rem;margin: 0.3rem;border-radius: 5px;" class="col-lg-6 col-md-6">
                <h4><strong>Explanation</strong></h4>
                <p [innerHTML]="data['question']['answerFeedback'] | safeHtml"></p>
                <h4><strong>Suggested Reading</strong></h4>
                <p *ngIf="!(suggestedReadingIsLink(data['question']['suggestedReading']))" style="overflow-wrap: anywhere">{{data['question']['suggestedReading']}}</p>
                <div>
                  <a *ngIf="suggestedReadingIsLink(data['question']['suggestedReading']) && !suggestedReadingIsTextWithLink(data['question']['suggestedReading'])" style="overflow-wrap: anywhere" href="{{data['question']['suggestedReading']}}" target="_blank">{{data['question']['suggestedReading']}}</a>
                </div>
                <ng-container *ngIf="suggestedReadingIsTextWithLink(data['question']['suggestedReading'])">

                  <ng-container style="margin-bottom: 0.8rem;" *ngFor="let reading of srArray">
                    <p *ngIf="!(suggestedReadingIsLink(reading))" style="overflow-wrap: anywhere">{{reading}}</p>
                    <a *ngIf="suggestedReadingIsLink(reading)" style="overflow-wrap: anywhere" href="{{reading}}" target="_blank">{{reading}}</a>
                  </ng-container>

                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div class="row" style="padding: 1rem;">
          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-center to-improve-feedback">
            <p style="font-size: 1.2rem;font-weight: bolder;"> What could be improved </p>
          </div>
        </div>
        <div class="row" style="padding: 1rem;">
          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
            <p style="font-size: 1.2rem;font-weight: bolder;color: #4976F2"> Blind spot questions </p>
          </div>
        </div>
        <div>
          <div class="row" *ngFor="let data of incorrectBlindSpot; let i = index" style="padding: 1rem">
            <div class="row" *ngIf="data['correct'] === 'true'" style="background: black; width: 100%;border-top-left-radius: 5px;border-bottom-left-radius: 5px;margin-left: 0.3rem;">
              <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="background: #48BB48;color: white;padding: 12px;border-radius: 5px;">
                <!--              Question {{data['sortOrder']}} Correct-->
                Question {{data['sortOrder']}} Correct
              </div>
              <div *ngIf="data['question'].hasOwnProperty('levelOfToughness')" class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">
                Category: {{data['question']['levelOfToughness']['toString']}}
              </div>
              <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">
                Time spent : {{msToTime(data['timeTaken'])}}
              </div>
            </div>
            <div class="row" *ngIf="data['correct'] === 'false' && data['answered'] === 'true'" style="background: black; width: 100%;border-top-left-radius: 5px;border-bottom-left-radius: 5px;margin-left: 0.3rem;">
              <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="background: #FB3737;color: white;padding: 12px;border-radius: 5px;">
                <!--              Question {{data['sortOrder']}} Correct-->
                Question {{data['sortOrder']}} Incorrect
              </div>
              <div *ngIf="data['question'].hasOwnProperty('levelOfToughness')" class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">
                Category: {{data['question']['levelOfToughness']['toString']}}
              </div>
              <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">
                Time spent : {{msToTime(data['timeTaken'])}}
              </div>
            </div>
            <div class="row" *ngIf="data['correct'] === 'false' && data['answered'] === 'false'" style="background: black; width: 100%;border-top-left-radius: 5px;border-bottom-left-radius: 5px;margin-left: 0.3rem;">
              <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="background: #817D7D;color: white;padding: 12px;border-radius: 5px;">
                <!--              Question {{data['sortOrder']}} Correct-->
                Question {{data['sortOrder']}} Un-attempted
              </div>
              <div *ngIf="data['question'].hasOwnProperty('levelOfToughness')" class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">
                Category: {{data['question']['levelOfToughness']['toString']}}
              </div>
              <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">
                Time spent : {{msToTime(data['timeTaken'])}}
              </div>
            </div>
            <!--          <div class="row" *ngIf="data['correct'] !== 'true' && data['answered'] === 'true'" style="background: black; width: 100%;border-top-left-radius: 5px;border-bottom-left-radius: 5px;margin-left: 0.3rem;">-->
            <!--            <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="background: #FB3737;color: white;padding: 12px;border-radius: 5px;">-->
            <!--&lt;!&ndash;              Question {{data['sortOrder']}} Incorrect&ndash;&gt;-->
            <!--              Question {{i + 1}} Incorrect-->
            <!--            </div>-->
            <!--            <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">-->
            <!--              <strong>Category: {{data['question']['levelOfToughness']['toString']}}</strong>-->
            <!--            </div>-->
            <!--            <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">-->
            <!--              <p>Time spent : {{msToTime(data['timeTaken'])}}</p>-->
            <!--            </div>-->
            <!--          </div>-->
            <!--        <div class="row" *ngIf="data['correct'] !== 'true' && data['answered'] === 'false'" style="background: black; width: 100%;border-top-left-radius: 5px;border-bottom-left-radius: 5px;margin-left: 0.3rem;">-->
            <!--          <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="background: #817D7D;color: white;padding: 12px;border-radius: 5px;">-->
            <!--            &lt;!&ndash;              Question {{data['sortOrder']}} Incorrect&ndash;&gt;-->
            <!--            Question {{i + 1}} Un-attempted-->
            <!--          </div>-->
            <!--          <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">-->
            <!--            <strong>Category: {{data['question']['levelOfToughness']['toString']}}</strong>-->
            <!--          </div>-->
            <!--          <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">-->
            <!--            <p>Time spent : {{msToTime(data['timeTaken'])}}</p>-->
            <!--          </div>-->
            <!--        </div>-->
            <div class="row" style="margin: 0.6rem">
              <div style="border:1px solid #E1DBDB;border-radius: 5px;padding: 1rem;margin: 0.3rem" class="col-lg-5 col-md-5">
                <div style="float: left">
                  <img src="assets/question.svg" style="height: 1.4rem;" />
                </div>
                <div class="col-lg-12 col-md-12" [innerHTML]="data['question']['questionText'] | safeHtml" style="margin-left: 1rem;">
<!--                  {{data['question']['title']}}-->
                </div><br>
                <!--            <div class="row">-->
                <div style="float: left">
                  <img src="assets/answer.svg" style="height: 1.4rem;" />
                </div>
                <ol style="list-style-type: none;margin-left: 1rem;padding-left: 1rem;">
                  <!--                <li *ngFor="let answer of data['question']['questionOptions']">-->
                  <li style="margin-bottom: 0.8rem;" *ngFor="let answer of data['answeredOptions']">
                    <!--                  <div class="circle"></div><span><p *ngIf="answer['correctAnswer'] === 'true'" style="color: green"><strong>{{answer['answerText']}}</strong></p></span>-->
                    <!--                  <div class="circle"></div><span><p *ngIf="answer['correctAnswer'] !== 'true'">{{answer['answerText']}}</p></span>-->
                    <div *ngIf="answer['selected'] === 'true'">
                      <span *ngIf="answer['answerOption']['correctAnswer'] === 'true'"><div class="correct-option-circle"></div><span style="margin-left: 0.4rem; color: #0478ED"><span [innerHTML]="answer['answerOption']['answerText'] | safeHtml"></span><span style="color: #02C26A"><strong> (Correctly Chosen) </strong></span></span></span>
                      <span *ngIf="answer['answerOption']['correctAnswer'] !== 'true'"><div class="incorrect-option-circle"></div><span style="margin-left: 0.4rem;color: #FB3737"><span [innerHTML]="answer['answerOption']['answerText'] | safeHtml"></span><span><strong> (Incorrectly Chosen) </strong></span></span></span>
                    </div>
                    <div *ngIf="answer['selected'] !== 'true' && answer['excluded'] === 'false'">
                      <span *ngIf="answer['answerOption']['correctAnswer'] === 'true'"><div class="correct-option-circle"></div><span style="margin-left: 0.4rem; color: #0478ED"><span [innerHTML]="answer['answerOption']['answerText'] | safeHtml"></span><span style="color: #02C26A"><strong> (Correct option not chosen) </strong></span></span></span>
                      <span *ngIf="answer['answerOption']['correctAnswer'] !== 'true'"><div class="circle"></div><span style="margin-left: 0.4rem"><span [innerHTML]="answer['answerOption']['answerText'] | safeHtml"></span></span></span>
                    </div>
                    <div *ngIf="answer['selected'] !== 'true' && answer['excluded'] === 'true'">
                      <span *ngIf="answer['answerOption']['correctAnswer'] === 'true'"><div class="correct-option-circle"></div><span style="margin-left: 0.4rem; color: #0478ED"><s><span [innerHTML]="answer['answerOption']['answerText'] | safeHtml"></span></s><span style="color: #02C26A"><strong> (Correct option Struck Off) </strong></span></span></span>
                      <span *ngIf="answer['answerOption']['correctAnswer'] !== 'true'"><div class="circle"></div><span style="margin-left: 0.4rem"><s><span [innerHTML]="answer['answerOption']['answerText'] | safeHtml"></span></s></span></span>
                    </div>
                  </li>
                </ol>
                <!--            </div>-->
                <div class="row">
                  <div class="col-lg-12 col-md-12">
                    <!--              <div class="col-lg-6 col-md-6">-->
                    <!--                <button class="blind" [class.not]="data['blindGuess'] === 'false' || data['blindGuess'] === undefined">Blind guess</button>-->
                    <!--                <button class="review" [class.not]="data['toReview'] === 'false'">Review Later</button>-->
                    <button [ngClass]="{'button-style-toReview': data['toReview'] === 'true', 'button-style-toReview-default': data['toReview'] !== 'true'}" style="font-weight: bold">
                      Review Later
                    </button>
                    <button [ngClass]="{'button-style-blindGuess': data['blindGuess'] === 'true', 'button-style-blindGuess-default': data['blindGuess'] !== 'true'}" style="font-weight: bold">
                      Blind Guess
                    </button>
                    <!--              </div>-->
                  </div>
                </div>
              </div>
              <div style="border: 1px solid #EFEAEA;background: #FBFBFB;padding: 1rem;margin: 0.3rem;border-radius: 5px;" class="col-lg-6 col-md-6">
                <h4><strong>Explanation</strong></h4>
                <p [innerHTML]="data['question']['answerFeedback'] | safeHtml"></p>
                <h4><strong>Suggested Reading</strong></h4>
                <p *ngIf="!(suggestedReadingIsLink(data['question']['suggestedReading']))" style="overflow-wrap: anywhere">{{data['question']['suggestedReading']}}</p>
                <div>
                  <a *ngIf="suggestedReadingIsLink(data['question']['suggestedReading']) && !suggestedReadingIsTextWithLink(data['question']['suggestedReading'])" style="overflow-wrap: anywhere" href="{{data['question']['suggestedReading']}}" target="_blank">{{data['question']['suggestedReading']}}</a>
                </div>

                <ng-container *ngIf="suggestedReadingIsTextWithLink(data['question']['suggestedReading'])">

                  <ng-container style="margin-bottom: 0.8rem;" *ngFor="let reading of srArray">
                    <p *ngIf="!(suggestedReadingIsLink(reading))" style="overflow-wrap: anywhere">{{reading}}</p>
                    <a *ngIf="suggestedReadingIsLink(reading)" style="overflow-wrap: anywhere" href="{{reading}}" target="_blank">{{reading}}</a>
                  </ng-container>

                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div class="row" style="padding: 1rem;">
          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
            <p style="font-size: 1.2rem;font-weight: bolder;color: #4976F2"> Incorrect Questions </p>
          </div>
        </div>
        <div>
          <div class="row" *ngFor="let data of incorrectQuestions; let i = index" style="padding: 1rem">
            <div class="row" *ngIf="data['correct'] === 'true'" style="background: black; width: 100%;border-top-left-radius: 5px;border-bottom-left-radius: 5px;margin-left: 0.3rem;">
              <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="background: #48BB48;color: white;padding: 12px;border-radius: 5px;">
                <!--              Question {{data['sortOrder']}} Correct-->
                Question {{data['sortOrder']}} Correct
              </div>
              <div *ngIf="data['question'].hasOwnProperty('levelOfToughness')" class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">
                Category: {{data['question']['levelOfToughness']['toString']}}
              </div>
              <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">
                Time spent : {{msToTime(data['timeTaken'])}}
              </div>
            </div>
            <div class="row" *ngIf="data['correct'] === 'false' && data['answered'] === 'true'" style="background: black; width: 100%;border-top-left-radius: 5px;border-bottom-left-radius: 5px;margin-left: 0.3rem;">
              <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="background: #FB3737;color: white;padding: 12px;border-radius: 5px;">
                <!--              Question {{data['sortOrder']}} Correct-->
                Question {{data['sortOrder']}} Incorrect
              </div>
              <div *ngIf="data['question'].hasOwnProperty('levelOfToughness')" class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">
                Category: {{data['question']['levelOfToughness']['toString']}}
              </div>
              <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">
                Time spent : {{msToTime(data['timeTaken'])}}
              </div>
            </div>
            <div class="row" *ngIf="data['correct'] === 'false' && data['answered'] === 'false'" style="background: black; width: 100%;border-top-left-radius: 5px;border-bottom-left-radius: 5px;margin-left: 0.3rem;">
              <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="background: #817D7D;color: white;padding: 12px;border-radius: 5px;">
                <!--              Question {{data['sortOrder']}} Correct-->
                Question {{data['sortOrder']}} Un-attempted
              </div>
              <div *ngIf="data['question'].hasOwnProperty('levelOfToughness')" class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">
                Category: {{data['question']['levelOfToughness']['toString']}}
              </div>
              <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">
                Time spent : {{msToTime(data['timeTaken'])}}
              </div>
            </div>
            <!--          <div class="row" *ngIf="data['correct'] !== 'true' && data['answered'] === 'true'" style="background: black; width: 100%;border-top-left-radius: 5px;border-bottom-left-radius: 5px;margin-left: 0.3rem;">-->
            <!--            <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="background: #FB3737;color: white;padding: 12px;border-radius: 5px;">-->
            <!--&lt;!&ndash;              Question {{data['sortOrder']}} Incorrect&ndash;&gt;-->
            <!--              Question {{i + 1}} Incorrect-->
            <!--            </div>-->
            <!--            <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">-->
            <!--              <strong>Category: {{data['question']['levelOfToughness']['toString']}}</strong>-->
            <!--            </div>-->
            <!--            <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">-->
            <!--              <p>Time spent : {{msToTime(data['timeTaken'])}}</p>-->
            <!--            </div>-->
            <!--          </div>-->
            <!--        <div class="row" *ngIf="data['correct'] !== 'true' && data['answered'] === 'false'" style="background: black; width: 100%;border-top-left-radius: 5px;border-bottom-left-radius: 5px;margin-left: 0.3rem;">-->
            <!--          <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="background: #817D7D;color: white;padding: 12px;border-radius: 5px;">-->
            <!--            &lt;!&ndash;              Question {{data['sortOrder']}} Incorrect&ndash;&gt;-->
            <!--            Question {{i + 1}} Un-attempted-->
            <!--          </div>-->
            <!--          <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">-->
            <!--            <strong>Category: {{data['question']['levelOfToughness']['toString']}}</strong>-->
            <!--          </div>-->
            <!--          <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">-->
            <!--            <p>Time spent : {{msToTime(data['timeTaken'])}}</p>-->
            <!--          </div>-->
            <!--        </div>-->
            <div class="row" style="margin: 0.6rem">
              <div style="border:1px solid #E1DBDB;border-radius: 5px;padding: 1rem;margin: 0.3rem" class="col-lg-5 col-md-5">
                <div style="float: left">
                  <img src="assets/question.svg" style="height: 1.4rem;" />
                </div>
                <div class="col-lg-12 col-md-12" [innerHTML]="data['question']['questionText'] | safeHtml" style="margin-left: 1rem;">
<!--                  {{data['question']['title']}}-->
                </div><br>
                <!--            <div class="row">-->
                <div style="float: left">
                  <img src="assets/answer.svg" style="height: 1.4rem;" />
                </div>
                <ol style="list-style-type: none;margin-left: 1rem;padding-left: 1rem;">
                  <!--                <li *ngFor="let answer of data['question']['questionOptions']">-->
                  <li style="margin-bottom: 0.8rem;" *ngFor="let answer of data['answeredOptions']">
                    <!--                  <div class="circle"></div><span><p *ngIf="answer['correctAnswer'] === 'true'" style="color: green"><strong>{{answer['answerText']}}</strong></p></span>-->
                    <!--                  <div class="circle"></div><span><p *ngIf="answer['correctAnswer'] !== 'true'">{{answer['answerText']}}</p></span>-->
                    <div *ngIf="answer['selected'] === 'true'">
                      <span *ngIf="answer['answerOption']['correctAnswer'] === 'true'"><div class="correct-option-circle"></div><span style="margin-left: 0.4rem; color: #0478ED"><span [innerHTML]="answer['answerOption']['answerText'] | safeHtml"></span><span style="color: #02C26A"><strong> (Correctly Chosen) </strong></span></span></span>
                      <span *ngIf="answer['answerOption']['correctAnswer'] !== 'true'"><div class="incorrect-option-circle"></div><span style="margin-left: 0.4rem;color: #FB3737"><span [innerHTML]="answer['answerOption']['answerText'] | safeHtml"></span><span><strong> (Incorrectly Chosen) </strong></span></span></span>
                    </div>
                    <div *ngIf="answer['selected'] !== 'true' && answer['excluded'] === 'false'">
                      <span *ngIf="answer['answerOption']['correctAnswer'] === 'true'"><div class="correct-option-circle"></div><span style="margin-left: 0.4rem; color: #0478ED"><span [innerHTML]="answer['answerOption']['answerText'] | safeHtml"></span><span style="color: #02C26A"><strong> (Correct option not chosen) </strong></span></span></span>
                      <span *ngIf="answer['answerOption']['correctAnswer'] !== 'true'"><div class="circle"></div><span style="margin-left: 0.4rem"><span [innerHTML]="answer['answerOption']['answerText'] | safeHtml"></span></span></span>
                    </div>
                    <div *ngIf="answer['selected'] !== 'true' && answer['excluded'] === 'true'">
                      <span *ngIf="answer['answerOption']['correctAnswer'] === 'true'"><div class="correct-option-circle"></div><span style="margin-left: 0.4rem; color: #0478ED"><s><span [innerHTML]="answer['answerOption']['answerText'] | safeHtml"></span></s><span style="color: #02C26A"><strong> (Correct option Struck Off) </strong></span></span></span>
                      <span *ngIf="answer['answerOption']['correctAnswer'] !== 'true'"><div class="circle"></div><span style="margin-left: 0.4rem"><s><span [innerHTML]="answer['answerOption']['answerText'] | safeHtml"></span></s></span></span>
                    </div>
                  </li>
                </ol>
                <!--            </div>-->
                <div class="row">
                  <div class="col-lg-12 col-md-12">
                    <!--              <div class="col-lg-6 col-md-6">-->
                    <!--                <button class="blind" [class.not]="data['blindGuess'] === 'false' || data['blindGuess'] === undefined">Blind guess</button>-->
                    <!--                <button class="review" [class.not]="data['toReview'] === 'false'">Review Later</button>-->
                    <button [ngClass]="{'button-style-toReview': data['toReview'] === 'true', 'button-style-toReview-default': data['toReview'] !== 'true'}" style="font-weight: bold">
                      Review Later
                    </button>
                    <button [ngClass]="{'button-style-blindGuess': data['blindGuess'] === 'true', 'button-style-blindGuess-default': data['blindGuess'] !== 'true'}" style="font-weight: bold">
                      Blind Guess
                    </button>
                    <!--              </div>-->
                  </div>
                </div>
              </div>
              <div style="border: 1px solid #EFEAEA;background: #FBFBFB;padding: 1rem;margin: 0.3rem;border-radius: 5px;" class="col-lg-6 col-md-6">
                <h4><strong>Explanation</strong></h4>
                <p [innerHTML]="data['question']['answerFeedback'] | safeHtml"></p>
                <h4><strong>Suggested Reading</strong></h4>
                <p *ngIf="!(suggestedReadingIsLink(data['question']['suggestedReading']))" style="overflow-wrap: anywhere">{{data['question']['suggestedReading']}}</p>
                <div>
                  <a *ngIf="suggestedReadingIsLink(data['question']['suggestedReading']) && !suggestedReadingIsTextWithLink(data['question']['suggestedReading'])" style="overflow-wrap: anywhere" href="{{data['question']['suggestedReading']}}" target="_blank">{{data['question']['suggestedReading']}}</a>
                </div>

                <ng-container *ngIf="suggestedReadingIsTextWithLink(data['question']['suggestedReading'])">

                  <ng-container style="margin-bottom: 0.8rem;" *ngFor="let reading of srArray">
                    <p *ngIf="!(suggestedReadingIsLink(reading))" style="overflow-wrap: anywhere">{{reading}}</p>
                    <a *ngIf="suggestedReadingIsLink(reading)" style="overflow-wrap: anywhere" href="{{reading}}" target="_blank">{{reading}}</a>
                  </ng-container>

                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div class="row" style="padding: 1rem;">
          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
            <p style="font-size: 1.2rem;font-weight: bolder;color: #4976F2"> Unattempted Questions </p>
          </div>
        </div>
        <div>
          <div class="row" *ngFor="let data of unAnsweredQuestions; let i = index" style="padding: 1rem">
<!--            <div class="row" *ngIf="data['correct'] === 'true'" style="background: black; width: 100%;border-top-left-radius: 5px;border-bottom-left-radius: 5px;margin-left: 0.3rem;">-->
<!--              <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="background: #48BB48;color: white;padding: 12px;border-radius: 5px;">-->
<!--                &lt;!&ndash;              Question {{data['sortOrder']}} Correct&ndash;&gt;-->
<!--                Question {{data['sortOrder']}} Correct-->
<!--              </div>-->
<!--              <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">-->
<!--                Category: {{data['question']['levelOfToughness']['toString']}}-->
<!--              </div>-->
<!--              <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">-->
<!--                Time spent : {{msToTime(data['timeTaken'])}}-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="row" *ngIf="data['correct'] === 'false' && data['answered'] === 'true'" style="background: black; width: 100%;border-top-left-radius: 5px;border-bottom-left-radius: 5px;margin-left: 0.3rem;">-->
<!--              <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="background: #FB3737;color: white;padding: 12px;border-radius: 5px;">-->
<!--                &lt;!&ndash;              Question {{data['sortOrder']}} Correct&ndash;&gt;-->
<!--                Question {{data['sortOrder']}} Incorrect-->
<!--              </div>-->
<!--              <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">-->
<!--                Category: {{data['question']['levelOfToughness']['toString']}}-->
<!--              </div>-->
<!--              <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">-->
<!--                Time spent : {{msToTime(data['timeTaken'])}}-->
<!--              </div>-->
<!--            </div>-->
            <div class="row" *ngIf="data['correct'] === 'false' && data['answered'] === 'false'" style="background: black; width: 100%;border-top-left-radius: 5px;border-bottom-left-radius: 5px;margin-left: 0.3rem;">
              <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="background: #817D7D;color: white;padding: 12px;border-radius: 5px;">
                <!--              Question {{data['sortOrder']}} Correct-->
                Question {{data['sortOrder']}} Un-attempted
              </div>
              <div *ngIf="data['question'].hasOwnProperty('levelOfToughness')" class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">
                Category: {{data['question']['levelOfToughness']['toString']}}
              </div>
              <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">
                Time spent : {{msToTime(data['timeTaken'])}}
              </div>
            </div>
            <!--          <div class="row" *ngIf="data['correct'] !== 'true' && data['answered'] === 'true'" style="background: black; width: 100%;border-top-left-radius: 5px;border-bottom-left-radius: 5px;margin-left: 0.3rem;">-->
            <!--            <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="background: #FB3737;color: white;padding: 12px;border-radius: 5px;">-->
            <!--&lt;!&ndash;              Question {{data['sortOrder']}} Incorrect&ndash;&gt;-->
            <!--              Question {{i + 1}} Incorrect-->
            <!--            </div>-->
            <!--            <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">-->
            <!--              <strong>Category: {{data['question']['levelOfToughness']['toString']}}</strong>-->
            <!--            </div>-->
            <!--            <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">-->
            <!--              <p>Time spent : {{msToTime(data['timeTaken'])}}</p>-->
            <!--            </div>-->
            <!--          </div>-->
            <!--        <div class="row" *ngIf="data['correct'] !== 'true' && data['answered'] === 'false'" style="background: black; width: 100%;border-top-left-radius: 5px;border-bottom-left-radius: 5px;margin-left: 0.3rem;">-->
            <!--          <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="background: #817D7D;color: white;padding: 12px;border-radius: 5px;">-->
            <!--            &lt;!&ndash;              Question {{data['sortOrder']}} Incorrect&ndash;&gt;-->
            <!--            Question {{i + 1}} Un-attempted-->
            <!--          </div>-->
            <!--          <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">-->
            <!--            <strong>Category: {{data['question']['levelOfToughness']['toString']}}</strong>-->
            <!--          </div>-->
            <!--          <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4" style="color: white;padding:12px;">-->
            <!--            <p>Time spent : {{msToTime(data['timeTaken'])}}</p>-->
            <!--          </div>-->
            <!--        </div>-->
            <div class="row" style="margin: 0.6rem">
              <div style="border:1px solid #E1DBDB;border-radius: 5px;padding: 1rem;margin: 0.3rem" class="col-lg-5 col-md-5">
                <div style="float: left">
                  <img src="assets/question.svg" style="height: 1.4rem;" />
                </div>
                <div class="col-lg-12 col-md-12" [innerHTML]="data['question']['questionText'] | safeHtml" style="margin-left: 1rem;">
<!--                  {{data['question']['title']}}-->
                </div><br>
                <!--            <div class="row">-->
                <div style="float: left">
                  <img src="assets/answer.svg" style="height: 1.4rem;" />
                </div>
                <ol style="list-style-type: none;margin-left: 1rem;padding-left: 1rem;">
                  <!--                <li *ngFor="let answer of data['question']['questionOptions']">-->
                  <li style="margin-bottom: 0.8rem;" *ngFor="let answer of data['answeredOptions']">
                    <!--                  <div class="circle"></div><span><p *ngIf="answer['correctAnswer'] === 'true'" style="color: green"><strong>{{answer['answerText']}}</strong></p></span>-->
                    <!--                  <div class="circle"></div><span><p *ngIf="answer['correctAnswer'] !== 'true'">{{answer['answerText']}}</p></span>-->
                    <div *ngIf="answer['selected'] === 'true'">
                      <span *ngIf="answer['answerOption']['correctAnswer'] === 'true'"><div class="correct-option-circle"></div><span style="margin-left: 0.4rem; color: #0478ED"><span [innerHTML]="answer['answerOption']['answerText'] | safeHtml"></span><span style="color: #02C26A"><strong> (Correctly Chosen) </strong></span></span></span>
                      <span *ngIf="answer['answerOption']['correctAnswer'] !== 'true'"><div class="incorrect-option-circle"></div><span style="margin-left: 0.4rem;color: #FB3737"><span [innerHTML]="answer['answerOption']['answerText'] | safeHtml"></span><span><strong> (Incorrectly Chosen) </strong></span></span></span>
                    </div>
                    <div *ngIf="answer['selected'] !== 'true' && answer['excluded'] === 'false'">
                      <span *ngIf="answer['answerOption']['correctAnswer'] === 'true'"><div class="correct-option-circle"></div><span style="margin-left: 0.4rem; color: #0478ED"><span [innerHTML]="answer['answerOption']['answerText'] | safeHtml"></span><span style="color: #02C26A"><strong> (Correct option not chosen) </strong></span></span></span>
                      <span *ngIf="answer['answerOption']['correctAnswer'] !== 'true'"><div class="circle"></div><span style="margin-left: 0.4rem"><span [innerHTML]="answer['answerOption']['answerText'] | safeHtml"></span> </span></span>
                    </div>
                    <div *ngIf="answer['selected'] !== 'true' && answer['excluded'] === 'true'">
                      <span *ngIf="answer['answerOption']['correctAnswer'] === 'true'"><div class="correct-option-circle"></div><span style="margin-left: 0.4rem; color: #0478ED"><s><span [innerHTML]="answer['answerOption']['answerText'] | safeHtml"></span></s><span style="color: #02C26A"><strong> (Correct option Struck Off) </strong></span></span></span>
                      <span *ngIf="answer['answerOption']['correctAnswer'] !== 'true'"><div class="circle"></div><span style="margin-left: 0.4rem"><s><span [innerHTML]="answer['answerOption']['answerText'] | safeHtml"></span></s></span></span>
                    </div>
                  </li>
                </ol>
                <!--            </div>-->
                <div class="row">
                  <div class="col-lg-12 col-md-12">
                    <!--              <div class="col-lg-6 col-md-6">-->
                    <!--                <button class="blind" [class.not]="data['blindGuess'] === 'false' || data['blindGuess'] === undefined">Blind guess</button>-->
                    <!--                <button class="review" [class.not]="data['toReview'] === 'false'">Review Later</button>-->
                    <button [ngClass]="{'button-style-toReview': data['toReview'] === 'true', 'button-style-toReview-default': data['toReview'] !== 'true'}" style="font-weight: bold">
                      Review Later
                    </button>
                    <button [ngClass]="{'button-style-blindGuess': data['blindGuess'] === 'true', 'button-style-blindGuess-default': data['blindGuess'] !== 'true'}" style="font-weight: bold">
                      Blind Guess
                    </button>
                    <!--              </div>-->
                  </div>
                </div>
              </div>
              <div style="border: 1px solid #EFEAEA;background: #FBFBFB;padding: 1rem;margin: 0.3rem;border-radius: 5px;" class="col-lg-6 col-md-6">
                <h4><strong>Explanation</strong></h4>
                <p [innerHTML]="data['question']['answerFeedback'] | safeHtml"></p>
                <h4><strong>Suggested Reading</strong></h4>
                <p *ngIf="!(suggestedReadingIsLink(data['question']['suggestedReading']))" style="overflow-wrap: anywhere">{{data['question']['suggestedReading']}}</p>
                <div>
                  <a *ngIf="suggestedReadingIsLink(data['question']['suggestedReading']) && !suggestedReadingIsTextWithLink(data['question']['suggestedReading'])" style="overflow-wrap: anywhere" href="{{data['question']['suggestedReading']}}" target="_blank">{{data['question']['suggestedReading']}}</a>
                </div>

                <ng-container *ngIf="suggestedReadingIsTextWithLink(data['question']['suggestedReading'])">

                  <ng-container style="margin-bottom: 0.8rem;" *ngFor="let reading of srArray">
                    <p *ngIf="!(suggestedReadingIsLink(reading))" style="overflow-wrap: anywhere">{{reading}}</p>
                    <a *ngIf="suggestedReadingIsLink(reading)" style="overflow-wrap: anywhere" href="{{reading}}" target="_blank">{{reading}}</a>
                  </ng-container>

                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-1 col-lg-1"></div>
  </div>

  <!-- feedback popup -->
  <div id="myModal" class="modal fade" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body text-center">
          <div class="d-flex justify-content-end align-items-center">
            <a href="" (click)="closeModal()" class="close_icon" data-dismiss="modal"><i class="bi bi-x-circle"></i></a>
          </div>
          <h1>Your opinion matters</h1>
          <h4>{{ title }}</h4>
          <hr>
          <div *ngFor="let item of feedbackAnswers">
            <div *ngIf="item.question.dataType === 'Integer'">
              <div class="text-center px-2">
                <p [innerHTML]="item.question.questionText | safeHtml"></p>
              </div> 
              <div class="questions">
                <ul class="list-unstyled d-flex justify-content-center">
                  <li (click)="selectStar(1, item.id)" [id]="'star' + item.id" class="star ml-4"><i class="bi bi-star-fill"></i></li>
                  <li (click)="selectStar(2, item.id)" [id]="'star' + item.id + 1" class="star ml-4"><i class="bi bi-star-fill"></i></li>
                  <li (click)="selectStar(3, item.id)" [id]="'star' + item.id + 2" class="star ml-4"><i class="bi bi-star-fill"></i></li>
                  <li (click)="selectStar(4, item.id)" [id]="'star' + item.id + 3" class="star ml-4"><i class="bi bi-star-fill"></i></li>
                  <li (click)="selectStar(5, item.id)" [id]="'star' + item.id + 4" class="star ml-4"><i class="bi bi-star-fill"></i></li>
                </ul>
              </div>
            </div>
          </div>
          <div *ngFor="let item of feedbackAnswers">
            <div *ngIf="item.question.dataType === 'String'">
              <div class="text-center mt-2 px-2">
                <p [innerHTML]="item.question.questionText | safeHtml"></p>
              </div> 
              <div>
                <textarea [(ngModel)] = "textFeedback" class="bg-light p-2" type="textarea" placeholder="Type your feedback" rows="5" style="width: 100%;border-radius: 4px;"></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer"> 
          <button (click)="sendFeedback()" class="btn btn-primary" data-dismiss="modal">Send</button> 
          <button (click)="closeModal()" class="btn btn-outline-primary" data-dismiss="modal">Cancel</button> 
        </div>
      </div>
    </div>
  </div>
  <!-- Thank you popup -->
  <div class="backdrop" [ngStyle]="{'display':showThankyou}"></div>
  <div [ngStyle]="{'display': showThankyou}" class="modal fade show" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div class="d-flex justify-content-between align-items-center">
            <p class="thankyou">Thankyou for your feedback</p>
            <p class="btn thankyou cross" (click)="thankyouClose()" ><i class="bi bi-x-circle"></i></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</div>
