<app-navbar></app-navbar>
<div class="main pb-4">
    <div class="container-fluid">
        <div class="row flex-column-reverse flex-lg-row justify-content-center">
            <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                <div *ngFor="let data of chatData">
                    <div *ngIf="data.message !== undefined && data.message.text !== '/' && data.message.text !== '/setprivacy' && data.message.chat.type !== 'private' && data.message.group_chat_created === undefined" class="d-flex flex-wrap flex-lg-nowrap justify-content-start">
                        <img src="/assets/img/homepage-landing.png" alt="image" class="chatImg">
                        <div class="chatbox card shadow ml-2 text-left">
                            <p class="chatHeading" [innerText]="data.message.chat.title"></p>
                            <div *ngIf="data.message.text" class="chatQuestion">
                                <p [innerText]="data.message.text"></p>
                            </div>
                            <div *ngIf="data.message.poll" class="chatQuestion">
                                <p [innerText]="data.message.poll.question"></p>
                                <div *ngFor="let option of data.message.poll.options">
                                    <p class="mt-2">{{ option.text }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="data.channel_post !== undefined && data.channel_post.text !== '/' && data.channel_post.text !== '/setprivacy' && data.channel_post.chat.type !== 'private'" class="d-flex flex-wrap flex-lg-nowrap justify-content-start mt-2">
                        <img src="/assets/img/homepage-landing.png" alt="image" class="chatImg">
                        <div class="chatbox card shadow arrow-left ml-2 mt-4 px-4 text-left">
                            <p class="chatHeading mt-3" [innerText]="data.channel_post.chat.title"></p>
                            <div *ngIf="data.channel_post.text" class="chatQuestion">
                                <p [innerText]="data.channel_post.text"></p>
                            </div>
                            <div *ngIf="data.channel_post.poll" class="chatQuestion">
                                <p [innerText]="data.channel_post.poll.question"></p>
                                <div *ngFor="let option of data.channel_post.poll.options">
                                    <p class="mt-2">{{ option.text }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12 pt-4">
                <div class="infoCard card shadow p-2 mt-2 ml-lg-4">
                    <div class="d-flex justify-content-start align-items-center">
                        <img src="/assets/img/homepage-landing.png" alt="image" class="infoImg">
                        <p class="infoHeading ml-2 mt-4">{{ infoData.infoHeading }}<br/><span class="infoSubheading"><a href="https://t.me/excelonacademy" target="_blank">{{ infoData.infoSubheading }}</a></span></p>
                    </div>
                    <div class="d-flex flex-md-wrap flex-sm-wrap justify-content-center align-items-center mt-4">
                        <div class="text-left" *ngFor="let item of infoData.data">
                            <p class="infoHeading1 m-2">{{ item.value }}<br/><span class="infoSubheading1">{{ item.heading }}</span></p>
                        </div>
                    </div>
                    <div class="infoHeading2 mt-2 p-2">
                        <p>"An IIT Madras startup. Daily practice questions for UPSC prelims and MAINS. For queries related to prep strategy DM <span class="infoSubheading"><a href="https://t.me/dsr123" target="_blank">@dsr123</a></span> or <span class="infoSubheading"><a href="https://t.me/udaythecoach" target="_blank">@udaythecoach</a></span>. For free data analytics based test series and 3 level analysis report visit <span routerLink="/" class="infoSubheading">excelonacademy.com</span>.</p>
                    </div>
                    <div class="p-2 text-center infoSubheading1">
                        <a href="https://t.me/excelonacademy" target="_blank" class="btn btn-primary p-2">open telegram</a>
                        <p class="mt-2">to view and join the conversation</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="last_div">
    <app-footer></app-footer>
</div>