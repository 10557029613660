<!--<app-login></app-login>-->
<div>
  <router-outlet></router-outlet>
</div>
<!--<div class="footer-position">-->
<!--  <div class="row">-->
<!--    <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12"></div>-->
<!--    <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12"></div>-->
<!--    <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 text-right">-->
<!--      &lt;!&ndash;    <a style="width: 100%;" class="message py-2"> Send a Message </a>&ndash;&gt;-->
<!--      <button class="message">Send a Message</button>-->
<!--    </div>-->
<!--  </div>-->
<!--  <app-footer></app-footer>-->
<!--</div>-->
<!--<app-testimonial></app-testimonial>-->
