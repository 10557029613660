import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {LinxService} from "../../linx.service";
import {ListenerService} from "../../listener.service";

@Component({
  selector: 'app-ans',
  templateUrl: './ans.component.html',
  styleUrls: ['./ans.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AnsComponent implements OnInit, OnChanges {

  constructor(public service: LinxService, public listenerService: ListenerService) {
    listenerService.clearAnswer.subscribe(event => {
      if (event === true) {
        this.answerTemp = undefined;
      }
    });
  }

  @Input() completeAnswerObject: any;
  @Input() answerType: any;
  @Input() answers: any;
  @Output() answer = new EventEmitter<string>();
  answerTemp: any;
  ngOnChanges() {
    if (this.completeAnswerObject['answered'] === 'true') {
      for (let i = 0; i < this.answers.length ; i++ ) {
        if (this.answers[i].selected === 'true') {
          this.answerTemp = this.answers[i].answerOption.answerText;
        }
      }
    } else {
      this.answerTemp = '';
    }
  }
  ngOnInit(): void {
    if (this.completeAnswerObject['answered'] === 'true') {
      for (let i = 0; i < this.answers.length ; i++ ) {
        if (this.answers[i].selected === 'true') {
          this.answerTemp = this.answers[i].answerOption.answerText;
        }
      }
    } else {
      this.answerTemp = '';
    }
  }
  emit(act) {
    this.answer.emit(JSON.stringify({answers: this.answers, action: act}));
  }


  // updateUserAnswer(a) {
  //   const payloadToUpdateUserAnswerOption = {
  //     excluded: this.answers[a].excluded,
  //     included: this.answers[a].included,
  //     selected: 'true'
  //   };
  //   this.service.updateUserAnswerOption(this.answers[a]['id'], payloadToUpdateUserAnswerOption).subscribe();
  //   this.emit();
  // }

  crossOut(a) {
    if (this.answers[a].excluded === 'false') {
      if (this.answers[a].selected === 'false') {
        this.answers[a].excluded = 'true';
        this.answers[a].included = 'false';
      }
    } else {
      this.answers[a].included = 'true';
      this.answers[a].excluded = 'false';
    }
    // const payloadToUpdateUserAnswerOption = {
    //   excluded: this.answers[a].excluded,
    //   included: this.answers[a].included,
    //   selected: this.answers[a].selected
    // };
    // this.service.updateUserAnswerOption(this.answers[a]['id'], payloadToUpdateUserAnswerOption).subscribe();
    // this.emit();
    this.updateAnswer('struck-off');
  }

  updateAnswer(action) {
    const payload = [];
    for (const answer of this.answers) {
      const tempObject = {};
      tempObject['excluded'] = answer['excluded'];
      tempObject['included'] = answer['included'];
      tempObject['selected'] = answer['selected'];
      tempObject['id'] = answer['id'];
      payload.push(tempObject);
    }
    this.service.updateMultipleOptions(payload).subscribe(response => {});
    this.emit(action);
  }

  changeOption() {
    for (let i = 0; i < this.answers.length ; i++) {
      this.answers[i].answered = 'true';
      if (this.answers[i].answerOption.answerText === this.answerTemp) {
        this.answers[i].selected = 'true';
      } else {
        this.answers[i].selected = 'false';
      }
    }
    this.updateAnswer('answered');
  }
  changeText() {
  }

}
