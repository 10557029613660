import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartscompComponent } from './chartscomp.component';
import {ChartsModule} from 'ng2-charts';
import {MatTabsModule} from '@angular/material/tabs';
import {HvrComponent} from './chartscomp.component';
import {MatCardModule} from '@angular/material/card';

@NgModule({
    declarations: [ChartscompComponent, HvrComponent],
    exports: [
        ChartscompComponent
    ],
    imports: [
        CommonModule,
        ChartsModule,
        MatTabsModule,
        MatCardModule
    ]
})
export class ChartscompModule { }
