import {Component, Inject, Input, OnDestroy, OnInit, ɵLocaleDataIndex} from '@angular/core';
import {LinxService} from "../linx.service";
import {ActivatedRoute, Router} from "@angular/router";
import {timer} from "rxjs";
import {ListenerService} from "../listener.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";

declare let $;

@Component({
  selector: 'app-exam',
  templateUrl: './exam.component.html',
  styleUrls: ['./exam.component.scss']
})
export class ExamComponent implements OnInit, OnDestroy {

  @Input() allQuestions: any;
  @Input() question: any;
  @Input() answers: any;
  @Input() questionType: any;
  @Input() answerType: any;
  @Input() answer: any;
  blindCount = 0;
  reviewCount = 0;
  answeredCount = 0;
  notAnsweredCount = 0;
  questions = [];
  completeData: any;
  id: any;
  examId: any;
  css = 'border: 3px solid mediumseagreen';
  questionId: any;
  flag = false;
  timer = {};
  counter = new Date();
  timeTakenForTheQuestion: any;
  showPauseButton = true;
  showResumeButton = false;
  testSubmitted = false;

  isPracticeMode = false;
  sortOrder: any;
  questionStartTime: any;

  seconds = 0;
  isTimerPaused = false;
  currentTime: any;
  questionTimerSubject: any;
  examDuration: any;

  constructor(private service: LinxService, private router: Router, private snackBar: MatSnackBar,
              private route: ActivatedRoute, public listenerService: ListenerService, public dialog: MatDialog) { }

  pad(val) {
    return val > 9 ? val : "0" + val;
  }

  incrementTimer() {
    if (localStorage.getItem('currentExamData') && this.router.url.includes('exams')) {
      if (document.getElementById("seconds") && document.getElementById("seconds") !== null && document.getElementById("seconds") !== undefined) {
        document.getElementById("seconds").innerHTML = this.pad(++this.seconds % 60);
      }
      if (document.getElementById("minutes") && document.getElementById("minutes") !== null && document.getElementById("minutes") !== undefined) {
        document.getElementById("minutes").innerHTML = this.pad(parseInt(((this.seconds / 60) % 60 ).toString(), 10));
      }
      if (document.getElementById("hours") && document.getElementById("hours") !== null && document.getElementById("hours") !== undefined) {
        document.getElementById("hours").innerHTML = this.pad(parseInt((this.seconds / (60 * 60)).toString(), 10));
      }
    }
  }

  ngOnInit(): void {

    const that = this;
    $(document).ready(function() {
      that.questionTimerSubject = setInterval(function () {
        if (!that.isTimerPaused) {
          that.incrementTimer();
        }
      }, 1000);
    });
    this.route.queryParams.subscribe(async params1 => {
      if (params1 !== undefined && params1 !== null && params1.exam !== null && params1.exam !== undefined) {
        // this.confPost.createExam(params1.exam).subscribe(data1 => {
        // this.examId = 13;
        this.examId = params1.exam;
        this.flag = false;
        const data = localStorage.getItem('currentExamData');
        if (localStorage.getItem('examMode') === 'practice') {
          this.isPracticeMode = true;
        } else {
          this.isPracticeMode = false;
        }
        // localStorage.removeItem('currentExamData');
        //if (!(localStorage.getItem('currentExamData'))) {
          await this.getExamData(params1.exam);
        // } else {
        //   this.completeData = JSON.parse(localStorage.getItem('currentExamData'));
        //   this.getExamDuration();
        //   this.currentTime = Date.now();
        //   for (let i = 0; i < this.completeData.userAnswers.length; i++) {
        //     this.questions.push(this.completeData.userAnswers[i].question);
        //     if (this.completeData.userAnswers[i].answered === 'true') {
        //       this.answeredCount++;
        //     } else {
        //       this.notAnsweredCount++;
        //     }
        //     if (this.completeData.userAnswers[i]['blindGuess'] === 'true') {
        //       this.blindCount++;
        //     } else if (this.completeData.userAnswers[i]['toReview'] === 'true') {
        //       this.reviewCount++;
        //     }
        //   }
        // }
        // });
        this.route.queryParams
          .subscribe(params => {
          this.completeData = JSON.parse(localStorage.getItem('currentExamData'));
          if (this.completeData) {
            if (params.id === undefined) {
              this.questionId = this.completeData.userAnswers[0].question.id;
            } else {
              this.questionId = parseInt(params.id, 10);
              this.updatePreviousResponse();
            }
            for (let j = 0; j < this.completeData.userAnswers.length ; j++ ) {
              if (this.completeData.userAnswers[j].question.id === this.questionId) {
                this.sortOrder = this.completeData.userAnswers[j]['sortOrder'];
                this.question = this.completeData.userAnswers[j].question.questionText;
                this.answers = this.completeData.userAnswers[j].answeredOptions;
                this.questionStartTime = this.completeData.userAnswers[j]['timeTaken'];
                this.answerType = 'select';
                this.id = j + 1;
                this.answer = '';
                this.setTimerForTheQuestion();
              }
            }
            this.flag = true;
          }
        });
      }
    });
  }

  showResume() {
    this.isTimerPaused = true;
    this.addToTotalTime();
    this.showResumeButton = true;
    this.showPauseButton = false;
  }

  showPause() {
    this.isTimerPaused = false;
    this.currentTime = Date.now();
    this.showPauseButton = true;
    this.showResumeButton = false;
  }

  getExamDuration() {
    if (this.completeData.hasOwnProperty('totalTime') && this.completeData['totalTime'] !== null && this.completeData['totalTime'] !== undefined) {
      this.examDuration = (+((this.completeData['paper']['maxTimeMS']) / 1000) - +((this.completeData['totalTime']) / 1000)).toString();
    } else {
      this.examDuration = +((this.completeData['paper']['maxTimeMS']) / 1000);
    }
  }

  getExamData(examId) {
    return new Promise((resolve, reject) => {
      this.service.getQuestion(examId).subscribe(data => {
        this.currentTime = Date.now();
        this.completeData = data;
        if (this.completeData && this.completeData !== null && this.completeData !== undefined) {
          this.getExamDuration();
          if (this.router.url.includes('exams')) {
            localStorage.setItem('currentExamData', JSON.stringify(this.completeData));
          }
          for (let i = 0; i < this.completeData.userAnswers.length; i++) {
            this.questions.push(this.completeData.userAnswers[i].question);
            if (this.completeData.userAnswers[i].answered === 'true') {
              this.answeredCount++;
            } else {
              this.notAnsweredCount++;
            }
            if (this.completeData.userAnswers[i]['blindGuess'] === 'true') {
              this.blindCount++;
            } else if (this.completeData.userAnswers[i]['toReview'] === 'true') {
              this.reviewCount++;
            }
          }
        } else {
          this.snackBar.open('Error while creating exam. Please try again after sometime.', 'Error', {
            duration: 9000,
            verticalPosition: 'top',
            horizontalPosition: 'center'
          });
          setTimeout(() => {
            this.router.navigate(['/modules']);
            setTimeout(() => {
              // location.reload();
            }, 300);
          }, 3000);
        }
        resolve('success');
      }, error => {
        this.snackBar.open('Error while creating exam. Please try again after sometime.', 'Error', {
          duration: 9000,
          verticalPosition: 'top',
          horizontalPosition: 'center'
        });
        setTimeout(() => {
          this.router.navigate(['/modules']);
          setTimeout(() => {
            // location.reload();
          }, 300);
        }, 3000);
        reject(error);
      });
    });
  }

  setTimerForTheQuestion() {
    // this.timeTakenForTheQuestion = +(this.completeData.userAnswers[this.id - 1]['timeTaken']);
    // const time = this.msToTime(this.timeTakenForTheQuestion);
    // if (this.timer.hasOwnProperty('closed')) {
    //   this.timer['closed'] = true;
    // }
    // this.timer = timer(0, 1000)
    //   .subscribe(t => {
    //     this.counter = new Date(0, 0, 0,  time.hours , time.minutes, time.seconds);
    //     // this.counter = new Date(0, 0, 0,  time.hours , time.minutes, time.seconds);
    //     this.counter.setSeconds(t);
    //   });


    if (this.completeData.userAnswers[this.id - 1].hasOwnProperty('timeTaken') && this.completeData.userAnswers[this.id - 1]['timeTaken'] !== null && this.completeData.userAnswers[this.id - 1]['timeTaken'] !== undefined && this.completeData.userAnswers[this.id - 1]['timeTaken'] !== '') {
      this.seconds = Math.round(+((this.completeData.userAnswers[this.id - 1]['timeTaken']) / 1000));
    } else {
      this.seconds = 0;
    }
  }

  msToTime(duration) {
      const seconds = Math.floor((duration / 1000) % 60);
      const minutes = Math.floor((duration / (1000 * 60)) % 60);
      const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

      return {hours: hours, minutes: minutes, seconds: seconds};
  }

  setPrev() {
    if (this.id === 1) {
    } else {
      // this.confPost.postUpdateUserAnswerSet('97', {userAnswers: this.completeData.userAnswers}).subscribe(success => {
      this.updatePreviousResponse();
      this.id = this.id - 1;
      this.question = this.completeData.userAnswers[this.id - 1].question.questionText;
      this.answers = this.completeData.userAnswers[this.id - 1].answeredOptions;
      this.answerType = 'select';
      this.answer = '';
      this.sortOrder = this.completeData.userAnswers[this.id - 1]['sortOrder'];
      this.setTimerForTheQuestion();
      // }, err => {
      //   alert('Error while Submitting. Please try again');
      // });
    }
  }
  clickone(th: any) {
    if (th.text === '00:01:00') {
      this.css = ' border: 3px solid orange';
    } else if (th.text === '00:00:30') {
      this.css = ' border: 3px solid red';
    } else if (th.text === '00:00:00') {
      alert('Test timed out');
      this.service.submitExam(this.examId).subscribe(response => {
        localStorage.removeItem('currentExamData');
        this.router.navigate(['/report'], { queryParams: {id: this.examId}});
      }, error => {
        this.snackBar.open('Error while submitting exam. Please try again after sometime.', 'Error', {
          duration: 5000,
          verticalPosition: 'top',
          horizontalPosition: 'center'
        });
        this.router.navigateByUrl('/modules');
      });
    }
  }

  addToTotalTime() {
    if (this.isTimerPaused === false) {
      this.service.addToTotalTime(this.completeData['id'], Date.now() - this.currentTime).subscribe(response => {
        this.currentTime = Date.now();
        this.completeData['paper']['totalTime'] = response;
        localStorage.setItem('currentExamData', JSON.stringify(this.completeData));
        this.examDuration = (+((this.completeData['paper']['maxTimeMS']) / 1000) - +((this.completeData['totalTime']) / 1000)).toString();
      });
    }
  }

  updatePreviousResponse() {
    // const msToHours = this.counter.getHours() * 3600000;
    // const msToMinutes = this.counter.getMinutes() * 60000;
    // const msToSeconds = this.counter.getSeconds() * 1000;
    // const timeTaken = msToHours + msToMinutes + msToSeconds;

    return new Promise((resolve, reject) => {
      this.addToTotalTime();
      const timeTaken = this.seconds * 1000;
      if (this.completeData.userAnswers[this.id - 1]) {
        this.completeData.userAnswers[this.id - 1]['timeTaken'] = timeTaken.toString();
        const payloadToUpdateOption = {
          answered: this.completeData.userAnswers[this.id - 1].answered,
          blindGuess: this.completeData.userAnswers[this.id - 1].blindGuess,
          timeTaken: timeTaken.toString(),
          toReview: this.completeData.userAnswers[this.id - 1].toReview
        };
        this.service.updateUserAnswer(this.completeData.userAnswers[this.id - 1]['id'], payloadToUpdateOption).subscribe(resp => {
          localStorage.setItem('currentExamData', JSON.stringify(this.completeData));
          resolve('success');
        }, error => {
          resolve('failed');
        });
      } else {
        resolve('success');
      }
    });
  }

  setNext() {
    if (this.id === this.completeData.userAnswers.length) {
    } else {
      // this.confPost.postUpdateUserAnswerSet('97', {userAnswers: this.completeData.userAnswers}).subscribe(success => {
      this.updatePreviousResponse();
      this.id = this.id + 1;
      this.question = this.completeData.userAnswers[this.id - 1].question.questionText;
      this.answers = this.completeData.userAnswers[this.id - 1].answeredOptions;
      this.answerType = 'select';
      this.answer = '';
      this.sortOrder = this.completeData.userAnswers[this.id - 1]['sortOrder'];
      this.setTimerForTheQuestion();
      // }, err => {
      //   alert('Error while Submitting. Please try again');
      // });
    }
  }
  blindGuess() {
    if (this.completeData.userAnswers[this.id - 1].answered === 'true') {
      // this.answeredCount++;
      this.completeData.userAnswers[this.id - 1].blindGuess = 'true';
      this.blindCount++;
      if (this.completeData.userAnswers[this.id - 1].toReview === 'true') {
        this.completeData.userAnswers[this.id - 1].toReview = 'false';
        if (this.reviewCount > 0) {
          this.reviewCount--;
        }
      }
      this.updatePreviousResponse();
      // this.confPost.postUpdateUserAnswerSet('97', {userAnswers: this.completeData.userAnswers});
    }
  }
  reviewLater() {
    // if (this.completeData.userAnswers[this.id - 1].answered === 'true') {
      this.completeData.userAnswers[this.id - 1].toReview = 'true';
      this.reviewCount++;
      if (this.completeData.userAnswers[this.id - 1].blindGuess === 'true') {
        this.completeData.userAnswers[this.id - 1].blindGuess = 'false';
        if (this.blindCount > 0) {
          this.blindCount--;
        }
      }
      this.updatePreviousResponse();
    // this.confPost.postUpdateUserAnswerSet('97', {userAnswers: this.completeData.userAnswers});
    // }
  }

  clearAnswer() {
    this.completeData.userAnswers[this.id - 1].answered = 'false';
    if (this.completeData.userAnswers[this.id - 1].toReview === 'true') {
      this.completeData.userAnswers[this.id - 1].toReview = 'false';
      this.reviewCount--;
    }
    if (this.completeData.userAnswers[this.id - 1].blindGuess === 'true') {
      this.completeData.userAnswers[this.id - 1].blindGuess = 'false';
      this.blindCount--;
    }
    if (this.completeData.userAnswers[this.id - 1].answered === 'true') {
      this.answeredCount--;
      this.notAnsweredCount++;
    }
    for (const option of this.completeData.userAnswers[this.id - 1].answeredOptions) {
      if (option) {
        option['answered'] = 'false';
        option['excluded'] = 'false';
        option['included'] = 'false';
        option['selected'] = 'false';
      }
    }
    this.listenerService.clearAnswer.emit(true);
  }

  updateAns(answerObject) {
    const object = JSON.parse(answerObject);
    const answer = object['answers'];
    if (this.completeData.userAnswers[this.id - 1]['answered'] !== 'true') {
      this.answeredCount++;
      this.notAnsweredCount--;
    }
    this.completeData.userAnswers[this.id - 1].answeredOptions = answer;
    if (object['action'] === 'struck-off') {
      if (this.completeData.userAnswers[this.id - 1]['answered'] !== 'true') {
        this.completeData.userAnswers[this.id - 1]['answered'] = 'false';
      }
    } else {
      this.completeData.userAnswers[this.id - 1]['answered'] = 'true';
      this.completeData.userAnswers[this.id - 1].toReview = 'false';
      this.completeData.userAnswers[this.id - 1].blindGuess = 'false';
    }
    // this.confPost.postUpdateUserAnswerSet('97', {userAnswers: this.completeData.userAnswers}).subscribe();
    this.updatePreviousResponse();
  }

  completeTest() {
    this.testSubmitted = true;
    const dialogRef = this.dialog.open(ConfirmSubmitExamComponent, {
      maxWidth: '65vw',
      minWidth: '50vw',
      maxHeight: '60vh',
      data: {
        id: this.examId
      }
    });

    dialogRef.afterClosed().subscribe(response => {
      this.testSubmitted = false;
    });
  }

  ngOnDestroy() {
    localStorage.removeItem('currentExamData');
    localStorage.removeItem('examMode');
    clearInterval(this.questionTimerSubject);
  }

  leaveComment(question) {
    const dialogRef = this.dialog.open(CommentOnQuestionComponent, {
      maxWidth: '65vw',
      minWidth: 'auto',
      data: {
        ques: question,
        ans: this.answers,
        sortOrder: this.sortOrder
      }
    });
  }

}

@Component({
  selector: 'app-confirmsubmittest',
  templateUrl: '../exam/confirm-submit.component.html',
  styleUrls: ['../exam/confirm-submit.component.css']
})
export class ConfirmSubmitExamComponent implements OnInit {
  testSubmittedConfirm = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ConfirmSubmitExamComponent>, public router: Router, public snackBar: MatSnackBar, public service: LinxService) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {

  }

  completeTest() {
    this.testSubmittedConfirm = true;

    this.service.submitExam(this.data['id']).subscribe(response => {
      localStorage.removeItem('currentExamData');
      this.router.navigate(['/report'], { queryParams: {id: this.data['id']}});
      this.dialogRef.close();
    }, error => {
      this.snackBar.open('Error while submitting exam. Please try again after sometime.', 'Error', {
        duration: 5000,
        verticalPosition: 'top',
        horizontalPosition: 'center'
      });
      this.testSubmittedConfirm = false;
      this.dialogRef.close();
    });
  }

  close() {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-commentonquestion',
  templateUrl: '../exam/comment-on-question.component.html',
  styleUrls: ['../exam/comment-on-question.component.css']
})
export class CommentOnQuestionComponent implements OnInit {

  comment = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<CommentOnQuestionComponent>, public router: Router, public snackBar: MatSnackBar, public service: LinxService) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
  }

  leaveComment() {
    const payload = {
      questionId: this.data['sortOrder'],
      feedbackText: this.comment
    };
    this.service.addQuestionComment(payload).subscribe(response => {
      if (response && response.hasOwnProperty('status') && response['status'].toLowerCase() === 'success') {
        this.snackBar.open('Comment received. Thanks for your feedback.', 'Error', {
          duration: 5000,
          verticalPosition: 'top',
          horizontalPosition: 'center'
        });
      } else {
        this.snackBar.open('Error while receiving your comment. Please try again sometime.', 'Error', {
          duration: 5000,
          verticalPosition: 'top',
          horizontalPosition: 'center'
        });
      }
    }, error => {
      this.snackBar.open('Error while receiving your comment. Please try again sometime.', 'Error', {
        duration: 5000,
        verticalPosition: 'top',
        horizontalPosition: 'center'
      });
    });
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }
}

