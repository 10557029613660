<div *ngIf="answerType === 'select' || answerType === undefined" style=" width:100%; margin-top: 10px ;padding-left:20px; padding-right:20px; padding-top: 1rem;background: #FBFBFB;">
<!--  <span style="float: left">-->
<!--              <img src="assets/answer.svg" style="padding-right: 1.8rem;" />-->
<!--  </span>-->
  <div style="float: left">
    <img src="assets/answer.svg" style="height: 1.4rem;" />
  </div>
  <div class="col-lg-12 col-md-12" style="margin-left: 2rem;">
    <mat-radio-group class="example-radio-group" style="float: left;" aria-label="Select an option" [(ngModel)]="answerTemp" (ngModelChange)="changeOption()">
      <li *ngFor="let answer1 of answers; let a = index"  class="row">
        <div *ngIf="answer1.excluded === 'true'">
<!--          <mat-radio-button class="example-radio-button" disabled="true" value="{{answer1.answerOption.answerText}}">-->
<!--            <s>{{answer1.answerOption.answerText}}</s> <span> <img *ngIf="answer1.excluded === 'true'" (click)="crossOut(a)" src="assets/strikeout.svg" style="padding-left: 1rem;padding-bottom: 0.3rem" /></span>-->
<!--          </mat-radio-button>-->
          <mat-radio-button class="example-radio-button" disabled="true" value="{{answer1.answerOption.answerText}}">
            <s><span [innerHTML]="answer1.answerOption.answerText"></span></s><span> <img *ngIf="answer1.excluded === 'true'" (click)="crossOut(a)" src="assets/strikeout.svg" style="padding-left: 1rem;padding-bottom: 0.3rem" /></span>
          </mat-radio-button>
        </div>
        <div *ngIf="answer1.excluded === 'false'">
<!--          <mat-radio-button class="example-radio-button" value="{{answer1.answerOption.answerText}}">-->
<!--            {{answer1.answerOption.answerText}} <span> <img *ngIf="answer1.excluded === 'false'" (click)="crossOut(a)" src="assets/nonstrikeout.svg" style="padding-left: 1rem;padding-bottom: 0.3rem" /></span>-->
<!--          </mat-radio-button>-->
          <mat-radio-button class="example-radio-button" value="{{answer1.answerOption.answerText}}">
            <span [innerHTML]="answer1.answerOption.answerText"></span><span> <img *ngIf="answer1.excluded === 'false'" (click)="crossOut(a)" src="assets/nonstrikeout.svg" style="padding-left: 1rem;padding-bottom: 0.3rem" /></span>
          </mat-radio-button>
        </div>
        &nbsp;
        <!--              <button class="x" (click)="crossOut(a)">X</button>-->
<!--        <img *ngIf="answer1.excluded === 'false'" (click)="crossOut(a)" src="assets/nonstrikeout.svg" style="padding-left: 1rem;padding-bottom: 0.3rem" />-->
<!--        <img *ngIf="answer1.excluded === 'true'" (click)="crossOut(a)" src="assets/strikeout.svg" style="padding-left: 1rem;padding-bottom: 0.3rem" />-->
      </li>
    </mat-radio-group>
  </div>
</div>
<div *ngIf="answerType === 'text'" style="width:100%;background: #FBFBFB;padding-top: 1rem;">
    <textarea (change)= "changeText()" style="height:50px"></textarea>
</div>

