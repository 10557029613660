// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // Enable below URLs to run the application in localhost

  // redirectURL: 'https://localhost:4200/socialLoginCB',
  // paymentRedirectUrl: 'https://localhost:4200/payments',
  // linxHost: 'https://di.linxmgt.com',
  // paymentPostURL: 'https://test.payu.in/_payment'


  // Enable below URLs to run the application in unlearn.apiplatform.io

  // redirectURL: 'https://unlearn.apiplatform.io/socialLoginCB',
  // paymentRedirectUrl: 'https://unlearn.apiplatform.io/payments',
  // linxHost: 'https://di.linxmgt.com',
  // paymentPostURL: 'https://test.payu.in/_payment'


  // Enable below URLs to run the application in excelonacademy.com (While pushing to master as well)

  redirectURL: 'https://www.unlearn.works/socialLoginCB',
  paymentRedirectUrl: 'https://www.unlearn.works/payments',
  linxHost: 'https://exam.linxmgt.com',
  paymentPostURL: 'https://secure.payu.in/_payment'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
