import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ListenerService} from '../listener.service';
import {Loggedin} from '../authfilter/platformCentralAuth';
import {LinxService} from '../linx.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor(private listener: ListenerService, private auth: Loggedin, private service: LinxService) {
    // listener.navBarLoggedInUpdation.subscribe((status) => {
    //   if (status) {
    //     this.loggedIn = true;
    //   } else {
    //     this.loggedIn = false;
    //   }
    // });

      listener.thirdPartySignIn.subscribe((status) => {
           if (status) {
             this.setDetails();
           }
         });
  }

  @ViewChild('dropdowns', {read: ElementRef}) dropdowns: ElementRef;
  visibility = false;
  userVisibility = false;
  loggedIn: boolean;
  userName = 'User';
  profileImage = './assets/Vector.svg';
  contactNumber = '+91 9500123566';

  ngOnInit(): void {
    this.visibility = true;
    this.setDetails();
  }

  setDetails() {
    if (localStorage.getItem('Auth')) {
          this.loggedIn = true;
          if (localStorage.getItem('personDetails')) {
            const personDetails = JSON.parse(localStorage.getItem('personDetails'));
            if (personDetails && personDetails !== null && personDetails !== undefined) {
              if (personDetails['name'] && personDetails['name'] !== null && personDetails['name'] !== undefined && personDetails['name'] !== '') {
                this.userName = personDetails['name'];
              }
              if (personDetails['publicPhoto.filename'] && personDetails['publicPhoto.filename'] !== null && personDetails['publicPhoto.filename'] !== undefined && personDetails['publicPhoto.filename'] !== '') {
                this.profileImage = personDetails['publicPhoto.filename'];
              }
            }
          }
        } else {
          this.loggedIn = false;
        }
  }

  showdropdown() {
    if (this.visibility === true) {
      this.visibility = false;
    } else {
      this.visibility = true;
    }
  }

  showUserDropdown() {
    if (this.userVisibility === true) {
      this.userVisibility = false;
    } else {
      this.userVisibility = true;
    }
  }

  logout() {
    this.auth.logout();
  }
}
