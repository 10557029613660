<div class="row">
  <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-center">
    <p style="font-size: 1rem;font-weight: bolder;">Please provide your mobile number to continue</p>
    <hr>
  </div>
  <div style="margin-top: 5px;" class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
<!--    <label class="label-style">-->
<!--      Job Description-->
<!--    </label>-->
    <input type="text" maxlength="16" placeholder="Enter your mobile number" [(ngModel)]="mobileNumber" [value]="mobileNumber" class="form-control"><br>
  </div>
<!--  <div class="row">-->
    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-right">
      <button class="button-class" style="margin: 0.5rem;" (click)="saveMobileNumber()"> Save </button>
    </div>
<!--  </div>-->
</div>
