<div class="container-fluid">
  <div class="d-flex flex-column flex-lg-row align-items-center justify-content-around">
    <span class="py-2">&copy; 2022 Unlearn</span>
<!--    <div class="py-2">-->
<!--      <a href="https://www.instagram.com/excelonacademy/" target="_blank"> <img src="/assets/insta.svg"  alt="" /></a>-->
<!--      <a href="https://www.linkedin.com/company/excelon-academy/" target="_blank"><img src="/assets/li.svg"  alt="" /></a>-->
<!--      <a href="https://www.facebook.com/ExcelOn-Academy-108398970865602" target="_blank"><img src="/assets/fb.svg"  alt="" /></a>-->
<!--      <a href="https://www.quora.com/profile/T-Uday-Kumar-1" target="_blank"><img src="/assets/quora_circle.png" width="35" alt="" style="filter: grayscale(100%);"/></a>-->
<!--      <a [routerLink]="['/aboutExcelOn']"><img height="28" src="/assets/img/info.png"  alt="" /></a>-->
<!--      <a [routerLink]="['/termsandconditions']"><img height="30" src="/assets/img/handshake.png"  alt="" /></a>-->
<!--      <a href="https://t.me/excelonacademy" target="_blank"><img src="/assets/telegram1.png" alt="" width="30" style="filter: grayscale(100%);"></a>-->
<!--    </div>-->
<!--    <div class="py-2">-->
<!--      <a [routerLink]="['/aboutExcelOn']">About Us | </a>-->
<!--      <a href="https://www.youtube.com/watch?v=tKTu2Jwi6rg&feature=youtu.be" target="_blank">ExcelOn On News </a>-->
<!--    </div>-->
  </div>
</div>
