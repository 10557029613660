<!--<div class="col-lg-12 col-md-12">-->
<!--  <div style="float: left">-->
<!--    <img src="assets/question.svg" style="padding-right: 1.1rem;" />-->
<!--  </div>-->
<!--  <div style="margin-left: 1rem;">-->
<!--    <p [innerHTML]="question"></p>-->
<!--  </div>-->
<!--</div>-->

<div class="col-lg-12 col-md-12">
  <div style="float: left">
    <img src="assets/question.svg" style="height: 1.4rem;" />
  </div>
  <div class="col-lg-12 col-md-12" [innerHTML]="question | safeHtml" style="margin-left: 1rem;">
  </div>
</div>
