import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './login/login.component';
import {HomeComponent} from './home/home.component';
import {ModulesCompComponent} from './modules-comp/modules-comp.component';
import {DashboardComponent} from './dashboard/dashboard.component';
// import {ReportComponent} from './report/report.component';
import {Dashboard2Component} from './dashboard2/dashboard2.component';
import {ExamComponent } from './exam/exam.component';
import {ResultsComponent} from './results/results.component';
import {SocialLoginComponent} from './social-login/social-login.component';
import {PlatformCentralAuth} from './authfilter/platformCentralAuth';
import {PaymentComponent} from './payment/payment.component';
import {TermsAndConditionsComponent} from './terms-and-conditions/terms-and-conditions.component';
import {MentorComponent} from './mentor/mentor.component';
import { TelegramPageComponent } from './telegram-page/telegram-page.component';
import { MainsComponent } from './mains/mains.component';

const routes: Routes = [
  {path: '', pathMatch : 'full', redirectTo: 'login'},
  {path: 'login', component: LoginComponent , pathMatch : 'full'},
  {path: 'modules', component: ModulesCompComponent , pathMatch : 'full'},
  {path: 'dashboard', component: DashboardComponent , pathMatch : 'full', canActivate: [PlatformCentralAuth]},
  {path: 'dashboard2', component: Dashboard2Component , pathMatch : 'full', canActivate: [PlatformCentralAuth]},
  {path: 'exams', component: ExamComponent , pathMatch : 'full', canActivate: [PlatformCentralAuth]},
  {path: 'socialLoginCB', component: SocialLoginComponent},
  {path: 'report', component: ResultsComponent, canActivate: [PlatformCentralAuth]},
  {path: 'payments', component: PaymentComponent, canActivate: [PlatformCentralAuth]},
  {path: 'termsandconditions', component: TermsAndConditionsComponent},
  // {path: 'mentor', component: MentorComponent},
  // {path: 'telegram', component: TelegramPageComponent},
  {path: 'mains', component: MainsComponent}

  // {path: 'Report', component: ReportComponent , pathMatch : 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
